export const titles = {
  ru: [
    {key: '1', value: 'Конституция'},
    {key: '2', value: 'О Президенте Республики Казахстан'},
    {key: '3', value: 'О правительстве Республики Казахстан'},
    {key: '4', value: 'О Гос службе Республики Казахстан'},
    {key: '5', value: 'О противодействии корупции'},
    {key: '6', value: 'О Правовых актах'},
    {key: '7', value: 'О государственых услугах'},
    {key: '8', value: 'Административный процедурно-процессуальный кодекс РК'},
    {key: '9', value: 'Этичеcкий кoдекc гoccлужaщих РК (Прaвилa cлужебнoй этики гoccлужaщих)'},
    {key: '10', value: 'О местном государственном управлении и самоуправлении в РК'},
    {key: '11', value: 'О Парламенте Республики Казахстан и статусе его депутатов'},
    {key: '12', value: 'О персональных данных и их защите'},
    {key: '13', value: 'О правоохранительной службе'},
    {key: '14', value: 'Об акционерных обществах'}
],
  kz: [
    {key: '1', value: 'Конституция'},
    {key: '2', value: 'Қазақстан Республикасының Президенті туралы'},
    {key: '3', value: 'Қазақстан Республикасының Үкіметі туралы'},
    {key: '4', value: 'Қазақстан Республикасының Мемлекеттік қызметі туралы'},
    {key: '5', value: 'Сыбайлас жемқорлыққа қарсы күрес туралы'},
    {key: '6', value: 'Құқықтық актілер туралы'},
    {key: '7', value: 'Мемлекеттік қызметтер туралы'},
    {key: '8', value: 'Қазақстан Республикасының Әкімшілік іс жүргізу кодексі'},
    {key: '9', value: 'Қазақстан Республикасы мемлекеттік қызметшілерінің әдеп кодексі (Мемлекеттік қызметшілердің қызметтік этика қағидалары)'},
    {key: '10', value: 'Қазақстан Республикасындағы жергілікті мемлекеттік басқару және өзін-өзі басқару туралы'},
    {key: '11', value: 'Қазақстан Республикасының Парламенті және оның депутаттарының мәртебесі туралы'},
    {key: '12', value: 'Жеке деректер және оларды қорғау туралы'},
    {key: '13', value: 'Құқық қорғау қызметі туралы'},
    {key: '14', value: 'Акционерлік қоғамдар туралы'}
  ]
};
