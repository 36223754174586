import {EQuestionType, ITest} from '../../../shared/models/test.model';

export const adminProcedures: ITest = {
  id: 'adminProcedures',
  title: 'Админпроцедуры',
  description: '<p>Тест на знание административно процедурно-процессуального кодекса РК</p>',
  questions: [
    {
      type: EQuestionType.Radio,
      title: 'Не относится к функциям государственных органов:',
      choices: [
        'издание актов рекомендательного характера в отношении юридических лиц',
        'издание актов, определяющих общеобязательные правила поведения',
        'управление и регулирование социально значимых общественных отношений',
        'контроль за соблюдением установленных государством общеобязательных правил поведения',
      ],
      correctAnswer: 'издание актов рекомендательного характера в отношении юридических лиц'
    },
    {
      type: EQuestionType.Radio,
      title: 'Согласно Закону «Об административных процедурах» под служебной информацией понимается: ',
      choices: [
        'информация, создаваемая, обрабатываемая и передаваемая при выполнении государственных функций, собственником, владельцем или пользователем которой является государство',
        'информация, обрабатываемая и передаваемая при выполнении государственных функций, собственником, владельцем или пользователем которой является физическое лицо',
        'информация, создаваемая при выполнении государственных функций, собственником, владельцем или пользователем которой является юридическое лицо',
        'информация, создаваемая, обрабатываемая и передаваемая при выполнении государственных функций, владельцем или пользователем которой является субъект квазигосударственного сектора',
      ],
      correctAnswer: 'информация, создаваемая, обрабатываемая и передаваемая при выполнении государственных функций, собственником, владельцем или пользователем которой является государство'
    },
    {
      type: EQuestionType.Radio,
      title: 'Должностные лица могут осуществлять возложенные на них функции:',
      choices: [
        'от имени конкретного государственного органа и его структурных подразделений',
        'от собственного имени',
        'от собственного имени и имени государственного органа',
        'от имени народа',
      ],
      correctAnswer: 'от имени конкретного государственного органа и его структурных подразделений'
    },
    {
      type: EQuestionType.Radio,
      title: 'Административные процедуры не применяются в деятельности:',
      choices: [
        'негосударственных учреждений и предприятий',
        'Президента, государственных органов и должностных лиц, обеспечивающих деятельность главы государства, государственных органов, непосредственно подчиненных и подотчетных Президенту',
        'Аппаратов палат Парламента, Центральной избирательной комиссии',
        'Аппаратов Конституционного Совета, Верховного Суда и иных судов',
      ],
      correctAnswer: 'негосударственных учреждений и предприятий'
    },
    {
      type: EQuestionType.Radio,
      title: 'В ходе рассмотрения дела, связанного с поступившим обращением:',
      choices: [
        'гражданин вправе лично излагать доводы должностному лицу, рассматривающему его обращение, а также предоставлять дополнительные материалы в подтверждение обоснованности своего обращения',
        'гражданин не вправе лично излагать доводы должностному лицу, рассматривающему его обращение',
        'государственный орган или должностное лицо не устанавливает и не исследует фактические обстоятельства, связанные с обращением',
        'государственный орган не рассматривает дополнительные материалы, представленные гражданином в подтверждение обоснованности своего обращения',
      ],
      correctAnswer: 'гражданин вправе лично излагать доводы должностному лицу, рассматривающему его обращение, а также предоставлять дополнительные материалы в подтверждение обоснованности своего обращения'
    },
    {
      type: EQuestionType.Radio,
      title: 'Личный прием граждан первыми руководителями государственных органов и их заместителями проводится:',
      choices: [
        'в установленные и доведенные до сведения граждан дни и часы',
        'в любое время, без уведомления граждан',
        'не проводится',
        'один раз в полугодие',
      ],
      correctAnswer: 'в установленные и доведенные до сведения граждан дни и часы'
    },
    {
      type: EQuestionType.Radio,
      title: 'К принципам установления административных процедур не относится:',
      choices: [
        'принцип меритократии',
        'принцип поддержания авторитета государственной власти и недопущения действий, способных дискредитировать Республику Казахстан и противоречащих интересам государственной службы, в том числе противостояния проявлениям коррупции, строгого соблюдения установленных законодательством для государственных служащих запретов и ограничений',
        'принцип экономичности и эффективности',
        'принцип взаимной ответственности и баланса интересов личности, общества и государства',
      ],
      correctAnswer: 'принцип меритократии'
    },
    {
      type: EQuestionType.Radio,
      title: 'По жалобе лица вышестоящим государственным органом (вышестоящим должностным лицом) выносится мотивированное решение в срок:',
      choices: [
        'не более тридцати рабочих дней с даты регистрации жалобы',
        'не более десяти рабочих дней с даты регистрации жалобы',
        'не более пятнадцати рабочих дней с даты регистрации жалобы',
        'не полее пятнадцати календарных дней',
      ],
      correctAnswer: 'не более тридцати рабочих дней с даты регистрации жалобы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Функции государственного органа подразделяются на:',
      choices: [
        'стратегические, регулятивные, реализационные и контрольные',
        'стратегические, регулятивные',
        'стратегические, операционные, регулятивные и контрольные',
        'стратегические, регулятивные и контрольные',
      ],
      correctAnswer: 'стратегические, регулятивные, реализационные и контрольные'
    },
    {
      type: EQuestionType.Radio,
      title: 'Распределение функций государственных органов, подотчетных Президенту, определяется:',
      choices: [
        'Президентом',
        'Правительством',
        'государственными органами, подотчетными Президенту',
        'законом',
      ],
      correctAnswer: 'Президентом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Распределение функций центральных исполнительных органов определяется:',
      choices: [
        'Правительством',
        'Президентом',
        'государственными органами',
        'законом',
      ],
      correctAnswer: 'Правительством'
    },
    {
      type: EQuestionType.Radio,
      title: 'Коллегиальными являются:',
      choices: [
        'государственные органы, решения которых принимаются большинством голосов членов этих органов',
        'все центральные государственные органы',
        'государственные органы, непосредственно подчиненные и подотчетные Президенту',
        'государственные органы, решения которых принимаются руководителем государственного органа',
      ],
      correctAnswer: 'государственные органы, решения которых принимаются большинством голосов членов этих органов'
    },
    {
      type: EQuestionType.Radio,
      title: 'План проведения заседаний коллегиальных органов разрабатывается:',
      choices: [
        'на квартал и на год',
        'на квартал, на полугодие и на год',
        'на год и долгосрочную перспективу',
        'на полугодие и на год',
      ],
      correctAnswer: 'на квартал и на год'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные органы осуществляют свою деятельность в соответствии:',
      choices: [
        'со стратегическими и операционными планами',
        'со стратегическими планами',
        'с операционными планами',
        'с межведомственными планами',
      ],
      correctAnswer: 'со стратегическими и операционными планами'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные органы, не разрабатывающие стратегические планы, осуществляют свою деятельность в соответствии с: ',
      choices: [
        'планами работы, составляемыми на квартал, год и долгосрочную перспективу',
        'операционными планами',
        'межведомственными планами',
        'операционными и межведомственными планами',
      ],
      correctAnswer: 'планами работы, составляемыми на квартал, год и долгосрочную перспективу'
    },
    {
      type: EQuestionType.Radio,
      title: 'Служебные документы, адресованные в государственный орган или непосредственно руководству данного органа, после их поступления:',
      choices: [
        'регистрируются службой делопроизводства',
        'напрямую направляются  руководителю структурного подразделения государственного органа',
        'передаются в архив',
        'напрямую направляются исполнителю',
      ],
      correctAnswer: 'регистрируются службой делопроизводства'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае, если при рассмотрении и прохождении служебных документов вопрос выходит за пределы установленной компетенции государственного органа, то:',
      choices: [
        'принимается решение о направлении обращения компетентному государственному органу или должностному лицу с обязательным уведомлением заявителя в срок не более трех рабочих дней',
        'обращение возвращается адресату, с обязательным указанием причин возврата (отказа в рассмотрении) обращения',
        'обращение оставляется без рассмотрения и без соответствующего уведомления об этом заявителя',
        'принимается решение о направлении обращения компетентному государственному органу или должностному лицу с обязательным уведомлением заявителя в срок не более пяти рабочих дней',
      ],
      correctAnswer: 'принимается решение о направлении обращения компетентному государственному органу или должностному лицу с обязательным уведомлением заявителя в срок не более трех рабочих дней'
    },
    {
      type: EQuestionType.Radio,
      title: 'Документы, направляемые от имени государственного органа, не могут быть подписаны:',
      choices: [
        'любым сотрудником государственного органа',
        'руководителем государственного органа',
        'лицом, замещающим руководителя (в соответствии с установленным в данном органе распределением служебных обязанностей)',
        'уполномоченным должностным лицом этого органа'
      ],
      correctAnswer: 'любым сотрудником государственного органа'
    },
    {
      type: EQuestionType.Radio,
      title: 'Срок рассмотрения документов в государственных органах не должен превышать:',
      choices: [
        'одного месяца, если иное не установлено законодательством',
        'двух месяцев, если иное не установлено законодательством',
        'десяти рабочих дней',
        'пятнадцати календарных дней',
      ],
      correctAnswer: 'одного месяца, если иное не установлено законодательством'
    },
    {
      type: EQuestionType.Radio,
      title: 'Внутренний контроль за сроками исполнения поручений по служебным документам осуществляется:',
      choices: [
        'службой делопроизводства государственного органа',
        'исполнителем',
        'руководителем структурного подразделения, в чью компетенцию входит исполнение поручения',
        'исполнителем и его непосредственным руководителем',
      ],
      correctAnswer: 'службой делопроизводства государственного органа'
    },
    {
      type: EQuestionType.Radio,
      title: 'Исполнение поручений, данных нескольким исполнителям, координирует:',
      choices: [
        'должностное лицо, указанное в поручении первым',
        'руководитель государственного органа',
        'служба делопроизводства государственного органа',
        'непосредственный руководитель ответственного исполнителя',
      ],
      correctAnswer: 'должностное лицо, указанное в поручении первым'
    },
    {
      type: EQuestionType.Radio,
      title: 'Дополнительный срок исполнения поручения устанавливается:',
      choices: [
        'руководителем, давшим поручение',
        'службой делопроизводства государственного органа',
        'исполнителем самостоятельно',
        'непосредственным руководителем исполнителя',
      ],
      correctAnswer: 'руководителем, давшим поручение'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае необходимости дополнительного времени для исполнения поручения исполнитель письменно обращается к:',
      choices: [
        'должностному лицу, давшему поручение, о продлении срока исполнения поручения',
        'службу делопроизводства государственного органа о продлении срока исполнения поручения',
        'руководителю государственного органа о продлении срока исполнения поручения',
        'непосредственному руководителю о продлении срока исполнения поручения',
      ],
      correctAnswer: 'должностному лицу, давшему поручение, о продлении срока исполнения поручения'
    },
    {
      type: EQuestionType.Radio,
      title: 'Процедуры реализации прав граждан не должны допускать:',
      choices: [
        'направление обращений должностным лицам, действия которых обжалуются в обращении',
        'порядок реализации прав, при котором граждане обязаны предоставлять государственным органам и должностным лицам минимальное число документов, подтверждающих юридически значимые факты',
        'минимальное число инстанций, с которыми согласовывается проект решения по реализации прав граждан',
        'заблаговременное извещение граждан о месте и времени рассмотрения дела соответствующим лицом или органом',
      ],
      correctAnswer: 'направление обращений должностным лицам, действия которых обжалуются в обращении'
    },
    {
      type: EQuestionType.Radio,
      title: 'Процедуры реализации прав граждан должны предусматривать:',
      choices: [
        'минимальное число инстанций, с которыми согласовывается проект решения по реализации прав граждан',
        'обращение жалобы во вред лицу, подавшему жалобу, или в интересах которого она была подана',
        'направление обращений должностным лицам, действия которых обжалуются в обращении',
        'возможность разглашения без согласия граждан сведений об их частной жизни, личной и семейной тайне',
      ],
      correctAnswer: 'минимальное число инстанций, с которыми согласовывается проект решения по реализации прав граждан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Личный прием граждан первыми руководителями государственных органов и их заместителями проводится:',
      choices: [
        'в установленные и доведенные до сведения граждан дни и часы',
        'в любое время, без уведомления граждан',
        'не проводится',
        'один раз в полугодие',
      ],
      correctAnswer: 'в установленные и доведенные до сведения граждан дни и часы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Заявитель вправе подать повторное обращение по уже рассмотренному в установленном законодательством порядке вопросу:',
      choices: [
        'если имеются новые доводы или вновь открывшиеся обстоятельства',
        'если заявитель не удовлетворен ответом государственного органа',
        'если не истек месячный срок обжалования',
        'в трехдневный срок со дня получения ответа',
      ],
      correctAnswer: 'если имеются новые доводы или вновь открывшиеся обстоятельства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Повторные обращения, в которых не приводятся новые доводы или вновь открывшиеся обстоятельства:',
      choices: [
        'проверке не подлежат, если по ним имеются исчерпывающие материалы проверок и заявителям в установленном порядке давались ответы',
        'ни при каких обстоятельствах не подлежат проверке',
        'подлежат проверке',
        'проверке не подлежат, если по ним не имеется исчерпывающих материалов проверок',
      ],
      correctAnswer: 'проверке не подлежат, если по ним имеются исчерпывающие материалы проверок и заявителям в установленном порядке давались ответы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решение о прекращении производства по обращениям граждан и снятии этих дел с контроля вправе принимать:',
      choices: [
        'руководитель государственного органа или его заместитель',
        'руководитель службы делопроизводства',
        'исполнитель',
        'непосредственный руководитель исполнителя',
      ],
      correctAnswer: 'руководитель государственного органа или его заместитель'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решение, принятое по обращению, может быть обжаловано заявителем:',
      choices: [
        'в вышестоящий государственный орган (вышестоящему должностному лицу) или в суд',
        'только в суд',
        'в государственный орган, рассмотревший обращение',
        'в общественную организацию',
      ],
      correctAnswer: 'в вышестоящий государственный орган (вышестоящему должностному лицу) или в суд'
    },
    {
      type: EQuestionType.Radio,
      title: 'Жалоба на действия должностных лиц, а также на акты государственных органов подается в вышестоящий государственный орган:',
      choices: [
        'не позднее трех месяцев со дня, когда лицу стало известно о совершении действия или принятии акта',
        'не позднее пятнадцати дней со дня, когда гражданину стало известно о совершении действия соответствующим должностным лицом',
        'не позднее месячного срока со дня, когда гражданину стало известно о совершении действия соответствующим должностным лицом',
        'не позднее двух месяцев со дня, когда лицу стало известно о совершении действия или принятии акта',
      ],
      correctAnswer: 'не позднее трех месяцев со дня, когда лицу стало известно о совершении действия или принятии акта'
    },
    {
      type: EQuestionType.Radio,
      title: 'Обращения иностранцев и лиц без гражданства рассматриваются:',
      choices: [
        'в установленном законодательством порядке, если международными договорами, ратифицированными Республикой Казахстан, не предусмотрены иные правила их рассмотрения',
        'в первую очередь',
        'в особом порядке с исключениями, предусмотренными законодательством',
        'не рассматриваются',
      ],
      correctAnswer: 'в установленном законодательством порядке, если международными договорами, ратифицированными Республикой Казахстан, не предусмотрены иные правила их рассмотрения'
    },
    {
      type: EQuestionType.Radio,
      title: 'Первые руководители государственных органов и их заместители обязаны проводить личный прием граждан, в том числе работников этих органов:',
      choices: [
        'не реже одного раза в месяц',
        'не реже двух раз в месяц',
        'по их личному усмотрению',
        'по заявлению граждан',
      ],
      correctAnswer: 'не реже одного раза в месяц'
    },
    {
      type: EQuestionType.Radio,
      title: 'Если заявление или жалоба не могут быть разрешены должностным лицом во время приема, они:',
      choices: [
        'излагаются в письменной форме и с ними ведется работа как с письменными обращениями',
        'фиксируются службой документооборота в протокольной форме',
        'рассматриваются вторично при повторной записи гражданина на прием',
        'гражданин обращается в вышестоящую организацию',
      ],
      correctAnswer: 'излагаются в письменной форме и с ними ведется работа как с письменными обращениями'
    },
    {
      type: EQuestionType.Radio,
      title: 'Персональную ответственность за организацию работы с обращениям граждан несут:',
      choices: [
        'руководители органов',
        'руководитель службы делопроизводства',
        'исполнитель',
        'за организацию работы с обращениями граждан персональной ответственности не предусмотрено',
      ],
      correctAnswer: 'руководители органов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Под функциями государственного органа понимается:',
      choices: [
        'осуществление государственным органом деятельности в пределах своей компетенции',
        'основные направления деятельности государственного органа',
        'совокупность установленных полномочий государственного органа',
        'круг действий, выполнение которых обязательно государственным органом',
      ],
      correctAnswer: 'осуществление государственным органом деятельности в пределах своей компетенции'
    },
    {
      type: EQuestionType.Radio,
      title: 'Стратегическими являются функции:',
      choices: [
        'по разработке, принятию плановых документов, определению системы государственного планирования, обеспечению международных отношений, национальной безопасности и обороноспособности',
        'направленные на исполнение плановых документов, нормативных правовых актов, достижение целей и задач, предусмотренных плановыми документами государственного органа,',
        'по нормативному правовому обеспечению реализации государственных функций, регистрации и ведению анализа исполнения нормативных правовых актов, координации деятельности государственных органов, управлению государственными активами',
        'по проверке и наблюдению на предмет соответствия деятельности физических и юридических лиц, в том числе государственных учреждений, установленным нормативными правовыми актами требованиям',
      ],
      correctAnswer: 'по разработке, принятию плановых документов, определению системы государственного планирования, обеспечению международных отношений, национальной безопасности и обороноспособности'
    },
    {
      type: EQuestionType.Radio,
      title: 'Под задачами государственного органа понимаются:',
      choices: [
        'основные направления деятельности государственного органа',
        'совокупность установленных полномочий государственного органа',
        'круг действий, выполнение которых обязательно государственным органом',
        'осуществление государственным органом деятельности в пределах своей компетенции',
      ],
      correctAnswer: 'основные направления деятельности государственного органа'
    },
    {
      type: EQuestionType.Radio,
      title: 'Под обязанностями государственного органа понимаются:',
      choices: [
        'круг действий, выполнение которых обязательно государственным органом',
        'права и обязанности государственного органа',
        'совокупность установленных полномочий государственного органа',
        'осуществление государственным органом деятельности в пределах компетенции',
      ],
      correctAnswer: 'круг действий, выполнение которых обязательно государственным органом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Функция по проверке и наблюдению на предмет соответствия деятельности физических и юридических лиц, в том числе государственных учреждений, установленным нормативными правовыми актами требованиям:',
      choices: [
        'контрольная функция',
        'регулятивная функция',
        'реализационная функция',
        'стратегическая функция',
      ],
      correctAnswer: 'контрольная функция'
    },
    {
      type: EQuestionType.Radio,
      title: 'Контроль за ходом рассмотрения обращений граждан, направленных в подразделения государственного органа, осуществляется:',
      choices: [
        'руководителями этих подразделений',
        'вышестоящим государственным органом',
        'непосредственно исполнителем',
        'службой делопроизводства государственного органа',
      ],
      correctAnswer: 'руководителями этих подразделений'
    },
    {
      type: EQuestionType.Radio,
      title: 'Функция по нормативному правовому обеспечению реализации государственных функций, регистрации и ведению анализа исполнения нормативных правовых актов, координации деятельности государственных органов, управлению государственными активами:',
      choices: [
        'регулятивная функция',
        'контрольная функция',
        'реализационная функция',
        'стратегическая функция',
      ],
      correctAnswer: 'регулятивная функция'
    },
    {
      type: EQuestionType.Radio,
      title: 'Под полномочиями государственного органа понимаются:',
      choices: [
        'права и обязанности государственного органа',
        'совокупность установленных полномочий государственного органа',
        'основные направления деятельности государственного органа',
        'осуществление государственным органом деятельности в пределах своей компетенции',
      ],
      correctAnswer: 'права и обязанности государственного органа'
    },
    {
      type: EQuestionType.Radio,
      title: 'Под правами государственного органа понимается:',
      choices: [
        'возможность совершать определенные действия, требовать определенного поведения (действий или воздержания от совершения действий) от другого лица (других лиц)',
        'компетенция государственного органа',
        'совокупность установленных полномочий государственного органа',
        'осуществление государственным органом деятельности в пределах своей компетенции',
      ],
      correctAnswer: 'возможность совершать определенные действия, требовать определенного поведения (действий или воздержания от совершения действий) от другого лица (других лиц)'
    },
    {
      type: EQuestionType.Radio,
      title: 'Если имеются новые доводы или вновь открывшиеся обстоятельства, то заявитель вправе:',
      choices: [
        'подать повторное обращение по уже рассмотренному государственным органом (должностным лицом государственного органа) в установленном законодательством порядке вопросу',
        'направить в государственный орган запрос ускоренного рассмотрения вопроса',
        'направить обращение в государственный орган для его рассмотрения в качестве отдельного вопроса',
        'обратиться в вышестоящий государственный орган'
      ],
      correctAnswer: 'подать повторное обращение по уже рассмотренному государственным органом (должностным лицом государственного органа) в установленном законодательством порядке вопросу'
    },
    {
      type: EQuestionType.Radio,
      title: 'Функции, направленные на исполнение плановых документов, нормативных правовых актов, достижение целей и задач, предусмотренных плановыми документами государственного органа, оказание государственных услуг, выдачу разрешительных документов:',
      choices: [
        'реализационная функция',
        'контрольная функция',
        'регулятивная функция',
        'стратегическая функция',
      ],
      correctAnswer: 'реализационная функция'
    },
    {
      type: EQuestionType.Radio,
      title: 'При рассмотрении жалобы всесторонне проверяются изложенные в ней доводы, в случае необходимости:',
      choices: [
        'истребуются дополнительные материалы от соответствующих должностных лиц, организаций и граждан с пояснениями относительно обжалуемых действий должностных лиц, актов государственных органов',
        'должностное лицо требует оплатить его услуги с целью положительного рассмотрения жалобы',
        'материалы передаются на рассмотрение в вышестоящие государственные органы',
        'создаются рабочие группы по рассмотрению  обращения',
      ],
      correctAnswer: 'истребуются дополнительные материалы от соответствующих должностных лиц, организаций и граждан с пояснениями относительно обжалуемых действий должностных лиц, актов государственных органов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Процедуры реализации прав граждан не должны допускать:',
      choices: [
        'обращения жалобы во вред лицу, подавшему жалобу, или в интересах которого она была подана',
        'возможность личного участия гражданина в разбирательстве по его обращению',
        'заблаговременное извещение граждан о месте и времени рассмотрения дела соответствующим лицом или органом',
        'ведение дела по обращению гражданина одним и тем же должностным лицом, не допуская необоснованной передачи материалов дела, связанных с обращением гражданина, другому должностному лицу',
      ],
      correctAnswer: 'обращения жалобы во вред лицу, подавшему жалобу, или в интересах которого она была подана'
    },
    {
      type: EQuestionType.Radio,
      title: 'Сроки устанавливаются государственным органом-исполнителем или вышестоящим органом и незамедлительно доводятся до сведения непосредственных исполнителей если:',
      choices: [
        'в правовом акте индивидуального применения не определены конкретные сроки его исполнения и непосредственные исполнители',
        'в правовом акте индивидуального применения установлены сжатые сроки',
        'в правовом акте определены сроки, с которым не согласен исполнитель',
        'об этом указано в правовом акте индивидуального применения',
      ],
      correctAnswer: 'в правовом акте индивидуального применения не определены конкретные сроки его исполнения и непосредственные исполнители'
    },
    {
      type: EQuestionType.Radio,
      title: 'Органы, решения которых принимаются большинством голосов членов этих органов:',
      choices: [
        'коллегиальные органы',
        'центральные исполнительные органы',
        'консультативно-совещательные органы',
        'местные исполнительные органы',
      ],
      correctAnswer: 'коллегиальные органы'
    },
    {
      type: EQuestionType.Radio,
      title: 'По результатам рассмотрения жалобы решения не может быть принято решение:',
      choices: [
        'о создании экспертной комиссии',
        'о полном удовлетворении жалобы',
        'об отказе в удовлетворении жалобы',
        'о частичном удовлетворении жалобы',
      ],
      correctAnswer: 'о создании экспертной комиссии'
    },
    {
      type: EQuestionType.Radio,
      title: 'Заседание коллегиального органа:',
      choices: [
        'протоколируются',
        'проводятся только в закрытом режиме',
        'проводятся только по особо важным вопросам',
        'как правило, проводятся под председательством Президента',
      ],
      correctAnswer: 'протоколируются'
    },
    {
      type: EQuestionType.Radio,
      title: 'Отправление и получение информации в установленном законодательством порядке должностными лицами государственных органов при осуществлении ими служебных полномочий:',
      choices: [
        'информационный обмен',
        'информационная система',
        'служебное взаимодействие',
        'межведомственное сотрудничество',
      ],
      correctAnswer: 'информационный обмен'
    },
    {
      type: EQuestionType.Radio,
      title: 'Рассмотрение жалобы на действия должностных лиц, а также на акты государственных органов производится:',
      choices: [
        'вышестоящим государственным органом',
        'неправительственными организациями',
        'руководителем государственного органа, акт которого обжалуется',
        'государственным органом по выбору автора жалобы',
      ],
      correctAnswer: 'вышестоящим государственным органом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Подача жалобы в вышестоящий государственный орган (вышестоящему должностному лицу):',
      choices: [
        'не препятствует обращению в суд за защитой оспариваемых прав, свобод и (или) законных интересов',
        'исключает возможность автора жалобы дальнейшего обращения в судебные органы',
        'производится только после попытки урегулировать вопрос с государственным органом, акт которого обжалуется',
        'препятствует обращению в суд за защитой оспариваемых прав, свобод и (или) законных интересов',
      ],
      correctAnswer: 'не препятствует обращению в суд за защитой оспариваемых прав, свобод и (или) законных интересов'
    },
    {
      type: EQuestionType.Radio,
      title: 'При пропуске срока для обжалования акта государственного органа:',
      choices: [
        'причины выясняются при рассмотрении жалобы по существу и могут являться одним из оснований для отказа в удовлетворении жалобы',
        'лицу отказывается в принятии жалобы вышестоящим государственным органом (вышестоящим должностным лицом)',
        'жалобы оставляется без рассмотрения вышестоящим государственным органом (вышестоящим должностным лицом)',
        'лицо не может подать жалобу в вышестоящий государственный орган',
      ],
      correctAnswer: 'причины выясняются при рассмотрении жалобы по существу и могут являться одним из оснований для отказа в удовлетворении жалобы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Отзыв жалобы лицом:',
      choices: [
        'не лишает его права на подачу повторной жалобы при условии соблюдения сроков, если иное не предусмотрено законами Республики Казахстан',
        'лишает его права на подачу повторной жалобы',
        'исключает возможность автора жалобы дальнейшего обращения в судебные органы',
        'не допускается',
      ],
      correctAnswer: 'не лишает его права на подачу повторной жалобы при условии соблюдения сроков, если иное не предусмотрено законами Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Процедуры принятия решений в области экономики не должны: ',
      choices: [
        'ограничивать права и интересы субъектов хозяйственной деятельности',
        'быть публичными и открытыми (в установленных законодательством пределах)',
        'основываться на тендерных началах применительно к гражданам и организациям, участвующим в приватизации государственного имущества',
        'основываться на принципе юридического равенства субъектов хозяйственной деятельности независимо от формы собственности и иных обстоятельств',
      ],
      correctAnswer: 'ограничивать права и интересы субъектов хозяйственной деятельности'
    },
    {
      type: EQuestionType.Radio,
      title: 'Деятельность, осуществляемая должностными лицами в государственных органах, заключающаяся в единоличном подписании уполномоченными должностными лицами правовых актов индивидуального применения, даче указаний и поручений находящимся в подчинении работникам, единоличном принятии иных организационно-распорядительных мер по осуществлению государственных функций: ',
      choices: [
        'единоличная распорядительная деятельность',
        'коллегиальная деятельность',
        'распорядительная деятельность',
        'исполнительная власть',
      ],
      correctAnswer: 'единоличная распорядительная деятельность'
    },
    {
      type: EQuestionType.Radio,
      title: 'Ответственность за законность принимаемых коллегиальным органом решений возлагается:',
      choices: [
        'на всех членов коллегиального органа, принимавших участие в голосовании, за исключением тех, кто голосовал при принятии решения против',
        'на руководителя государственного органа',
        'на всех членов коллегиального органа',
        'на председателя заседания коллегиального государственного органа',
      ],
      correctAnswer: 'на всех членов коллегиального органа, принимавших участие в голосовании, за исключением тех, кто голосовал при принятии решения против'
    },
    {
      type: EQuestionType.Radio,
      title: 'Датой подачи жалобы в вышестоящий государственный орган (вышестоящему должностному лицу) в явочном порядке считается дата:',
      choices: [
        'получения жалобы вышестоящим государственным органом',
        'получения лицом уведомления о получении жалобы государственным органов',
        'издания акта о принятии жалобы к рассмотрению государственным органом',
        'получения электронной копии жалобы государственным органом',
      ],
      correctAnswer: 'получения жалобы вышестоящим государственным органом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Компетенция, полномочия, функции и задачи государственного органа не могут быть установлены:',
      choices: [
        'нормативными правовыми актами нижестоящих государственным органом по отношению к нему',
        'Конституцией',
        'законами',
        'нормативными правовыми актами, принимаемыми Президентом, Правительством',
      ],
      correctAnswer: 'нормативными правовыми актами нижестоящих государственным органом по отношению к нему'
    },
    {
      type: EQuestionType.Radio,
      title: 'Процедуры, связанные с приватизацией государственного имущества, управлением государственными активами:',
      choices: [
        'должны быть публичными и открытыми (в установленных законодательством пределах)',
        'не подлежат публичному освещению',
        'составляют служебную информацию',
        'должны быть закрытыми, недоступными широкому кругу лиц'
      ],
      correctAnswer: 'должны быть публичными и открытыми (в установленных законодательством пределах)'
    },
    {
      type: EQuestionType.Radio,
      title: 'В силу требований Конституции, законов и иных нормативных правовых актов должностные лица могут осуществлять возложенные на них функции:',
      choices: [
        'от имени конкретного должностного лица государства в порядке единоличного распорядительства',
        'от собственного имени',
        'от собственного имени и имени государственного органа',
        'от имени народа'
      ],
      correctAnswer: 'от имени конкретного должностного лица государства в порядке единоличного распорядительства'
    }
  ],
  time: 10,
  passingScore: 5,
  questionsTotal: 10
};
