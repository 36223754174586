import {EQuestionType, ITest} from '../../../shared/models/test.model';

export const government: ITest = {
  id: 'government',
  title: 'Правительство',
  description: '<p>Тест на знание закона о правительстве</p>',
  questions: [
    {
      type: EQuestionType.Radio,
      title: 'Правительство в порядке, предусмотренном Конституцией, образуется: ',
      choices: [
        'Президентом',
        'Премьер-Министром',
        'Членами Правительства',
        'Председателем Сената'
      ],
      correctAnswer: 'Президентом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Премьер-Министр ставит вопрос о доверии Правительству путем письменного обращения: ',
      choices: [
        'В Мажилис Парламента',
        'В Сенат Парламента',
        'В Администрацию Президента',
        'Спикерам палат Парламента'
      ],
      correctAnswer: 'В Мажилис Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Вправе по собственной инициативе принимать решение о прекращении полномочий Правительства: ',
      choices: [
        'Президент',
        'Мажилис Парламента',
        'Сенат Парламента',
        'Премьер-Министр с согласия Президента'
      ],
      correctAnswer: 'Президент'
    },
    {
      type: EQuestionType.Radio,
      title: 'Предложения о структуре и составе Правительства вносятся Президенту: ',
      choices: [
        'В десятидневный срок после назначения Премьер-Министра',
        'В пятидневный срок после формирования Правительства',
        'В двухнедельный срок после назначения Премьер-Министра',
        'В пятнадцатидневный срок после назначения Премьер-Министра'
      ],
      correctAnswer: 'В десятидневный срок после назначения Премьер-Министра'
    },
    {
      type: EQuestionType.Radio,
      title: 'В состав Правительства входят: ',
      choices: [
        'Премьер-Министр Республики Казахстан, его заместители, министры и иные должностные лица',
        'Министры и иные должностные лица',
        'Заместители Премьер-Министра и министры',
        'Премьер-Минитср Республики Казахстан, его заместители и министры'
      ],
      correctAnswer: 'Премьер-Министр Республики Казахстан, его заместители, министры и иные должностные лица'
    },
    {
      type: EQuestionType.Radio,
      title: 'Член Правительства приносит присягу: ',
      choices: [
        'Народу и Президенту',
        'Президенту и иным должностным лицам',
        'Премьер-Министру и Президенту',
        'Народу'
      ],
      correctAnswer: 'Народу и Президенту'
    },
    {
      type: EQuestionType.Radio,
      title: 'Члены Правительства, не согласные с проводимой Правительством политикой или не проводящие ее:',
      choices: [
        'подают в отставку',
        'Увольняются',
        'продолжают свою деятельность после информирования Премьер-Министра о несогласии с проводимой политикой',
        'Отрешаются от должности'
      ],
      correctAnswer: 'подают в отставку'
    },
    {
      type: EQuestionType.Radio,
      title: 'Правительство является:',
      choices: [
        'Коллегиальным органом',
        'Консультативно-совещательным органом',
        'Исполнительным органом',
        'Законодательным органом'
      ],
      correctAnswer: 'Коллегиальным органом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент вправе освобождать от должности членов Правительства вправе: ',
      choices: [
        'по собственной инициативе',
        'с согласия Сената Парламента',
        'с согласия Мажилиса Парламента',
        'с согласия Премьер-Министра'
      ],
      correctAnswer: 'по собственной инициативе'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент рассматривает вопрос о принятии или отклонении отставки члена Правительства:',
      choices: [
        'В десятидневный срок',
        'В месячный срок',
        'В недельный срок',
        'В пятидневный срок'
      ],
      correctAnswer: 'В десятидневный срок'
    },
    {
      type: EQuestionType.Radio,
      title: 'Правительство исполняет свои обязанности до:',
      choices: [
        'Утверждения нового состава Правительства',
        'Истечения полномочий Президента',
        'Истчечения срока полномочий Премьер-Министра',
        'роспуска Парламента'
      ],
      correctAnswer: 'Утверждения нового состава Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Предложения о структуре и составе Правительства вносится Президенту: ',
      choices: [
        'Премьер-Министром',
        'Парламентом',
        'Членами Правительства',
        'Сенатом Парламента'
      ],
      correctAnswer: 'Премьер-Министром'
    },
    {
      type: EQuestionType.Radio,
      title: 'Премьер-Министр ставит вопрос о доверии Правительству перед: ',
      choices: [
        'перед вновь избранным Мажилисом Парламента',
        'перед вновь избранным Сенатом Парламента',
        'Президентом',
        'Руководителем Администрации Президента'
      ],
      correctAnswer: 'перед вновь избранным Мажилисом Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае выражения Мажилисом доверия Правительству: ',
      choices: [
        'продолжает исполнять свои обязанности, если иное не будет решено Президентом Республики',
        'Вопрос дальнейшего исполнения обязанностей членов Правительства решает Премьер-Министр',
        'Правительство подает в отставку',
        'Вопрос выражения доверия Правительству рассматривает Сенат Парламента'
      ],
      correctAnswer: 'продолжает исполнять свои обязанности, если иное не будет решено Президентом Республики'
    },
    {
      type: EQuestionType.Radio,
      title: 'Принятие отставки Премьер-Министра означает: ',
      choices: [
        'прекращение полномочий всего Правительства',
        'прекращение полномочий Премьер-Министра',
        'продолжение полномочий Правительства',
        'Отрешение Премьер-Министра от должности'
      ],
      correctAnswer: 'прекращение полномочий всего Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Систему исполнительных органов возглавляет: ',
      choices: [
        'Правительство',
        'Премьер-Министр',
        'Глава государства',
        'Министр'
      ],
      correctAnswer: 'Правительство'
    },
    {
      type: EQuestionType.Radio,
      title: 'Вотум недоверия Правительству выражает: ',
      choices: [
        'Мажилис Парламента или Парламент',
        'Президент',
        'Премьер-Министр',
        'Сенат Парламента'
      ],
      correctAnswer: 'Мажилис Парламента или Парламент'
    },
    {
      type: EQuestionType.Radio,
      title: 'Правительство осуществляет: ',
      choices: [
        'Исполнительную власть',
        'Законодательное власть',
        'судебную власть',
        'распорядительную власть'
      ],
      correctAnswer: 'Исполнительную власть'
    },
    {
      type: EQuestionType.Radio,
      title: 'Члены Правительства вправе заниматься:',
      choices: [
        'Оплачиваемой творческой деятельностью',
        'Быть депутатом представительного органа',
        'предпринимательской деятельностью',
        'Заниматься коммерческой деятельностью'
      ],
      correctAnswer: 'Оплачиваемой творческой деятельностью'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не входит в компетенцию Правительства:',
      choices: [
        'Определение основных направлений внутренней и внешней политики государства',
        'Осуществление инвестиционной политики',
        'Осуществление структурной политики',
        'Формирование системы оплаты труда'
      ],
      correctAnswer: 'Определение основных направлений внутренней и внешней политики государства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не входит в компетенцию Правительства:',
      choices: [
        'Утверждение военной доктрины',
        'Выработка основных направлений государственной региональной политики',
        'Обеспечение решения межрегиональных проблем',
        'Обеспечение решения вопросов социально-экономического развития регионов'
      ],
      correctAnswer: 'Утверждение военной доктрины'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не входит в компетенцию Правительства: ',
      choices: [
        'решение вопроса гражданства',
        'Формирование государственной политики по развитию науки и техники',
        'Формирует государственную политику по внедрению новых технологий',
        'Обеспечение реализации правовой политики'
      ],
      correctAnswer: 'решение вопроса гражданства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Постановления Правительства принимаются:',
      choices: [
        'Большинством голосов от общего числа членов Правительства',
        'двумя третями от общего числа присутствующих членов Правительства',
        'Одной третями от общего числа присутствующих членов Правительства',
        'Премьер - Министром'
      ],
      correctAnswer: 'Большинством голосов от общего числа членов Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Распоряжения Премьер-Министра имеют обязательную силу: ',
      choices: [
        'На всей территории Республики Казахстан',
        'На территории отдельных административно-территориальных единиц',
        'только в отношении Канцелярии Премьер-Министра',
        'В отношении неправительственных организаций'
      ],
      correctAnswer: 'На всей территории Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Постановления Правительства могут быть отменены:',
      choices: [
        'Президентом и Правительством',
        'только Президентом',
        'Премьер-Министром',
        'только Правительством'
      ],
      correctAnswer: 'Президентом и Правительством'
    },
    {
      type: EQuestionType.Radio,
      title: 'Правительство ответственно во всей своей деятельности:',
      choices: [
        'Президентом',
        'Председателем Сената Парламента',
        'Президентом и Министром',
        'Премьер-Министром'
      ],
      correctAnswer: 'Президентом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Члены Правительства несут персональную ответственность за работу подчиненных им государственных органов перед:',
      choices: [
        'Премьер-Министром',
        'Президентом',
        'Народом',
        'Председателем Сената Парламента'
      ],
      correctAnswer: 'Премьер-Министром'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решение о внесении проекта законодательного акта в Мажилис  принимается путем:',
      choices: [
        'принятия соответствующего постановления Правительства',
        'Издания распоряжения Премьер-Министра',
        'постановления соответствующей Палаты Парламента',
        'Издания соответствующего постановления Сената Парламента'
      ],
      correctAnswer: 'принятия соответствующего постановления Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Правительство обладает правом законодательной инициативы, которое реализуется в:',
      choices: [
        'Мажилисе Парламента',
        'Сенате Парламента',
        'Администрации Президента',
        'Канцелярии Премьер-Министра'
      ],
      correctAnswer: 'Мажилисе Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не входит в компетенцию Правительства:',
      choices: [
        'решение вопросов помилования',
        'Выработка государственной политики ценообразования',
        'Организация управления государственной собственностью',
        'разработка мер по охране и защите прав и свобод граждан'
      ],
      correctAnswer: 'решение вопросов помилования'
    },
    {
      type: EQuestionType.Radio,
      title: 'Правительство вносит законопроект в:',
      choices: [
        'Мажилис',
        'Сенат',
        'Парламент',
        'Администрация Президента'
      ],
      correctAnswer: 'Мажилис'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не входит в компетенцию Правительства:',
      choices: [
        'решение вопросов амнистии',
        'принятие мер по развитию внешней торговли',
        'Осуществление сотрудничества с международными финансовыми организациями',
        'Обеспечение реализации безопасности и обороноспособности страны'
      ],
      correctAnswer: 'решение вопросов амнистии'
    },
    {
      type: EQuestionType.Radio,
      title: 'Постановления Правительств подписываются: ',
      choices: [
        'Премьер-Министром',
        'Руководителем государственного органа',
        'Президентом',
        'Руководителем Канцелярии Премьер-Министра'
      ],
      correctAnswer: 'Премьер-Министром'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не входит в компетенцию Правительства:',
      choices: [
        'распределение функциональных обязанности между членами Правительства',
        'Обеспечение территориальной целостности и охраны государственных границ страны',
        'Формирование государственной политики по развитию науки и техники',
        'Формирование системы социального страхования'
      ],
      correctAnswer: 'распределение функциональных обязанности между членами Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Функции Министерства: ',
      choices: [
        'Осуществление руководства соответствующей отраслью (сферой) государственного управления, а также в пределах, предусмотренных законодательством, межотраслевой координации',
        'Утверждение планов, экономических и социальных программ развития соответствующей территории',
        'Осуществляет регулятивные, реализационные и (или) контрольные функции, обеспечивающие решение задач местного значения',
        'подписание нормативных правовых постановлений Правительства'
      ],
      correctAnswer: 'Осуществление руководства соответствующей отраслью (сферой) государственного управления, а также в пределах, предусмотренных законодательством, межотраслевой координации'
    },
    {
      type: EQuestionType.Radio,
      title: 'Министерство реорганизуется и упраздняется: ',
      choices: [
        'Президентом по предложению Премьер-Министра',
        'Президентом по предложению Председателя Сената Парламента',
        'Парламентом на совместном заседании Палат по предложению Президента',
        'Правительством Республики по представлению руководителя соответствующего центрального исполнительного органа'
      ],
      correctAnswer: 'Президентом по предложению Премьер-Министра'
    },
    {
      type: EQuestionType.Radio,
      title: 'Руководитель ведомства центрального исполнительного органа назначается на должность и освобождается от должности: ',
      choices: [
        'Руководителем соответствующего центрального исполнительного органа',
        'Премьер-министром',
        'Ответственным секретарем соответствующего центрального исполнительного органа',
        'Президентом'
      ],
      correctAnswer: 'Руководителем соответствующего центрального исполнительного органа'
    },
    {
      type: EQuestionType.Radio,
      title: 'Компетенция и порядок взаимодействия ведомства центрального исполнительного органа с иными государственными органами определяется:  ',
      choices: [
        'Руководителем центрального исполнительного органа',
        'Заместителем руководителя центрального исполнительного органа',
        'Ответственным секретарем центрального исполнительного органа',
        'Руководителем ведомства центрального исполнительного органа'
      ],
      correctAnswer: 'Руководителем центрального исполнительного органа'
    },
    {
      type: EQuestionType.Radio,
      title: 'Ведомство центрального исполнительного органа образуется, реорганизуется и упраздняется: ',
      choices: [
        'Правительством по представлению руководителя соответствующего центрального исполнительного органа',
        'Премьер-министром по представлению руководителя соответствующего центрального исполнительного органа',
        'Президентом',
        'Руководителем центрального исполнительного органа'
      ],
      correctAnswer: 'Правительством по представлению руководителя соответствующего центрального исполнительного органа'
    },
    {
      type: EQuestionType.Radio,
      title: 'Структура министерства утверждается: ',
      choices: [
        'Ответственным секретарем',
        'Министром',
        'Вице-министром',
        'Правительством'
      ],
      correctAnswer: 'Ответственным секретарем'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не вправе возглавлять консультативно-совещательные органы при Правительстве:',
      choices: [
        'Руководители ведомств государственных органов',
        'Члены Правительства',
        'Заместители Премьер-Министра',
        'Премьер-Министр'
      ],
      correctAnswer: 'Руководители ведомств государственных органов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Кем осуществляется отмена либо приостановление полностью или в части действия актов ведомства центрального исполнительного органа',
      choices: [
        'Руководителем центрального исполнительного органа',
        'Ответственным секретарем центрального исполнительного органа',
        'Заместителем руководителя центрального исполнительного органа',
        'Руководителем ведомства центрального исполнительного органа'
      ],
      correctAnswer: 'Руководителем центрального исполнительного органа'
    },
    {
      type: EQuestionType.Radio,
      title: 'К функциям Министерства не относятся функции:',
      choices: [
        'Охранительные',
        'стратегические',
        'регулятивные',
        'реализационные'
      ],
      correctAnswer: 'Охранительные'
    },
    {
      type: EQuestionType.Radio,
      title: 'Центральный исполнительный орган, не входящий в состав Правительства, образуется, реорганизуется и упраздняется: ',
      choices: [
        'Президентом',
        'Парламентом',
        'Премьер-министром',
        'Правительством'
      ],
      correctAnswer: 'Президентом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Ведомством центрального исполнительного органа является: ',
      choices: [
        'Комитет центрального исполнительного органа',
        'Структурное подразделение центрального исполнительного органа',
        'Территориальное подразделение центрального исполнительного органа',
        'Управление центрального исполнительного органа'
      ],
      correctAnswer: 'Комитет центрального исполнительного органа'
    },
    {
      type: EQuestionType.Radio,
      title: 'Канцелярия Премьер-Министра осуществляет: ',
      choices: [
        'Информационно-аналитическое и организационно-правовое обеспечение деятельности Премьер-министра и Правительства',
        'Материально-техническое обеспечение деятельности Премьер-министра и Правительства',
        'Организационное обеспечение деятельности Правительства',
        'правовое обеспечение деятельности Премьер-министра и Правительства'
      ],
      correctAnswer: 'Информационно-аналитическое и организационно-правовое обеспечение деятельности Премьер-министра и Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'К функциям Министерства не относятся: ',
      choices: [
        'решение вопросов местного административно-территориального устройства',
        'руководство соответствующей отраслью (сферой) государственного управления',
        'Межотраслевая координация в пределах, предусмотренных законодательством',
        'стратегические, регулятивные, реализационные и (или) контрольно-надзорные функции'
      ],
      correctAnswer: 'решение вопросов местного административно-территориального устройства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Ведомство центрального государственного органа может иметь: ',
      choices: [
        'Территориальные подразделения',
        'Филиалы, представительства',
        'Отделы, управления',
        'Департаменты, отделы'
      ],
      correctAnswer: 'Территориальные подразделения'
    },
    {
      type: EQuestionType.Radio,
      title: 'На заседаниях Правительства председательствует: ',
      choices: [
        'Премьер-Министр',
        'Министры',
        'Руководитель Администрации Президента',
        'Все ответы правильные'
      ],
      correctAnswer: 'Премьер-Министр'
    },
    {
      type: EQuestionType.Radio,
      title: 'Заседание Правительства считается правомочным, если в нем принимает участие:',
      choices: [
        'Не менее двух третей членов Правительства',
        'Не менее одной третей членов Правительства',
        'Не менее двух пятых членов Правительства',
        'Не менее одной восьмой членов Правительства'
      ],
      correctAnswer: 'Не менее двух третей членов Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Закрытые заседания Правительства могут проводиться по инициативе: ',
      choices: [
        'Президента либо Премьер-Министра',
        'Президента',
        'Премьер-Министра',
        'Заместителя Премьер-Министра'
      ],
      correctAnswer: 'Президента либо Премьер-Министра'
    },
    {
      type: EQuestionType.Radio,
      title: 'Организует работу Правительства и распределяет функциональные обязанности между членами Правительства: ',
      choices: [
        'Премьер-Министр самостоятельно',
        'Президент Республики',
        'Премьер-Министр с согласия Президента',
        'Руководитель Администарции Президента'
      ],
      correctAnswer: 'Премьер-Министр самостоятельно'
    },
    {
      type: EQuestionType.Radio,
      title: 'Об основных направлениях деятельности Правительства Премьер-Министр докладывается: ',
      choices: [
        'Президенту и Парламенту',
        'только Президенту',
        'Конституционному Совету',
        'Членам Правительства'
      ],
      correctAnswer: 'Президенту и Парламенту'
    },
    {
      type: EQuestionType.Radio,
      title: 'Обязанности Премьер-Министра в случае его отсутствия исполняет: ',
      choices: [
        'Один из Заместителей Премьер-Министра в порядке, установленном   Премьер-Министром',
        'Руководитель Канцелярии Премьер-Министра в порядке, установленном Премьер-Министром',
        'Государственный секретарь Республики Казахстан в порядке, установленном Президентом',
        'Член Правительства в порядке, установленном Премьер-Министром'
      ],
      correctAnswer: 'Один из Заместителей Премьер-Министра в порядке, установленном   Премьер-Министром'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не относится к полномочиям членов Правительства: ',
      choices: [
        'Введение военного положения в стране',
        'самостоятельное принятие решений в пределах своей компетенции',
        'Осуществление руководства соответствующими государственными органами',
        'Обеспечение межотраслевой координации в пределах,  предусмотренных законодательством'
      ],
      correctAnswer: 'Введение военного положения в стране'
    },
    {
      type: EQuestionType.Radio,
      title: 'Персональную ответственность перед Премьер –Министром за работу подчиненных им государственных органов несут: ',
      choices: [
        'Члены Правительства',
        'Ответственные секретари',
        'Руководители государственных органов, непосредственно подчиненных и подотчетных Президенту',
        'Руководители ведомств центрадльных государственных органов'
      ],
      correctAnswer: 'Члены Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент назначает на должность и освобождает от должности ответственных секретарей: ',
      choices: [
        'по согласованию с Премьер-Министром',
        'самостоятельно',
        'по согласованию с Председателем Сената Парламента',
        'с Руководителем Администрации Президента'
      ],
      correctAnswer: 'по согласованию с Премьер-Министром'
    },
    {
      type: EQuestionType.Radio,
      title: 'Аппараты министерств и центральных исполнительных органов не входящих в состав Правительства, возглавляют:',
      choices: [
        'Ответственные секретари',
        'Вице-министры',
        'Министры',
        'Члены Правительства'
      ],
      correctAnswer: 'Ответственные секретари'
    },
    {
      type: EQuestionType.Radio,
      title: 'Статус и полномочия ответственных секретарей министерства и центрального исполнительного органа, не входящего в состав Правительства, определяет:',
      choices: [
        'Президент',
        'Премьер-Министр',
        'Премьер-Министр с согласия Президента',
        'Парламента'
      ],
      correctAnswer: 'Президент'
    },
    {
      type: EQuestionType.Radio,
      title: 'Ответственный секретарь при осуществлении своей деятельности подотчетен:',
      choices: [
        'Президенту Республики, Премьер-Министру и министру',
        'Министру',
        'Премьер-Министру и министру',
        'Президенту Республики'
      ],
      correctAnswer: 'Президенту Республики, Премьер-Министру и министру'
    },
    {
      type: EQuestionType.Radio,
      title: 'Отставка руководителя центрального исполнительного органа, не входящего в состав Правительства:',
      choices: [
        'Не влечет прекращение полномочий ответственных секретарей',
        'Влечет перевод ответственного секретаря на другую должность в данном государственном органе',
        'Влечет  перевод ответственного секретаря на другую должность в другом государственном органе',
        'Влечет прекращение полномочий ответственных секретарей'
      ],
      correctAnswer: 'Не влечет прекращение полномочий ответственных секретарей'
    },
    {
      type: EQuestionType.Radio,
      title: 'Акимы административно-территориальных единиц, возглавляющие соответствующие местные исполнительные органы являются: ',
      choices: [
        'Представителями Президента и Правительства',
        'Представителями Президента',
        'Представителями Правительства',
        'Представителями Парламента'
      ],
      correctAnswer: 'Представителями Президента и Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'В отдельных министерствах и центральных исполнительных органах, не входящих в состав Правительства, должность ответственного секретаря может не вводиться по решению:',
      choices: [
        'Президента',
        'Премьер-Министра',
        'Руководителя государственного органа',
        'должность ответственного секретаря во всех министерствах и центральных исполнительных органах'
      ],
      correctAnswer: 'Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Заседания Правительства проводятся не реже: ',
      choices: [
        'Одного раза в месяц',
        'Одного раза в квартал',
        'Одного раза в полугодие',
        'Одного раза в год'
      ],
      correctAnswer: 'Одного раза в месяц'
    },
    {
      type: EQuestionType.Radio,
      title: 'Отчеты членов Правительства, руководителей центральных и местных государственных органов заслушивает: ',
      choices: [
        'Премьер-Министр',
        'Президент',
        'Мажилис',
        'Сенат'
      ],
      correctAnswer: 'Премьер-Министр'
    },
    {
      type: EQuestionType.Radio,
      title: 'Подписывает межправительственные договоры и соглашения: ',
      choices: [
        'Премьер-Министр',
        'Министр иностранных дел',
        'Президент',
        'Председатель Сената  Парламента'
      ],
      correctAnswer: 'Премьер-Министр'
    },
    {
      type: EQuestionType.Radio,
      title: 'Руководство соответствующими государственными органами, входящими в состав Правительства, осуществляет:',
      choices: [
        'Члены Правительства',
        'Аким',
        'Премьер-Министр',
        'Ответственные секретари'
      ],
      correctAnswer: 'Члены Правительства'
    }
  ],
  time: 10,
  passingScore: 5,
  questionsTotal: 10
};
