export const environment = {
  production: false,
  firebase: {
    apiKey: 'AIzaSyAw57r2QHl0BosGF2UBqkcRznHJH943q-U',
    authDomain: 'kyzmet-app.firebaseapp.com',
    projectId: 'kyzmet-app',
    storageBucket: 'kyzmet-app.appspot.com',
    messagingSenderId: '554091049977',
    appId: '1:554091049977:web:3e5beb6687d5a0da950328'
  },
  locales: ['ru', 'kz'],
  defaultLocale: 'ru',
};
