import {EQuestionType, ITest} from '../../../shared/models/test.model';

export const selfService: ITest = {
  id: 'selfService',
  title: 'O местном гос. управлении и самоуправлении',
  description: '<p>Тест на знание закона «O местном государственном управлении и самоуправлении в РК»</p>',
  questions: [
    {
      type: EQuestionType.Radio,
      title: 'Полномочия маслихата начинаются:',
      choices: [
        'с момента открытия первой сессии',
        'с момента назначения секретаря маслихата',
        'в первый вторник месяца, следюущего за месяцом избрания маслихата',
        'с момента избирания маслихата'
      ],
      correctAnswer: 'с момента открытия первой сессии'
    },
    {
      type: EQuestionType.Radio,
      title: 'Кандидатуры на должность секретаря Маслихата выдвигаются:',
      choices: [
        'депутатами маслихата',
        'акимом соответствующей территории',
        'предыдушим секретарем сессии маслихата',
        'местным сообществом'
      ],
      correctAnswer: 'депутатами маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Срок полномочий маслихата:',
      choices: [
        '5 лет',
        '4 года',
        '6 лет',
        '3 года'
      ],
      correctAnswer: '5 лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'На какой срок избирается секретарь Маслихата:',
      choices: [
        'на срок полномочий маслихата',
        'на срок полномочий постоянной комиссии маслихата',
        'на три года',
        'на срок полномочий депутатов Мажилиса'
      ],
      correctAnswer: 'на срок полномочий маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Координирует деятельность постоянных комиссий и иных органов маслихата, и депутатских групп:',
      choices: [
        'секретарь маслихата',
        'аппарат маслихата',
        'сотрудник маслихата',
        'аппарат акима'
      ],
      correctAnswer: 'секретарь маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Маслихатом может быть выражен вотум недоверия:',
      choices: [
        'акиму',
        'любому государственному служащему',
        'секретарю маслихата',
        'депутату маслихата'
      ],
      correctAnswer: 'акиму'
    },
    {
      type: EQuestionType.Radio,
      title: 'Организованная группа депутатов, представляющих политическую партию или иное общественное объединение, создаваемая в целях выражения интересов соответствующей политической партии или иного общественного объединения в маслихате:',
      choices: [
        'фракция',
        'депутатская группа',
        'инициативная группа',
        'рабочая группа'
      ],
      correctAnswer: 'фракция'
    },
    {
      type: EQuestionType.Radio,
      title: 'Коллегиальный исполнительный орган, осуществляющий в пределах своей компетенции местное государственное управление и самоуправление на соответствующей территории является:',
      choices: [
        'местный исполнительный орган (акимат)',
        'аппарат маслихата',
        'местный представительный орган (маслихат)',
        'аппарат акима'
      ],
      correctAnswer: 'местный исполнительный орган (акимат)'
    },
    {
      type: EQuestionType.Radio,
      title: 'Выборный орган, избираемый населением соответствующей территории, выражающий волю населения и определяющий меры, необходимые для ее реализации, контролирующий их осуществление:',
      choices: [
        'местный представительный орган (маслихат)',
        'аппарат акима',
        'местный исполнительный орган (акимат)',
        'аппарат маслихата',
      ],
      correctAnswer: 'местный представительный орган (маслихат)'
    },
    {
      type: EQuestionType.Radio,
      title: 'Формы деятельности местного сообщества:',
      choices: [
        'сход, собрание',
        'конференция, сход',
        'собрание, заседание',
        'сессия, собрание'
      ],
      correctAnswer: 'сход, собрание'
    },
    {
      type: EQuestionType.Radio,
      title: 'Планы развития территорий, принимаемые маслихатами и акиматами, должны соответствовать:',
      choices: [
        'стратегическим планам развития Республики Казахстан',
        'комплексным планам центральных государственных органов',
        'решениям руководителей центральных исполнительных органов',
        'решениям органов международных организаций'
      ],
      correctAnswer: 'стратегическим планам развития Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Депутатом маслихата может быть избран гражданин, достигший:',
      choices: [
        'двадцати лет',
        'двадцати пяти лет',
        'тридцати лет',
        'восемнадцати лет'
      ],
      correctAnswer: 'двадцати лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Полномочия маслихата заканчиваются:',
      choices: [
        'с началом работы первой сессии маслихата нового созыва',
        'в случае невозможности участия секретаря сессии в работе маслихата',
        'с момента реорганизации (присоединения, слияния, преобразования, выделения или разделения) соответствующей административно-территориальной единицы',
        'по решению местного сообщества'
      ],
      correctAnswer:  'с началом работы первой сессии маслихата нового созыва'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае реорганизации (присоединения, слияния, преобразования, выделения или разделения) административно-территориальной единицы полномочия депутатов соответствующего маслихата:',
      choices: [
        'сохраняются до начала работы сессии вновь избранного маслихата',
        'прекращаются',
        'передаются местному сообществу',
        'приостанавливаются'
      ],
      correctAnswer: 'сохраняются до начала работы сессии вновь избранного маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Проекты решений маслихатов, предусматривающие сокращение местных бюджетных доходов или увеличение местных бюджетных расходов, могут быть внесены на рассмотрение лишь при наличии положительного заключения:',
      choices: [
        'акима',
        'Республиканской бюджетной комиссии',
        'местного сообщества',
        'ревизионной комиссии'
      ],
      correctAnswer: 'акима'
    },
    {
      type: EQuestionType.Radio,
      title: 'Первая сессия вновь избранного маслихата созывается:',
      choices: [
        'председателем соответствующей территориальной избирательной комиссии',
        'акимом соответствующей территории',
        'собранием местного сообщества',
        'сходом местного сообщества'
      ],
      correctAnswer: 'председателем соответствующей территориальной избирательной комиссии'
    },
    {
      type: EQuestionType.Radio,
      title: 'Проведение закрытых сессий маслихата:',
      choices: [
        'допускается по решению маслихата',
        'не допускается',
        'допускается по решению акима соответствующей территории',
        'допускается по решению местного сообщества'
      ],
      correctAnswer: 'допускается по решению маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае ликвидации административно-территориальной единицы соответствующий маслихат:',
      choices: [
        'ликвидируется',
        'сохраняет свои полномочия',
        'подлежит реорганизации',
        'подлежит присоединению к маслихату соседней территориально-административной единицы'

      ],
      correctAnswer: 'ликвидируется'
    },
    {
      type: EQuestionType.Radio,
      title: 'Секретарь маслихата избирается:',
      choices: [
        'маслихатом из числа его депутатов',
        'населением соответствующей административно-территориальной единицы',
        'акиматом соответствующей территории',
        'временной комиссией маслихата'
      ],
      correctAnswer: 'маслихатом из числа его депутатов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Заседания постоянных комиссий считаются правомочными, если на них присутствует:',
      choices: [
        'более половины от общего числа их членов',
        'не менее двух третей от общего числа депутатов, входящих в их состав',
        'треть общего числа депутатов, входящих в их состав',
        'все депутаты, входящие в их состав'
      ],
      correctAnswer: 'более половины от общего числа их членов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Контроль за исполнением местного бюджета, программ развития территорий отнесено к компетенции:',
      choices: [
        'маслихата',
        'акимата',
        'местного сообщества',
        'территориального подразделения центрального исполнительного органа'
      ],
      correctAnswer: 'маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решения маслихата, касающиеся прав, свобод и обязанностей граждан:',
      choices: [
        'подлежат официальному опубликованию в установленном законодательством порядке',
        'требуют наличия положительного заключения местного сообщества',
        'подлежат обязательному согласованию с акимом соответствующей территории',
        'требуют наличия положительного заключения ревизионной комиссии'
      ],
      correctAnswer: 'подлежат официальному опубликованию в установленном законодательством порядке'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решения маслихатов, не соответствующие Конституции и законодательству Республики Казахстан подлежат отмене:',
      choices: [
        'самим маслихатом либо в судебном порядке',
        'только в судебном порядке',
        'Президентом',
        'акимом соответствующей территории'
      ],
      correctAnswer: 'самим маслихатом либо в судебном порядке'
    },
    {
      type: EQuestionType.Radio,
      title: 'Секретарь маслихата не вправе:',
      choices: [
        'состоять в постоянных комиссиях маслихата',
        'контролировать рассмотрение запросов депутатов и депутатских обращений',
        'представлять в маслихат информацию об обращениях избирателей и о принятых по ним мерах',
        'издавать распоряжения'
      ],
      correctAnswer: 'состоять в постоянных комиссиях маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Полномочия секретаря маслихата в случае его отсутствия осуществляются:',
      choices: [
        'председателем одной из постоянных комиссий маслихата или депутатом маслихата',
        'только секретарем сессии маслихата',
        'представителем местного сообщества',
        'представителем акимата'
      ],
      correctAnswer: 'председателем одной из постоянных комиссий маслихата или депутатом маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Полномочия депутата маслихата начинаются с момента:',
      choices: [
        'регистрации его в качестве депутата маслихата соответствующей территориальной избирательной комиссией',
        'назначения секретаря сессии маслихата',
        'проведения первой сессии маслихата',
        'получения согласия местного сообщества'
      ],
      correctAnswer: 'регистрации его в качестве депутата маслихата соответствующей территориальной избирательной комиссией'
    },
    {
      type: EQuestionType.Radio,
      title: 'Полномочия депутата маслихата прекращаются:',
      choices: [
        'с момента прекращения полномочий маслихата',
        'с момента реорганизации (присоединения, слияния, преобразования, выделения или разделения) административно-территориальной единицы',
        'по решению секретаря сессии маслихата',
        'по распоряжению секретаря маслихата'
      ],
      correctAnswer: 'с момента прекращения полномочий маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Систематическое невыполнение депутатом своих обязанностей, в том числе отсутствия без уважительных причин на пленарных заседаниях сессии маслихата более трех раз подряд, влечет:',
      choices: [
        'досрочное прекращение полномочий депутата маслихата',
        'прекращение полномочий маслихата',
        'отставку депутата маслихата',
        'отставку с сессии маслихата'
      ],
      correctAnswer: 'досрочное прекращение полномочий депутата маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решение о досрочном прекращении полномочий депутата принимается:',
      choices: [
        'по представлению избирательной комиссии на сессии маслихата большинством голосов от общего числа присутствующих депутатов',
        'по представлению большинства от общего числа депутатов маслихата секретарем маслихата',
        'секретарем сессии маслихата единолично',
        'по представлению большинства от общего числа депутатов маслихата акимом'
      ],
      correctAnswer: 'по представлению избирательной комиссии на сессии маслихата большинством голосов от общего числа присутствующих депутатов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Местное самоуправление осуществляется:',
      choices: [
        'членами местного сообщества непосредственно, а также через маслихаты и другие органы местного самоуправления',
        'акиматом соответствующей территории',
        'территориальным подразделением центрального государственного органа',
        'территориальной избирательной комиссией'
      ],
      correctAnswer: 'членами местного сообщества непосредственно, а также через маслихаты и другие органы местного самоуправления'
    },
    {
      type: EQuestionType.Radio,
      title: 'Маслихаты отчитываются перед населением о проделанной работе маслихата, деятельности его постоянных комиссий:',
      choices: [
        'не реже одного раза в год',
        'не реже двух раз в год',
        'не реже одного раза в квартал',
        'не реже двух раз в квартал'
      ],
      correctAnswer: 'не реже одного раза в год'
    },
    {
      type: EQuestionType.Radio,
      title: 'Сессия маслихата считается правомочной, если на сессии присутствует:',
      choices: [
        'не менее двух третей от общего числа депутатов маслихата',
        'более половины от общего числа депутатов, входящих в ее состав',
        'не менее трети от общего числа депутатов, входящих в ее состав',
        'все депутаты, входящие в ее состав'
      ],
      correctAnswer: 'не менее двух третей от общего числа депутатов маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Временные комиссии маслихата вправе образовывать:',
      choices: [
        'маслихат либо секретарь маслихата',
        'члены местного сообщества',
        'территориальный совет местного самоуправления',
        'территориальное подразделение центрального государственного органа'
      ],
      correctAnswer: 'маслихат либо секретарь маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решение о созыве сессии маслихата принимает:',
      choices: [
        'секретарь маслихата',
        'аким',
        'представитель местного сообщества',
        'любой депутат маслихата'
      ],
      correctAnswer: 'секретарь маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Каждому депутату маслихата гарантируется:',
      choices: [
        'защита его прав, чести и достоинства',
        'оплата жилищно-коммунальных услуг в полном объеме',
        'бесплатные зарубежные поездки',
        'сохранение прежней работы (должности) или предоставление равноценной работы (должности) при прекращении или по истечении его полномочий'
      ],
      correctAnswer: 'защита его прав, чести и достоинства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Взыскание на депутата маслихата налагается:',
      choices: [
        'большинством голосов от общего числа присутствующих депутатов',
        'должностными лицами (органом), имеющими такое право в соответствии с должностными полномочиями',
        'акимом соответствующей административно-территориальной единицы',
        'секретарем маслихата'
      ],
      correctAnswer: 'большинством голосов от общего числа присутствующих депутатов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Депутат маслихата вправе по вопросам, отнесенным к компетенции маслихата, обратиться с официальным письменным запросом:',
      choices: [
        'прокурору и должностным лицам территориальных подразделений центральных государственных органов, исполнительных органов, финансируемых из местных бюджетов',
        'председателю и членам Центральной избирательной комиссии',
        'к Премьер-министру и членам Правительства',
        'к прокурору, Председателю Комитета национальной безопасности'
      ],
      correctAnswer: 'прокурору и должностным лицам территориальных подразделений центральных государственных органов, исполнительных органов, финансируемых из местных бюджетов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лицо, назначающее акима, либо лицо, им уполномоченное, вносит представление о даче согласия на назначение на должность акима на рассмотрение:',
      choices: [
        'маслихата',
        'депутатской группы',
        'территориальной избирательной комиссии',
        'местного сообщества'
      ],
      correctAnswer: 'маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Инициирование прекращения полномочий депутата Сената Парламента по решению выборщиков должно быть поддержано:',
      choices: [
        'не менее чем 25 % голосов от общего числа выборщиков, представляющих все маслихаты области, города республиканского значения и столицы, но не менее чем 25 % голосов выборщиков от одного маслихата',
        'более 50 % голосов от общего числа выборщиков, представляющих все маслихаты области, города республиканского значения или столицы',
        'не менее чем 10 % голосов от общего числа выборщиков, представляющих все маслихаты области, города республиканского значения или столицы, но не более чем 25 % голосов выборщиков от одного маслихата',
        'более 30 % голосов от общего числа выборщиков, представляющих все маслихаты области, города республиканского значения или столицы'
      ],
      correctAnswer: 'не менее чем 25 % голосов от общего числа выборщиков, представляющих все маслихаты области, города республиканского значения и столицы, но не менее чем 25 % голосов выборщиков от одного маслихата'
    },
    {
      type: EQuestionType.Radio,
      title: 'Акимат области, города республиканского значения, столицы формируется:',
      choices: [
        'акимом из заместителей акима, руководителя аппарата акима, первых руководителей исполнительных органов, финансируемых из местного бюджета',
        'акимом из руководителя аппарата акима, руководителей территориальных подразделений центральных исполнительных органов',
        'Правительством Республики Казахстан из акима, заместителей акима, руководителя аппарата акима и иных должностных лиц',
        'Президентом по согласованию с Премьер-Министром из акима, руководителя аппарата акима, первых руководителей исполнительных органов, финансируемых из местного бюджета'
      ],
      correctAnswer: 'акимом из заместителей акима, руководителя аппарата акима, первых руководителей исполнительных органов, финансируемых из местного бюджета'
    },
    {
      type: EQuestionType.Radio,
      title: 'Акимат области, города республиканского значения, столицы в соответствии с законодательством Республики Казахстан:',
      choices: [
        'осуществляет регулятивные, реализационные и (или) контрольные функции, обеспечивающие решение задач местного значения в пределах соответствующей административно-территориальной единицы',
        'формирует государственную политику по развитию науки и техники, внедрению новых технологий, здравоохранения, туризма и спорта',
        'организует управление государственной собственностью, вырабатывает и осуществляет меры по ее использованию, обеспечивает защиту права государственной собственности',
        'разрабатывает и осуществляет мероприятия по обеспечению рационального использования и охраны природных ресурсов и окружающей природной среды'
      ],
      correctAnswer: 'осуществляет регулятивные, реализационные и (или) контрольные функции, обеспечивающие решение задач местного значения в пределах соответствующей административно-территориальной единицы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Аким области, города республиканского значения, столицы назначается на должность:',
      choices: [
        'Президентом с согласия маслихата области, города республиканского значения, столицы',
        'Президентом по представлению Премьер-Министра',
        'избирается жителями, проживающими на территории соответствующей административно-территориальной единицы',
        'Премьер-Министром с согласия маслихата области (города республиканского значения и столицы).'
      ],
      correctAnswer: 'Президентом с согласия маслихата области, города республиканского значения, столицы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Аким области, города республиканского значения, столицы по вопросам своей компетенции и по вопросам, отнесенным к компетенции областного, города республиканского значения, столицы акимата, несет ответственность перед:',
      choices: [
        'Президентом и Правительством',
        'Премьер-Министром',
        'Правительством',
        'населением, проживающим на территории соответствующей административно-территориальной единицы'
      ],
      correctAnswer: 'Президентом и Правительством'
    },
    {
      type: EQuestionType.Radio,
      title: 'Численный состав депутатов в областном маслихате, маслихатах городов республиканского значения и столицы:',
      choices: [
        'до 50',
        'до 60',
        'до 30',
        'до 40'
      ],
      correctAnswer: 'до 50'
    },
    {
      type: EQuestionType.Radio,
      title: 'Численный состав депутатов в городском маслихате:',
      choices: [
        'до 30',
        'до 40',
        'до 50',
        'до 25'
      ],
      correctAnswer: 'до 30'
    },
    {
      type: EQuestionType.Radio,
      title: 'Численный состав депутатов в районном маслихате:',
      choices: [
        'до 25',
        'до 50',
        'до 40',
        'до 30'
      ],
      correctAnswer: 'до 25'
    },
    {
      type: EQuestionType.Radio,
      title: 'Аким района (города областного значения) по вопросам своей компетенции и по вопросам, отнесенным к компетенции районного (города областного значения) акимата, несет ответственность перед:',
      choices: [
        'Президентом, Правительством и акимом области',
        'Президентом',
        'Правительством',
        'акимом области'
      ],
      correctAnswer: 'Президентом, Правительством и акимом области'
    },
    {
      type: EQuestionType.Radio,
      title: 'Акты акимата и (или) акима, принятые в пределах их компетенции, имеют обязательную силу:',
      choices: [
        'на всей территории соответствующей административно-территориальной единицы',
        'на всей территории Республики Казахстан',
        'на территории соответствующего района',
        'за пределами соответствующей территории'
      ],
      correctAnswer: 'на всей территории соответствующей административно-территориальной единицы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Действие актов акимата и (или) акима может быть отменено либо приостановлено полностью или частично:',
      choices: [
        'Президентом, Правительством, вышестоящим акиматом и (или) акимом, самим акиматом и (или) акимом, а также решением суда',
        'Президентом и Правительством',
        'вышестоящим акиматом и (или) акимом, самим акиматом и (или) акимом',
        'решением суда'
      ],
      correctAnswer: 'Президентом, Правительством, вышестоящим акиматом и (или) акимом, самим акиматом и (или) акимом, а также решением суда'
    },
    {
      type: EQuestionType.Radio,
      title: 'Аппарат акима является:',
      choices: [
        'государственным учреждением, содержащимся за счет местного бюджета',
        'коллегиальным органом, создаваемым при местных исполнительных органах районов (городов областного значения)',
        'консультативно-совещательным органом, финансируемым из местного бюджета',
        'юридическим лицом, содержащимся за счет республиканского бюджета'
      ],
      correctAnswer: 'государственным учреждением, содержащимся за счет местного бюджета'
    }
  ],
  time: 10,
  passingScore: 5,
  questionsTotal: 10
};
