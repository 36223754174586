import {IExam} from '../../../shared/models/test.model';

export const administrativeCivilServiceThird: IExam = {
  id: 'administrativeCivilServiceThird',
  title: 'Административная госслужба (программа 3)',
  topics: [
    {
      id: 'constitution',
      title: 'Конституция РК',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: 'civilService',
      title: 'Закон «O государственной службе РК»',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: 'antiCorruption',
      title: 'Закон «O противодействии коррупции»',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: 'publicServices',
      title: 'Закон «O государственных услугах»',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: 'selfService',
      title: 'Закон «O местном государственном управлении и самоуправлении в РК»',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: 'adminProcedures',
      title: 'Административный процедурно-процессуальный кодекс РК',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: 'ethicalCode',
      title: 'Этический кодекс государственных служащих РК (правила служебной этики госслужащих)',
      questions: [],
      questionsCount: 10,
      passingScore: 5
    }
  ],
  time: 85,
  questionsTotal: 100,
  passingScore: 50
};

