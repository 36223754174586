import {EQuestionType, ITest} from '../../../shared/models/test.model';

export const constitution: ITest = {
  id: 'constitution',
  title: 'Конституция',
  description: '<p>Тест на знание конституции</p>',
  questions: [
    {
      type: EQuestionType.Radio,
      title: 'Единственным источником государственной власти в Республике Казахстан является:',
      choices: [
        'Народ',
        'Президент',
        'Парламент',
        'Правительство'
      ],
      correctAnswer: 'Народ'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственная власть в Республике Казахстан осуществляется в соответствии с принципом ее разделения на следующие ветви власти:',
      choices: [
        'Законодательную, исполнительную, судебную',
        'Законодательную, управленческую, судебную',
        'Управленческую, судебную, исполнительную',
        'Исполнительную, публичную, законодательную'
      ],
      correctAnswer: 'Законодательную, исполнительную, судебную'
    },
    {
      type: EQuestionType.Radio,
      title: 'Правительство и иные государственные органы в пределах делегированных им полномочий выступают от имени:',
      choices: [
        'Государства',
        'Президента',
        'Народа',
        'От своего имени'
      ],
      correctAnswer: 'Государства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Наряду с Президентом Республики Казахстан правом выступать от имени народа и государства обладает:',
      choices: [
        'Парламент в пределах его конституционных полномочий',
        'Правительство в пределах его конституционных полномочий',
        'Государственные органы, непосредственно подчиненные и подотчетные Президенту',
        'Центральные исполнительные государственные органы'
      ],
      correctAnswer: 'Парламент в пределах его конституционных полномочий'
    },
    {
      type: EQuestionType.Radio,
      title: 'Основные направления внутренней и внешней политики государства определяются:',
      choices: [
        'Президентом',
        'Парламентом',
        'Правительством',
        'исполнительными органы'
      ],
      correctAnswer: 'Президентом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент назначает на должность Премьер-Министра с согласия:',
      choices: [
        'Мажилиса Парламента',
        'Обеих палат Парламента',
        'Конституционного Совета',
        'Сената Парламента'
      ],
      correctAnswer: 'Мажилиса Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Назначение министров иностранных дел, обороны, внутренних дел осуществляется Президентом: ',
      choices: [
        'Самостоятельно',
        'По представлению Премьер-Министра',
        'С согласия Сената Парламента',
        'С согласия Мажилиса Парламента'
      ],
      correctAnswer: 'Самостоятельно'
    },
    {
      type: EQuestionType.Radio,
      title: 'Численный состав депутатов Мажилиса Парламента: ',
      choices: [
        'Сто семь депутатов',
        'Сто девять депутатов',
        'Девяносто восемь депутатов',
        'Сорок семь депутатов'
      ],
      correctAnswer: 'Сто семь депутатов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Срок полномочий депутатов Сената Парламента:',
      choices: [
        'Шесть лет',
        'Семь лет',
        'Пять лет',
        'Девять лет'
      ],
      correctAnswer: 'Шесть лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Срок полномочий депутатов Мажилиса Парламента: ',
      choices: [
        'Пять лет',
        'Шесть лет',
        'Восемь лет',
        'Семь лет'
      ],
      correctAnswer: 'Пять лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Численный состав членов Конституционного Совета: ',
      choices: [
        'Семь членов',
        'Пятнадцать членов',
        'Десять членов',
        'Одиннадцать членов'
      ],
      correctAnswer: 'Семь членов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Законопроект, рассмотренный и одобренный большинством голосов от общего числа депутатов: Мажилиса Парламента',
      choices: [
        'Передается в Сенат Парламента',
        'Представляется на подпись Президенту',
        'Вводится в действие',
        'Подлежит официальному опубликованию'
      ],
      correctAnswer: 'Передается в Сенат Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решения, приговоры и иные постановления судов:',
      choices: [
        'Имеют обязательную силу на всей территории Республики Казахстан',
        'Имеют высшую юридическую силу и прямое действие на всей территории Республики Казахстан',
        'Являются окончательными и обжалованию не подлежат',
        'Имеют обязательную силу на территории отдельной административно-территориальной единицы'
      ],
      correctAnswer: 'Имеют обязательную силу на всей территории Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Судья при отправлении правосудия подчиняется: ',
      choices: [
        'Только Конституции и закону',
        'Президенту Республики Казахстан',
        'Конституционному Совету',
        'Председателю Верховного Суда'
      ],
      correctAnswer: 'Только Конституции и закону'
    },
    {
      type: EQuestionType.Radio,
      title: 'При применении закона судья должен руководствоваться следующим принципом:',
      choices: [
        'Лицо считается невиновным в совершении преступления, пока его виновность не будет признана вступившим в законную силу приговором суда',
        'Лицо может быть подвергнуто повторно уголовной или административной ответственности за одно и то же правонарушение',
        'Законы, устанавливающие или усиливающие ответственность, возлагающие новые обязанности на граждан или ухудшающие их положение имеют обратную силу',
        'Подсудность гражданина может быть изменена без его согласия'
      ],
      correctAnswer: 'Лицо считается невиновным в совершении преступления, пока его виновность не будет признана вступившим в законную силу приговором суда'
    },
    {
      type: EQuestionType.Radio,
      title: 'Высший судебный орган в Республике Казахстан: ',
      choices: [
        'Верховный Суд',
        'Конституционный Совет',
        'Высший Судебный Совет',
        'Конституционный суд'
      ],
      correctAnswer: 'Верховный Суд'
    },
    {
      type: EQuestionType.Radio,
      title: 'Председатель и судьи Верховного Суда  избираются',
      choices: [
        'Сенатом Парламента по представлению Президента',
        'Высшим Судебным Советом',
        'Мажилисом Парламента по представлению Президента',
        'Мажилисом Парламента по рекомендации Высшего Судебного Совета'
      ],
      correctAnswer: 'Сенатом Парламента по представлению Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Должность судьи несовместима с:',
      choices: [
        'Депутатским мандатом',
        'Занятием преподавательской деятельностью',
        'Занятием  научной деятельностью',
        'Занятием творческой деятельностью'
      ],
      correctAnswer: 'Депутатским мандатом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Форма правления в Республике Казахстан: ',
      choices: [
        'Президентская республика',
        'Парламентская республика',
        'Смешанная форма правления',
        'Унитарная'
      ],
      correctAnswer: 'Президентская республика'
    },
    {
      type: EQuestionType.Radio,
      title: 'Местный представительный орган в Республике Казахстан ',
      choices: [
        'Маслихат',
        'Местный совет',
        'Акимат',
        'Ревизионная комиссия'
      ],
      correctAnswer: 'Маслихат'
    },
    {
      type: EQuestionType.Radio,
      title: 'Акимы областей (городов республиканского значения, столицы) назначаются: ',
      choices: [
        'Президентом с согласия маслихатов',
        'Председателем Сената Парламента с согласия маслихатов',
        'Председателем Мажилиса Парламента с согласия маслихатов',
        'Председателем сессии Маслихата'
      ],
      correctAnswer: 'Президентом с согласия маслихатов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Казахстан гарантирует защиту и покровительство за пределами Республики:',
      choices: [
        'Всем своим гражданам',
        'Сотрудникам дипломатических учреждений',
        'Всем государственным служащим',
        'Гражданам, находящимся в служебной командировке'
      ],
      correctAnswer: 'Всем своим гражданам'
    },
    {
      type: EQuestionType.Radio,
      title: 'Арест и содержание под стражей допускаются только в предусмотренных законом случаях:',
      choices: [
        'Лишь с санкции суда',
        'Лишь с санкции прокурора',
        'С санкции органа, ведущего преследование',
        'Без санкционирования на срок не более 2х часов'
      ],
      correctAnswer: 'Лишь с санкции суда'
    },
    {
      type: EQuestionType.Radio,
      title: 'Смертная казнь устанавливается законом как исключительная мера наказания за:',
      choices: [
        'Террористические преступления, сопряженные с гибелью людей',
        'Сбыт и хранение наркотических веществ в особо крупных размерах',
        'Насильственное изменение конституционного строя Республики Казахстан',
        'Преступления против несовершеннолетних, сопряженные с гибелью людей'
      ],
      correctAnswer: 'Террористические преступления, сопряженные с гибелью людей'
    },
    {
      type: EQuestionType.Radio,
      title: 'Принудительный труд в Республике Казахстан допускается:',
      choices: [
        'По приговору суда либо в условиях чрезвычайного или военного положения',
        'Принудительный труд в Республике Казахстан запрещен',
        'Только на основании вступившего в законную силу решению суда',
        'По приговору суда либо по указанию прокурора'
      ],
      correctAnswer: 'По приговору суда либо в условиях чрезвычайного или военного положения'
    },
    {
      type: EQuestionType.Radio,
      title: 'Обязательным образованием в Республике Казахстан является:',
      choices: [
        'Среднее',
        'Начальное',
        'Средне-специальное',
        'Высшее'
      ],
      correctAnswer: 'Среднее'
    },
    {
      type: EQuestionType.Radio,
      title: 'Любые сомнения в виновности лица толкуются в пользу:',
      choices: [
        'Обвиняемого',
        'Потерпевшего',
        'Свидетеля',
        'Прокурора'
      ],
      correctAnswer: 'Обвиняемого'
    },
    {
      type: EQuestionType.Radio,
      title: 'К действующему праву в Республике Казахстан не относятся:',
      choices: [
        'Решения органов неправительственных организаций',
        'Нормы Конституции, соответствующих ей законов',
        'Нормы международных договорных и иных обязательств Республики Казахстан',
        'Нормативные постановления Конституционного Совета и Верховного Суда'
      ],
      correctAnswer: 'Решения органов неправительственных организаций'
    },
    {
      type: EQuestionType.Radio,
      title: 'В Республике Казахстан деятельность политических партий и профессиональных союзов других государств',
      choices: [
        'Не допускается',
        'Допускается в случае их регистрации в установленном законодательством порядке',
        'Поощряется',
        'Допускается на взаимных началах'
      ],
      correctAnswer: 'Не допускается'
    },
    {
      type: EQuestionType.Radio,
      title: 'На основаниях, условиях и в пределах, установленных законом, в частной собственности может находиться ',
      choices: [
        'Земля',
        'Растительный мир',
        'Водные ресурсы',
        'Животный мир'
      ],
      correctAnswer: 'Земля'
    },
    {
      type: EQuestionType.Radio,
      title: 'Военнослужащие, работники органов национальной безопасности, правоохранительных органов и судьи:',
      choices: [
        'Не должны состоять в партиях, профессиональных союзах, выступать в поддержку какой-либо политической партии',
        'Могут состоять в партиях, профессиональных союзах',
        'Не могут быть членами одновременно двух политических партий',
        'Могут выступать в поддержку какой-либо политической партии'
      ],
      correctAnswer: 'Не должны состоять в партиях, профессиональных союзах, выступать в поддержку какой-либо политической партии'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лишение жилища в Республике Казахстан: ',
      choices: [
        'Допускается по решению суда',
        'Допускается по указанию прокурора',
        'Не допускается',
        'Допускается по решению жилищной комиссии'
      ],
      correctAnswer: 'Допускается по решению суда'
    },
    {
      type: EQuestionType.Radio,
      title: 'Избирать и быть избранными, участвовать в республиканском референдуме имеют право: ',
      choices: [
        'Граждане Республики Казахстан',
        'Граждане, признанные судом недееспособными',
        'Граждане, содержащиеся в местах лишения свободы по приговору суда',
        'Иностранные граждане'
      ],
      correctAnswer: 'Граждане Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Права и свободы человека и гражданина: ',
      choices: [
        'Могут быть ограничены только законами',
        'Не могут быть ограничены',
        'Могут быть ограничены законами, подзаконными нормативными правовыми актами',
        'Могут быть ограничены по политическим мотивам'
      ],
      correctAnswer: 'Могут быть ограничены только законами'
    },
    {
      type: EQuestionType.Radio,
      title: 'Генеральный прокурор назначается на должность Президентом с согласия:',
      choices: [
        'Сената Парламента',
        'Мажилиса Парламента',
        'Правительства',
        'Конституционного Совета'
      ],
      correctAnswer: 'Сената Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае введения Президентом на территории государства военного положения и объявления частичной или общей мобилизации, об этом незамедлительно информируется: ',
      choices: [
        'Парламент',
        'Премьер-Министр',
        'Министерство иностранных дел',
        'Министерство обороны'
      ],
      correctAnswer: 'Парламент'
    },
    {
      type: EQuestionType.Radio,
      title: 'Введение на всей территории Республики Казахстана и в отдельных его местностях чрезвычайного положения осуществляется Президентом',
      choices: [
        'После официальных консультаций с Премьер-Министром и председателями Палат Парламента Республики',
        'После проведения республиканского референдума',
        'После официальных консультаций с Председателем Комитета Национальной безопасности',
        'Принимает решение единолично и информирует об этом Парламент'
      ],
      correctAnswer: 'После официальных консультаций с Премьер-Министром и председателями Палат Парламента Республики'
    },
    {
      type: EQuestionType.Radio,
      title: 'Изменения и дополнения в Конституцию не могут быть внесены:',
      choices: [
        'Актом Президента',
        'Республиканским референдумом',
        'Принятым Парламентом законом',
        'При наличии заключения Конституционного Совета'
      ],
      correctAnswer: 'Актом Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'К исключительному ведению Мажилиса относится: ',
      choices: [
        'Объявление очередных выборов Президента',
        'Лишение неприкосновенности Генерального Прокурора, Председателя и судей Верховного Суда Республики',
        'Избрание и освобождение от должности по представлению Президента Председателя Верховного Суда и судей Верховного Суда',
        'Принятие присяги Председателя Верховного Суда и судей Верховного'
      ],
      correctAnswer: 'Объявление очередных выборов Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решение вопросов амнистии отнесено к компетенции ',
      choices: [
        'Парламента',
        'Президента',
        'Органов уголовно-исполнительной системы',
        'Генеральной прокуратуры'
      ],
      correctAnswer: 'Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Мандат депутата Парламента сохраняется: ',
      choices: [
        'В период служебной командировки за пределы территории Республики Казахстан',
        'При выезде на постоянное место жительства за пределы Казахстана',
        'При вступлении в законную силу в отношении его обвинительного приговора суда',
        'При утрате гражданства Республики Казахстан'
      ],
      correctAnswer: 'В период служебной командировки за пределы территории Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Девять депутатов Мажилиса из общего числа его депутатов избираются:  ',
      choices: [
        'Ассамблеей народа Казахстана',
        'На основе всеобщего, равного и прямого избирательного права при тайном голосовании',
        'Большинством голосов депутатов Сената Парламента',
        'На республиканском референдуме'
      ],
      correctAnswer: 'Ассамблеей народа Казахстана'
    },
    {
      type: EQuestionType.Radio,
      title: 'Осуществление помилования граждан отнесено к компетенции ',
      choices: [
        'Президента',
        'Министра внутренних дел',
        'Председателя Сената Парламента',
        'Председателя Мажилиса'
      ],
      correctAnswer: 'Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лицо, за исключением Первого Президента Казахстана, не может быть избрано Президентом: ',
      choices: [
        'Более двух раз подряд',
        'Более трех раз подряд',
        'Более одного раза',
        'Более четырех раз'
      ],
      correctAnswer: 'Более двух раз подряд'
    },
    {
      type: EQuestionType.Radio,
      title: 'Без санкции суда лицо может быть подвергнуто задержанию на срок',
      choices: [
        'Не более семидесяти двух часов',
        'Не более двадцати четырех часов',
        'Не более тридцати двух часов',
        'Не более семидесяти пяти часов'
      ],
      correctAnswer: 'Не более семидесяти двух часов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лишение гражданства в Республике Казахстан:',
      choices: [
        'Допускается лишь по решению суда за совершение террористических преступлений, а также за причинение иного тяжкого вреда жизненно важным интересам Республики Казахстан',
        'Не допускается ни при каких условиях',
        'Допускается по согласованному с Премьер-Министром решению Президента',
        'Допускается по представлению Генерального прокурора'
      ],
      correctAnswer: 'Допускается лишь по решению суда за совершение террористических преступлений, а также за причинение иного тяжкого вреда жизненно важным интересам Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Приоритет над законами Республики Казахстан имеют: ',
      choices: [
        'Международные договоры, ратифицированные Республикой Казахстан',
        'Указы Президента',
        'Постановления Правительства',
        'Распоряжения Премьер-Министра'
      ],
      correctAnswer: 'Международные договоры, ратифицированные Республикой Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Местные представительные органы, выражающие волю населения соответствующих административно-территориальных единиц:',
      choices: [
        'Маслихаты',
        'Акиматы',
        'Ревизионные комиссии',
        'Местные сообщества'
      ],
      correctAnswer: 'Маслихаты'
    },
    {
      type: EQuestionType.Radio,
      title: 'Депутатом маслихата может быть избран гражданин, достигший:',
      choices: [
        'Двадцати лет',
        'Двадцати одного года',
        'Двадцати пяти лет',
        'Тридцати лет'
      ],
      correctAnswer: 'Двадцати лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Форма государственного устройства Республики Казахстан:',
      choices: [
        'Унитарное государство',
        'Йедеративное государство',
        'Содружество',
        'Конфедеративное государство'
      ],
      correctAnswer: 'Унитарное государство'
    },
    {
      type: EQuestionType.Radio,
      title: 'Обязательное условие применения нормативных правовых актов, касающихся прав, свобод и обязанностей граждан:',
      choices: [
        'Официальное опубликование',
        'Заключение Конституционного Совета',
        'Регистрация в органах юстиции',
        'Проведение республиканского референдума'
      ],
      correctAnswer: 'Официальное опубликование'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президентом Республики Казахстан может быть избран гражданин не моложе:',
      choices: [
        'Сорока лет',
        'Сорока пяти лет',
        'Тридцати пяти лет',
        'Ограничений по возрастному признаку для кандидатов Конституцией не установлено'
      ],
      correctAnswer: 'Сорока лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Основанием прекращения полномочий Президента не является',
      choices: [
        'Сложение полномочий Правительства',
        'Вступление в должность вновь избранного Президента',
        'Досрочного освобождение Президента от должности',
        'Отрешение Президента от должности'
      ],
      correctAnswer: 'Сложение полномочий Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Депутатом Парламента может быть лицо, состоящее в гражданстве Республики Казахстан и постоянно проживающее на ее территории последние:',
      choices: [
        'Десять лет',
        'Двадцать лет',
        'Пять лет',
        'Пятнадцать лет'
      ],
      correctAnswer: 'Десять лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Члены Правительства вправе:',
      choices: [
        'Заниматься преподавательской, научной или иной творческой деятельностью',
        'Быть депутатами представительного органа',
        'Занимать иные оплачиваемые должности',
        'Осуществлять предпринимательскую деятельность'
      ],
      correctAnswer: 'Заниматься преподавательской, научной или иной творческой деятельностью'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент рассматривает вопрос о принятии или отклонении отставки Правительства: ',
      choices: [
        'В десятидневный срок',
        'В семидневный срок',
        'В пятидневный срок',
        'В месячный срок',
      ],
      correctAnswer: 'В десятидневный срок'
    },
    {
      type: EQuestionType.Radio,
      title: 'На какой срок избирается Президент Республики Казахстан?',
      choices: [
        'На 5 лет',
        'На 4 года',
        'На 9 лет',
        'На 7 лет'
      ],
      correctAnswer: 'На 5 лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент Республики Казахстан вступает в должность с момента:',
      choices: [
        'Принесения присяги народу Казахстана',
        'Объявления результатов выборов',
        'По истечении десяти дней со дня объявления результатов выборов',
        'По истечении десяти дней со дня принесения присяги народу Казахстана'
      ],
      correctAnswer: 'Принесения присяги народу Казахстана'
    },
    {
      type: EQuestionType.Radio,
      title: 'Звание экс-Президента имеют:',
      choices: [
        'Все бывшие Президенты, кроме отрешенных от должности',
        'Все бывшие президенты',
        'Все бывшие Президенты, кроме досрочно освобожденных от должности',
        'Избранные Президенты, но не вступившие в должность'
      ],
      correctAnswer: 'Все бывшие Президенты, кроме отрешенных от должности'
    },
    {
      type: EQuestionType.Radio,
      title: 'В круг субъектов, наделенных правом законодательной инициативы, не входят:',
      choices: [
        'Неправительственные организации',
        'Президент',
        'Депутаты Парламента',
        'Правительство'
      ],
      correctAnswer: 'Неправительственные организации'
    },
    {
      type: EQuestionType.Radio,
      title: 'Право законодательной инициативы реализуется: ',
      choices: [
        'Исключительно в Мажилисе Парламента',
        'Исключительно в Сенате Парламента',
        'На совместном заседании Палат Парламента',
        'В Правительстве'
      ],
      correctAnswer: 'Исключительно в Мажилисе Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Руководит деятельностью министерств, государственных комитетов, иных центральных и местных исполнительных органов:',
      choices: [
        'Правительство',
        'Премьер-Министр',
        'Президент',
        'Парламент'
      ],
      correctAnswer: 'Правительство'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент вправе принять решение о прекращении полномочий Правительства и освободить от должности любого его члена: ',
      choices: [
        'По собственной инициативе',
        'По инициативе Председателя Мажилиса Парламента',
        'По инициативе Председателя Сената Парламента',
        'По инициативе Руководителя Администрации Президента'
      ],
      correctAnswer: 'По собственной инициативе'
    },
    {
      type: EQuestionType.Radio,
      title: 'Направляет в Конституционный Совет обращение о рассмотрении вступившего в силу закона или иного правового акта на соответствие Конституции:  ',
      choices: [
        'Президент',
        'Руководитель ведомства центрального государственного органа',
        'Премьер-Министр',
        'Руководитель центрального исполнительного государственного органа'
      ],
      correctAnswer: 'Президент'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае, если суд усмотрит, что закон или иной нормативный правовой акт, подлежащий применению, ущемляет закрепленные Конституцией права и свободы человека и гражданина, он обязан обратиться с представлением о признании этого акта неконституционным:',
      choices: [
        'В Конституционный Совет',
        'В Верховный Суд',
        'В органы прокуратуры',
        'В Высший Судебный Совет'
      ],
      correctAnswer: 'В Конституционный Совет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Высший надзор за соблюдением законности на территории Республики Казахстан осуществляет',
      choices: [
        'Прокуратура',
        'Парламент',
        'Президент',
        'Верховный Суд'
      ],
      correctAnswer: 'Прокуратура'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решает вопросы гражданства Республики, предоставления политического убежища:',
      choices: [
        'Президент',
        'Председатель Сената Парламента',
        'Премьер-Министр',
        'Генеральный прокурор'
      ],
      correctAnswer: 'Президент'
    },
    {
      type: EQuestionType.Radio,
      title: 'Избрание депутатов Сената осуществляется: ',
      choices: [
        'На основе косвенного избирательного права при тайном голосовании',
        'На основе прямого избирательного права при тайном голосовании',
        'На республиканском референдуме',
        'На основе всеобщего, равного и прямого голосования'
      ],
      correctAnswer: 'На основе косвенного избирательного права при тайном голосовании'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае досрочного освобождения или отрешения от должности Президента, а также его смерти: полномочия Президента Республики на оставшийся срок переходят ',
      choices: [
        'К Председателю Сената Парламента',
        'К Председателю Мажилиса Парламента',
        'К Государственному секретарю',
        'К Руководителю Администрации Президента'
      ],
      correctAnswer: 'К Председателю Сената Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лицо, принявшее на себя полномочия Президента в случае его досрочного освобождения или отрешения от должности, а также его смерти: ',
      choices: [
        'Не вправе инициировать изменения и дополнения в Конституцию',
        'Вправе совмещать полномочия Президента и функциональные обязанности, предусмотренные для занимаемой им должности',
        'Вправе именоваться экс-Президентом после избрания нового Президента',
        'Вправе инициировать изменения и дополнения в Конституцию'
      ],
      correctAnswer: 'Не вправе инициировать изменения и дополнения в Конституцию'
    },
    {
      type: EQuestionType.Radio,
      title: 'Применение уголовного закона по аналогии: ',
      choices: [
        'Не допускается',
        'Допускается в отношении преступлений небольшой тяжести',
        'Допускается в отношении преступлений средней тяжести',
        'Допускается в отношении тяжких преступлений'
      ],
      correctAnswer: ''
    },
    {
      type: EQuestionType.Radio,
      title: 'Принципам правосудия противоречит следующее утверждение: ',
      choices: [
        'Обвиняемый обязан доказывать свою невиновность',
        'Любые сомнения в виновности лица толкуются в пользу обвиняемого',
        'В суде каждый имеет право быть выслушанным',
        'Никому не может быть без его согласия изменена подсудность, предусмотренная для него законом'
      ],
      correctAnswer: ''
    },
    {
      type: EQuestionType.Radio,
      title: 'Разрабатывает основные направления социально-экономической политики государства, его обороноспособности, безопасности, обеспечения общественного порядка и организует их осуществление: ',
      choices: [
        'Правительство',
        'Президент',
        'Парламент',
        'Премьер-Министр'
      ],
      correctAnswer: 'Правительство'
    },
    {
      type: EQuestionType.Radio,
      title: 'Двойное гражданство в Республике Казахстан: ',
      choices: [
        'Не признается',
        'Признается за оралманами',
        'Признается',
        'Признается за лицами, имеющими вид на жительство в Республике Казахстан'
      ],
      correctAnswer: 'Не признается'
    },
    {
      type: EQuestionType.Radio,
      title: 'Иностранцы и лица без гражданства, пребывающие в Республике Казахстан:',
      choices: [
        'Пользуются правами и свободами, а также несут обязанности, установленные для граждан, если иное не предусмотрено Конституцией, законами и международными договорами',
        'Пользуются ограниченным объемом прав и свобод, а также несут обязанности, установленные для граждан, если иное не предусмотрено Конституцией, законами и международными договорами',
        'Не вправе защищать свои права и свободы в судах Республики Казахстан',
        'Пользуются ограниченным объемом прав и свобод'
      ],
      correctAnswer: 'Пользуются правами и свободами, а также несут обязанности, установленные для граждан, если иное не предусмотрено Конституцией, законами и международными договорами'
    },
    {
      type: EQuestionType.Radio,
      title: 'Обязательное требование, предъявляемое к кандидатам в Президенты Республики Казахстан:',
      choices: [
        'Гражданство Республики Казахстан по рождению',
        'Юридическое образование',
        'Свободное владение иностранными языками',
        'Наличие опыта работы на руководящих должностях'
      ],
      correctAnswer: 'Гражданство Республики Казахстан по рождению'
    },
    {
      type: EQuestionType.Radio,
      title: 'По необходимости председательствует на заседаниях Правительства по особо важным вопросам:',
      choices: [
        'Президент',
        'Премьер-Министр',
        'Депутат Парламента',
        'Государственный секретарь'
      ],
      correctAnswer: 'Президент'
    },
    {
      type: EQuestionType.Radio,
      title: 'При выходе или исключении депутата Мажилиса из политической партии, от которой в соответствии с конституционным законом он избран: ',
      choices: [
        'Депутат лишается мандата',
        'Действие мандата депутата приостанавливается до его вступления в другую политическую партию',
        'Мандат депутата сохраняется',
        'Вопрос сохранения мандата депутата решается Председателем Мажилиса'
      ],
      correctAnswer: 'Депутат лишается мандата'
    }
  ],
  time: 10,
  passingScore: 5,
  questionsTotal: 10
};
