import {IExam} from '../../../shared/models/test.model';

export const cona: IExam = {
    id: 'cona',
    title: 'Правоохранительная госслужба (программа 2)',
    topics: [],
    time: 90,
    questionsTotal: 105,
    passingScore: 63
};

