<footer class="footer">
  <div class="container">
    <div class="row">
      <div class="col-sm-12 col-md-6 footer__left">
        <p class="footer__copyrights">
          <a href="https://kyzmet.kz/">KYZMET.KZ</a>
          {{ 'FOOTER.COPYRIGHT' | translate }}
        </p>
      </div>
      <div class="col-sm-12 col-md-6 footer__right">
        <!--<p class="footer__dev">
          {{ 'FOOTER.DEVELOPMENT' | translate }}
          <a href="https://whynet.kz/" target="_blank">"WhyNet"</a>
        </p>-->
        <p style="font-size: 10px;margin: 10px;opacity: 0.5;">
            Powered by
            <a href="https://whynet.agency/" target="_blank">
            <img style="height: 15px; margin: -5px 5px; opacity: 1; width: auto" src="https://whynet.agency/wp-content/uploads/2023/08/logo-wn.png">
          </a>
        </p>
      </div>
    </div>
  </div>
</footer>
