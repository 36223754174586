<app-preloader *ngIf="isLoading"></app-preloader>
<div class="page news">
  <div class="page__header news__header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <p class="page__title news__title" [innerHTML]="'INFO.TITLE' | translate"></p>
          <p class="page__sub-title news__sub-title" [innerHTML]="'INFO.SUB_TITLE' | translate "></p>
        </div>
      </div>
    </div>
  </div>
</div>

<section class="section news__wrapper">
  <div class="container">
    <div class="row" *ngIf="isNews">
      <div class="news-item col-xs-12 col-sm-6 col-md-4 col-lg-4"
           *ngFor="let item of news[language$ | async]['items']; let i = index">
        <div class="news-item__inner" [routerLink]="['/news', item.id]">
          <div class="news-item__img" [style.background-image]="'url(' + (item.img ? item.img : '../../../assets/images/posts/' + item.id + '.jpg') + ')'"></div>
          <p class="news-item__title" [title]="item.title">{{item.title}}</p>
          <p class="news-item__description" [innerHTML]="item.text.substring(0, 75) + '...'"></p>
        </div>
      </div>
    </div>
  </div>
</section>
