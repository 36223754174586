import {EQuestionType, ITest} from '../../../shared/models/test.model';

export const civilService: ITest = {
  id: 'civilService',
  title: 'Госслужба',
  description: '<p>Тест на знание закона «O государственной службе РК»</p>',
  questions: [
    {
      type: EQuestionType.Radio,
      title: 'Действие Закона РК «О государственной службе Республики Казахстан» распространяется на:',
      choices: [
        'государственных служащих, проходящих правоохранительную службу, с особенностями, предусмотренными законодательными актами Республики Казахстан о правоохранительной службе',
        'лиц, осуществляющих техническое обслуживание и обеспечивающих функционирование государственных органов',
        'иностранных работников государственных органов',
        'лиц, осуществляющих деятельность в государственных органах на основании трудового договора',
      ],
      correctAnswer: 'государственных служащих, проходящих правоохранительную службу, с особенностями, предусмотренными законодательными актами Республики Казахстан о правоохранительной службе'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственный служащий, к которому поступили подарки вправе:',
      choices: [
        'выкупить их с согласия вышестоящего должностного лица',
        'передать его в собственность иному должностному лицу',
        'оставить его в своей собственности по согласованию с лицом, подарившим подарок',
        'скрыть факт получения подарка при исполнении должностных обязанностей',
      ],
      correctAnswer: 'выкупить их с согласия вышестоящего должностного лица'
    },
    {
      type: EQuestionType.Radio,
      title: 'Вправе ли государственный служащий получать доход от переданного в доверительное управление имущества?   ',
      choices: [
        'вправе',
        'не вправе',
        'вправе, при наличии судебного решения',
        'вправе, в случае согласования с органами государственных доходов',
      ],
      correctAnswer: 'вправе'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не относится к принципам государственной службы в Республике Казахстан:',
      choices: [
        'взаимозаменяемость',
        'законность',
        'нетерпимость к правонарушениям',
        'приоритет прав, свобод и законных интересов граждан перед интересами государства',
      ],
      correctAnswer: 'взаимозаменяемость'
    },
    {
      type: EQuestionType.Radio,
      title: 'Граждане Республики Казахстан приобретают статус государственного служащего с момента:',
      choices: [
        'назначения или избрания на государственную должность',
        'принятия присяги',
        'назначения наставника на период прохождения испытательного срока',
        'заключения трудового договора',
      ],
      correctAnswer: 'назначения или избрания на государственную должность'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нарушение служебной этики государственными служащими влечет',
      choices: [
        'дисциплинарную ответственность',
        'уголовную ответственность',
        'административную ответственность',
        'гражданскую ответственность',
      ],
      correctAnswer: 'дисциплинарную ответственность'
    },
    {
      type: EQuestionType.Radio,
      title: 'Права и обязанности государственных служащих могут устанавливаться:',
      choices: [
        'законами и актами Президента',
        'актами Президента и Правительства',
        'первыми руководителями государственных органов',
        'только законами',
      ],
      correctAnswer: 'законами и актами Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственный служащий вправе: ',
      choices: [
        'заниматься оплачиваемой педагогической деятельностью',
        'быть депутатом представительного органа',
        'заниматься предпринимательской деятельностью',
        'быть представителем по делам третьих лиц в государственном органе, в котором он состоит на службе',
      ],
      correctAnswer: 'заниматься оплачиваемой педагогической деятельностью'
    },
    {
      type: EQuestionType.Radio,
      title: 'Копия нотариально засвидетельствованного договора на доверительное управление имуществом со дня нотариального засвидетельствования представляется государственным служащим в кадровую службу по месту работы в:',
      choices: [
        'десятидневный срок',
        'месячный срок',
        'срок, определяемый уполномоченным органом',
        'срок до трех месяцев',
      ],
      correctAnswer: 'десятидневный срок'
    },
    {
      type: EQuestionType.Radio,
      title: 'Деньги, поступившие на счет государственного служащего без его ведома, подлежат:',
      choices: [
        'перечислению в республиканский бюджет',
        'возвращению дарителю после установления его личности',
        'внесению в фонд государственного органа',
        'перечислению в благотворительный фонд',
      ],
      correctAnswer: 'перечислению в республиканский бюджет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Ограничение, связанное с достижением пенсионного возраста лицом, претендующим на государственную должность, не распространяется:',
      choices: [
        'на политические государственные должности',
        'на ответственных секретарей центральных исполнительных органов',
        'на административные государственные должности',
        'на руководителей аппаратов государственных органов',
      ],
      correctAnswer: 'на политические государственные должности'
    },
    {
      type: EQuestionType.Radio,
      title: 'Служебное удостоверение, выдаваемое государственному служащему, является документом:',
      choices: [
        'подтверждающим его государственную должность и должностные полномочия',
        'удостоверяющим личность',
        'подтверждающим его должностные обязанности',
        'подтверждающим его должностные полномочия',
      ],
      correctAnswer: 'подтверждающим его государственную должность и должностные полномочия'
    },
    {
      type: EQuestionType.Radio,
      title: 'На государственный службу может быть принят гражданин:',
      choices: [
        'не достигший пенсионного возраста',
        'уволенный по отрицательным мотивам из правоохранительных органов, специальных государственных органов и судов, воинской службы',
        'совершивший коррупционное преступление',
        'отказавшийся принять на себя ограничения, установленные Законом «О государственной службе»'
      ],
      correctAnswer: 'не достигший пенсионного возраста'
    },
    {
      type: EQuestionType.Radio,
      title: 'В целях определения эффективности и качества работы государственных служащих проводится:',
      choices: [
        'оценка деятельности государственных служащих',
        'повышение квалификации госслужащих раз в три года',
        'переподготовка государственных служащих',
        'аттестация государственных служащих',
      ],
      correctAnswer: 'оценка деятельности государственных служащих'
    },
    {
      type: EQuestionType.Radio,
      title: 'Результаты оценки деятельности государственных служащих являются основанием для принятия решений по:',
      choices: [
        'выплате бонусов, поощрению, обучению, ротации, понижению в государственной должности либо увольнению',
        'выплате бонусов, поощрению',
        'обучению, ротации',
        'понижению в государственной должности, увольнению',
      ],
      correctAnswer: 'выплате бонусов, поощрению, обучению, ротации, понижению в государственной должности либо увольнению'
    },
    {
      type: EQuestionType.Radio,
      title: 'Политические государственные служащие поступают на государственную службу на основании:',
      choices: [
        'назначения либо избрания',
        'исключительно назначения',
        'исключительно избрания',
        'на конкурсной основе',
      ],
      correctAnswer: 'назначения либо избрания'
    },
    {
      type: EQuestionType.Radio,
      title: 'Сообщение в случае сомнения в правомерности поступившего для исполнения распоряжения подается:',
      choices: [
        'в письменной форме',
        'в устной форме',
        'в нотариально заверенной форме',
        'в любой из перечисленных форм по выбору государственного служащего',
      ],
      correctAnswer: 'в письменной форме'
    },
    {
      type: EQuestionType.Radio,
      title: 'К мерам поощрения, применяемым к государственным служащим не относится:',
      choices: [
        'обеспечение поездки за границу на период трудового отпуска',
        'единовременное денежное вознаграждение',
        'объявление благодарности',
        'награждение ценным подарком',
      ],
      correctAnswer: 'обеспечение поездки за границу на период трудового отпуска'
    },
    {
      type: EQuestionType.Radio,
      title: 'Ответственность за последствия исполнения государственным служащим неправомерного распоряжения несет:',
      choices: [
        'руководитель, подтвердивший данное распоряжение',
        'государственный служащий, исполнивший распоряжение',
        'руководитель государственного органа',
        'государственный служащий, исполнивший распоряжение, и руководитель, издавший распоряжение',
      ],
      correctAnswer: 'руководитель, подтвердивший данное распоряжение'
    },
    {
      type: EQuestionType.Radio,
      title: 'Продвижение по государственной службе в государственном органе ',
      choices: [
        'осуществляется с учетом квалификации, компетенций, способностей, заслуг и добросовестного исполнения государственным служащим своих служебных обязанностей',
        'предполагает процесс обучения для получения академической и ученой степени по программам послевузовского образования',
        'обеспечивается посредством вовлечения государственных служащих в обучающие семинары для совершенствования их компетенций',
        'обеспечивается в целях приобретения государственным служащим профессиональных знаний и опыта вне места постоянной работы',
      ],
      correctAnswer: 'осуществляется с учетом квалификации, компетенций, способностей, заслуг и добросовестного исполнения государственным служащим своих служебных обязанностей'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае призыва государственных служащих на срочную воинскую службу:',
      choices: [
        'им предоставляется отпуск без сохранения заработной платы',
        'им предоставляется  отпуск с сохранением заработной платы',
        'период срочной службы оформляется в государственном органе как служебная командировка',
        'государственный служащий подлежит увольнению с последующим восстановлением',
      ],
      correctAnswer: 'им предоставляется отпуск без сохранения заработной платы'
    },
    {
      type: EQuestionType.Radio,
      title: 'При ротации в другую местность административные государственные служащие обеспечиваются: ',
      choices: [
        'служебным жильем без права приватизации',
        'служебным жильем и компенсационной выплатой',
        'бесплатным земельным участком',
        'надбавкой к должностному окладу',
      ],
      correctAnswer: 'служебным жильем без права приватизации'
    },
    {
      type: EQuestionType.Radio,
      title: 'При письменном подтверждении своего распоряжения вышестоящим по государственной должности руководителем, государственный служащий:',
      choices: [
        'обязан исполнить распоряжение, если его выполнение не влечет действий, относящихся к уголовно наказуемым деяниям',
        'обязан, если его выполнение не влечет действий, которые относятся к административно наказуемым деяниям',
        'не обязан исполнять распоряжение',
        'по своему усмотрению решает вопрос исполнения распоряжения',
      ],
      correctAnswer: 'обязан исполнить распоряжение, если его выполнение не влечет действий, относящихся к уголовно наказуемым деяниям'
    },
    {
      type: EQuestionType.Radio,
      title: 'Конфликт интересов – это:',
      choices: [
        'противоречие между личными интересами государственного служащего и его должностными полномочиями, при котором личные интересы государственного служащего могут привести к неисполнению или ненадлежащему исполнению им своих должностных полномочий',
        'разногласия между работником (работниками) и работодателем (работодателями) по вопросам применения трудового законодательства, выполнения или изменения условий соглашений, трудового и (или) коллективного договоров, актов работодателя',
        'нарушение работником трудовой дисциплины, а также ненадлежащее исполнение трудовых обязанностей',
        'разногласия между работником (работниками) и работодателем (работодателями) по вопросам исполнения срочных поручений вышестоящих государственных органов',
      ],
      correctAnswer: 'противоречие между личными интересами государственного служащего и его должностными полномочиями, при котором личные интересы государственного служащего могут привести к неисполнению или ненадлежащему исполнению им своих должностных полномочий'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае проведения служебного расследования государственный служащий может быть временно отстранен от исполнения должностных полномочий на срок:',
      choices: [
        'не более одного месяца',
        'не более трех месяцев',
        'не более шести месяцев',
        'не более одного года',
      ],
      correctAnswer: 'не более одного месяца'
    },
    {
      type: EQuestionType.Radio,
      title: 'К обязанностям государственных служащих не относится:',
      choices: [
        'получение в установленном порядке информации и материалов, необходимых для исполнения должностных полномочий',
        'руководство принципом законности',
        'сохранение и укрепление доверие общества к государственной службе, государству и его институтам',
        'соблюдение общепринятых морально-этических норм',
      ],
      correctAnswer: 'получение в установленном порядке информации и материалов, необходимых для исполнения должностных полномочий'
    },
    {
      type: EQuestionType.Radio,
      title: 'Меры по предотвращению и урегулированию конфликта интересов должны предприниматься:',
      choices: [
        'всеми  государственными служащими',
        'руководителем соответствующего государственного органа',
        'уполномоченным органом по делам государственной службы',
        'всеми гражданами Республики Казахстан',
      ],
      correctAnswer: 'всеми  государственными служащими'
    },
    {
      type: EQuestionType.Radio,
      title: 'Руководство государственного органа в отношении государственного служащего, сообщившего о случаях коррупционных правонарушений обязано:',
      choices: [
        'принять меры по защите государственного служащего от преследования, ущемляющего его права, свободы и законные интересы',
        'привлечь государственного служащего к дисциплинарной ответственности',
        'направить обращение государственного служащего в правоохранительные органы',
        'оставить обращение государственного служащего без рассмотрения',
      ],
      correctAnswer: 'принять меры по защите государственного служащего от преследования, ущемляющего его права, свободы и законные интересы'
    },
    {
      type: EQuestionType.Radio,
      title: 'При предъявлении к государственному служащему необоснованного публичного обвинения в коррупции:',
      choices: [
        'государственный служащий должен в месячный срок принять меры по его опровержению',
        'государственный служащий подать заявление об увольнении по собственному желанию',
        'государственный служащий не обязан опровергать сведения, не соответствующие действительности',
        'государственный служащий в первую очередь обратиться за защитой в правоохранительные органы',
      ],
      correctAnswer: 'государственный служащий должен в месячный срок принять меры по его опровержению'
    },
    {
      type: EQuestionType.Radio,
      title: 'Продолжительность ежегодного трудового отпуска, предоставляемого государственным служащим, составляет: ',
      choices: [
        'тридцать календарных дней',
        'сорок два календарных  дней',
        'двадцать календарных дней',
        'двадцать рабочих дней',
      ],
      correctAnswer: 'тридцать календарных дней'
    },
    {
      type: EQuestionType.Radio,
      title: 'Размер выплаты пособия для оздоровления, выплачиваемого  при предоставлении ежегодного трудового отпуска, составляет: ',
      choices: [
        'два должностных оклада',
        'один должностной оклад',
        'три должностных оклада',
        'четыре должностных оклада',
      ],
      correctAnswer: 'два должностных оклада'
    },
    {
      type: EQuestionType.Radio,
      title: 'Для индивидуального жилищного строительства государственным служащим, нуждающимся в улучшении жилищных условий, предоставляются:',
      choices: [
        'земельные участки',
        'материальная помощь для приобретения земельного участка',
        'арендное жилье с обязательным условием последующего выкупа',
        'данный вопрос законом не регламентируется',
      ],
      correctAnswer: 'земельные участки'
    },
    {
      type: EQuestionType.Radio,
      title: 'При сокращении штатной численности государственного органа государственному служащему, занимающему сокращаемую государственную должность, при наличии стажа государственной службы не менее трех лет, производится выплата выходного пособия в размере:',
      choices: [
        'четырех среднемесячных заработных плат',
        'трех среднемесячных заработных плат',
        'двух среднемесячных заработных плат',
        'одной среднемесячной заработной платы',
      ],
      correctAnswer: 'четырех среднемесячных заработных плат'
    },
    {
      type: EQuestionType.Radio,
      title: 'К основаниям прекращения полномочий политического государственного служащего на занимаемой политической должности не относится:',
      choices: [
        'длительная зарубежная командировка',
        'утрата им гражданства Республики Казахстан',
        'упразднение (ликвидации) государственного органа',
        'представление политическим государственным служащим заведомо ложных сведений о доходах и имуществе, принадлежащем ему на праве собственности',
      ],
      correctAnswer: 'длительная зарубежная командировка'
    },
    {
      type: EQuestionType.Radio,
      title: 'Медицинским обслуживанием в соответствующих государственных учреждениях здравоохранения пользуются:',
      choices: [
        'государственные служащие и члены их семей, проживающие совместно с ними',
        'только государственные служащие',
        'государственные служащие и члены их семей, независимо от их совместного проживания',
        'государственные служащие и их родственники',
      ],
      correctAnswer: 'государственные служащие и члены их семей, проживающие совместно с ними'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае незаконного увольнения государственных служащих, причиненный им в связи с этим ущерб подлежит возмещению: ',
      choices: [
        'в полном объеме за счет государственного органа, из которого они были уволены',
        'в полном объеме за счет органа, ведущего уголовный процесс, в порядке, установленном уголовно-процессуальным законодательством Республики Казахстан',
        'частично из средств республиканского или местных бюджетов либо из средств Национального Банка Республики Казахстан',
        'в объеме, определенном решением суда из средств местного бюджета',
      ],
      correctAnswer: 'в полном объеме за счет государственного органа, из которого они были уволены'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственный служащий – это:',
      choices: [
        'гражданин Республики Казахстан, занимающий оплачиваемую из республиканского или местных бюджетов либо из средств Национального Банка государственную должность в государственном органе и осуществляющий должностные полномочия в целях реализации задач и функций государства',
        'физическое лицо, состоящее в трудовых отношениях с работодателем и непосредственно выполняющее работу по трудовому договору',
        'лицо, занимающее оплачиваемую штатную должность в казенных предприятиях, государственных учреждениях и осуществляющее должностные полномочия в целях реализации их задач и функций, осуществления технического обслуживания и обеспечения функционирования государственных органов',
        'лицо, осуществляющее техническое обслуживание и обеспечивающее функционирование государственных органов',
      ],
      correctAnswer: 'гражданин Республики Казахстан, занимающий оплачиваемую из республиканского или местных бюджетов либо из средств Национального Банка государственную должность в государственном органе и осуществляющий должностные полномочия в целях реализации задач и функций государства'
    },
    {
      type: EQuestionType.Radio,
      title: 'На государственную службу может быть принят гражданин :',
      choices: [
        'восстановленный в государственной должности по решению суда',
        'который в течение трех лет перед поступлением на государственную службу привлекался к дисциплинарной ответственности за дисциплинарный проступок, дискредитирующий государственную службу',
        'на которого в течение трех лет до поступления на государственную службу за совершение коррупционного правонарушения налагалось в судебном порядке административное взыскание',
        'совершивший коррупционное преступление',
      ],
      correctAnswer: 'восстановленный в государственной должности по решению суда'
    },
    {
      type: EQuestionType.Radio,
      title: 'Представление гражданином и его (ее) супругом (супругой) в органы государственных доходов декларации о доходах и имуществе, принадлежащем им на праве собственности: ',
      choices: [
        'является обязательным условием приема на государственную службу',
        'осуществляется на добровольной основе',
        'не регулируется Законом «О государственной службе Республики Казахстан»',
        'не является условием приема на государственную службу'
      ],
      correctAnswer: 'является обязательным условием приема на государственную службу'
    },
    {
      type: EQuestionType.Radio,
      title: 'Бонус – это:',
      choices: [
        'денежная выплата, устанавливаемая государственным служащим по результатам оценки эффективности их деятельности',
        'денежная доплата к должностному окладу, устанавливаемая административному государственному служащему корпуса «Б»',
        'гарантированный минимум денежных выплат работнику простого неквалифицированного (наименее сложного) труда при выполнении им трудовых обязанностей в нормальных условиях и при нормальной продолжительности рабочего времени',
        'вознаграждение за труд в зависимости от квалификации работника, сложности, количества, качества и условий выполняемой работы, а также выплаты компенсационного и стимулирующего характера'
      ],
      correctAnswer: 'денежная выплата, устанавливаемая государственным служащим по результатам оценки эффективности их деятельности'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственная служба Республики Казахстан – это: ',
      choices: [
        'деятельность государственных служащих в государственных органах по исполнению должностных полномочий, направленная на реализацию задач и функций государственной власти',
        'права и обязанности, предусмотренные конкретной государственной должностью, отвечающие целям и задачам, стоящим перед государственными органами, в которых осуществляют свою деятельность государственные служащие',
        'отношения между работником и работодателем, возникающие при осуществлении прав и обязанностей, предусмотренных трудовым законодательством, соглашениями, трудовым, коллективным договорами и актами работодателя',
        'сформированный в установленном законодательством порядке систематизированный список граждан, претендующих на занятие вакантных либо временно вакантных административных государственных должностей корпуса «А»'
      ],
      correctAnswer: 'деятельность государственных служащих в государственных органах по исполнению должностных полномочий, направленная на реализацию задач и функций государственной власти'
    },
    {
      type: EQuestionType.Radio,
      title: 'Должностное лицо – это:',
      choices: [
        'лицо, постоянно, временно или по специальному полномочию осуществляющее функции представителя власти либо выполняющее организационно-распорядительные или административно-хозяйственные функции в государственных органах',
        'государственный служащий, закрепляемый за государственным служащим, впервые принятым на государственную службу, оказывающий ему практическую помощь в его профессиональной адаптации',
        'физическое лицо, состоящее в трудовых отношениях с работодателем и непосредственно выполняющее работу по трудовому договору',
        'государственный служащий, назначение (избрание), освобождение и деятельность которого носят политико-определяющий характер, который несет ответственность за реализацию политических целей и задач',
      ],
      correctAnswer: 'лицо, постоянно, временно или по специальному полномочию осуществляющее функции представителя власти либо выполняющее организационно-распорядительные или административно-хозяйственные функции в государственных органах'
    },
    {
      type: EQuestionType.Radio,
      title: 'Политический государственный служащий – это:',
      choices: [
        'государственный служащий, назначение (избрание), освобождение и деятельность которого носят политико-определяющий характер, который несет ответственность за реализацию политических целей и задач',
        'государственный служащий, закрепляемый за государственным служащим, впервые принятым на государственную службу, оказывающий ему практическую помощь в его профессиональной адаптации',
        'лицо, постоянно, временно или по специальному полномочию осуществляющее функции представителя власти либо выполняющее организационно-распорядительные или административно-хозяйственные функции в государственных органах',
        'физическое лицо, состоящее в трудовых отношениях с работодателем и непосредственно выполняющее работу по трудовому договору',
      ],
      correctAnswer: 'государственный служащий, назначение (избрание), освобождение и деятельность которого носят политико-определяющий характер, который несет ответственность за реализацию политических целей и задач'
    },
    {
      type: EQuestionType.Radio,
      title: 'Если международным договором, ратифицированным Республикой Казахстан, установлены иные правила, чем те, которые предусмотрены в Законе «О государственной службе», то применяются:',
      choices: [
        'нормы международного договора',
        'нормы закона',
        'одних из двух актов по усмотрению правоприменителя',
        'аналогия права',
      ],
      correctAnswer: 'нормы международного договора'
    },
    {
      type: EQuestionType.Radio,
      title: 'Действие Закона «О государственной службе» не распространяется на:',
      choices: [
        'лиц, осуществляющих техническое обслуживание и обеспечивающих функционирование государственных органов',
        'государственных служащих, находящихся в трудовом отпуске',
        'государственных служащих, проходящих правоохранительную службу, с особенностями, предусмотренными законодательными актами Республики Казахстан о правоохранительной службе',
        'лиц, занимающих административные государственные должности',
      ],
      correctAnswer: 'лиц, осуществляющих техническое обслуживание и обеспечивающих функционирование государственных органов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Создание политических партий в государственных органах:',
      choices: [
        'не допускается',
        'допускается без каких-либо ограничений',
        'допускается при согласии руководства государственного органа',
        'допускается в предусмотренном законодательстве порядке',
      ],
      correctAnswer: 'не допускается'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные служащие обязаны:',
      choices: [
        'выполнять приказы и распоряжения руководителей, решения и указания вышестоящих органов и должностных лиц, изданные в пределах их должностных полномочий',
        'пользоваться правами и свободами, гарантируемыми гражданам Конституцией и законами',
        'участвовать в пределах своих должностных полномочий в рассмотрении вопросов и принятии по ним решений, требовать их исполнения соответствующими органами и должностными лицами',
        'посещать в установленном порядке для исполнения должностных полномочий организации',
      ],
      correctAnswer: 'выполнять приказы и распоряжения руководителей, решения и указания вышестоящих органов и должностных лиц, изданные в пределах их должностных полномочий'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственный служащий вправе:',
      choices: [
        'требовать от руководителя точного определения задач и объема должностных полномочий в соответствии с государственной должностью, занимаемой государственным служащим',
        'заниматься предпринимательской деятельностью, в том числе участвовать в управлении коммерческой организацией независимо от ее организационно-правовой формы',
        'использовать в неслужебных целях средства материально-технического, финансового и информационного обеспечения его служебной деятельности, другое государственное имущество и служебную информацию',
        'участвовать в действиях, препятствующих нормальному функционированию государственных органов и выполнению должностных обязанностей',
      ],
      correctAnswer: 'требовать от руководителя точного определения задач и объема должностных полномочий в соответствии с государственной должностью, занимаемой государственным служащим'
    },
    {
      type: EQuestionType.Radio,
      title: 'Непринятие ограничений, установленных законами Республики Казахстан, влечет:',
      choices: [
        'отказ в приеме на государственную службу либо увольнение',
        'административный штраф',
        'предупреждение',
        'выговор',
      ],
      correctAnswer: 'отказ в приеме на государственную службу либо увольнение'
    },
    {
      type: EQuestionType.Radio,
      title: 'Для выполнения неотложных, заранее непредвиденных работ государственные служащие могут привлекаться:',
      choices: [
        'к сверхурочной работе, к работе в выходные и праздничные дни в соответствии с трудовым законодательством Республики Казахстан',
        'к работе, в период временной нетрудоспособности лица',
        'к работе, во время отпуска по уходу за ребенком',
        'к работе, во время трудового отпуска',
      ],
      correctAnswer: 'к сверхурочной работе, к работе в выходные и праздничные дни в соответствии с трудовым законодательством Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'За одно и то же отличие к государственному служащему допускается применять:',
      choices: [
        'только одно поощрение',
        'поощрения разного вида',
        'не более двух поощрений, в зависимости от заслуги государственного служащего',
        'до трех поощрений, инициированных разными должностными лицами',
      ],
      correctAnswer: 'только одно поощрение'
    },
    {
      type: EQuestionType.Radio,
      title: 'Продвижение по государственной службе в государственном органе предусматривает:',
      choices: [
        'последовательное занятие вышестоящих государственных должностей, предусмотренных штатным расписанием государственного органа',
        'занятие государственными служащими государственных должностей в других государственных органах, загранучреждениях Республики Казахстан и иных организациях с сохранением прежнего места работы (государственной должности)',
        'занятие государственной должности, временно свободной в связи с прикомандированием, нахождением государственного служащего, занимающего данную государственную должность, в социальном отпуске либо на обучении на основе государственного заказа,',
        'занятие государственной должности, временно свободной в связи с отсутствием его на рабочем месте более двух месяцев подряд вследствие заболевания, если заболевание входит в перечень заболеваний, для которых установлен более длительный срок нетрудоспособности, утверждаемый уполномоченным государственным органом в области здравоохранения',
      ],
      correctAnswer: 'последовательное занятие вышестоящих государственных должностей, предусмотренных штатным расписанием государственного органа'
    },
    {
      type: EQuestionType.Radio,
      title: 'При сомнениях в правомерности полученного для исполнения распоряжения государственный служащий должен:',
      choices: [
        'незамедлительно сообщить об этом в письменной форме своему непосредственному руководителю и руководителю, отдавшему распоряжение',
        'незамедлительно сообщить об этом в устной форме своему непосредственному руководителю',
        'по усмотрению сотрудника сообщить об этом в письменной форме своему непосредственному руководителю',
        'по усмотрению сотрудника сообщить об этом в письменной форме своему непосредственному руководителю',
      ],
      correctAnswer: 'незамедлительно сообщить об этом в письменной форме своему непосредственному руководителю и руководителю, отдавшему распоряжение'
    },
    {
      type: EQuestionType.Radio,
      title: 'Действия и решения государственного органа или должностного лица не могут быть обжалованы привлекаемыми к ответственности государственными служащими:',
      choices: [
        'в организации по защите прав человека',
        'перед вышестоящим руководством',
        'в уполномоченный орган по делам государственной службы',
        'в суд',
      ],
      correctAnswer: 'в организации по защите прав человека'
    },
    {
      type: EQuestionType.Radio,
      title: 'Принцип меритократии предусматривает:',
      choices: [
        'признание личных заслуг и достижений государственного служащего, его продвижения по государственной службе в соответствии со способностями и профессиональной подготовкой',
        'обязательность исполнения решений, принятых вышестоящими государственными органами и должностными лицами в пределах их полномочий,',
        'личную ответственность за неисполнение либо ненадлежащее исполнение государственным служащим должностных обязанностей и превышение им своих должностных полномочий',
        'учета общественного мнения и гласности, за исключением деятельности, составляющей государственные секреты или иную охраняемую законом тайну',
      ],
      correctAnswer: 'признание личных заслуг и достижений государственного служащего, его продвижения по государственной службе в соответствии со способностями и профессиональной подготовкой'
    },
    {
      type: EQuestionType.Radio,
      title: 'К основным функциям политических государственных служащих не относится:',
      choices: [
        'решение вопросов согласованного функционирования всех ветвей государственной власти',
        'принятие решений, способствующих выработке, определению и реализации государственной политики',
        'взаимодействие с главами иностранных дипломатических представительств по согласованию с уполномоченным органом в сфере внешнеполитической деятельности',
        'определение целей государственного органа, развития соответствующей территории',
      ],
      correctAnswer: 'решение вопросов согласованного функционирования всех ветвей государственной власти'
    },
    {
      type: EQuestionType.Radio,
      title: 'Гражданам, впервые поступающим на государственную службу или вновь поступающим на государственную службу после ее прекращения, необходимо получение положительных результатов специальной проверки, проводимой:',
      choices: [
        'органами национальной безопасности',
        'уполномоченным органом по делам государственной службы',
        'органами внутренних дел',
        'органами прокуратуры',
      ],
      correctAnswer: 'органами национальной безопасности'
    },
    {
      type: EQuestionType.Radio,
      title: 'Срок проведения специальной проверки составляет:',
      choices: [
        'до трех месяцев',
        'до полугода',
        'до двух месяцев',
        'до четырех месяцев',
      ],
      correctAnswer: 'до трех месяцев'
    },
    {
      type: EQuestionType.Radio,
      title: 'Испытательный срок, устанавливаемый для государственных служащих, впервые, а также вновь поступивших на административную государственную службу корпуса «Б», после ее прекращения, составляет:',
      choices: [
        'три месяца',
        'по решению руководителя государственного органа',
        'два месяца',
        'не более четырех месяцев',
      ],
      correctAnswer: 'три месяца'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственный служащий не может занимать государственную должность, находящуюся в непосредственной подчиненности должности, занимаемой:',
      choices: [
        'его близкими родственниками',
        'сотрудниками, с которыми государственный служащий ранее состоял в трудовых отношениях',
        'сотрудниками, с которыми государственный служащий обучался в одном высшем образовательном учреждении',
        'его  как близкими, так и дальними родственниками',
      ],
      correctAnswer: 'его близкими родственниками'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не допускается занятие вакантных или временно вакантных административных государственных должностей без проведения конкурса для:',
      choices: [
        'административной государственной низовой должности корпуса «Б»',
        'помощников первых руководителей государственных органов',
        'пресс-секретарей',
        'советников первых руководителей государственных органов'
      ],
      correctAnswer: 'административной государственной низовой должности корпуса «Б»'
    },
    {
      type: EQuestionType.Radio,
      title: 'Обучение административных государственных служащих не включает:',
      choices: [
        'стажировку государственных служащих',
        'подготовку государственных служащих',
        'переподготовку государственных служащих',
        'повышение квалификации государственных служащих',
      ],
      correctAnswer: 'стажировку государственных служащих'
    }
  ],
  time: 10,
  passingScore: 5,
  questionsTotal: 10
};

