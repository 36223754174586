<app-preloader *ngIf="isLoading"></app-preloader>
<div *ngIf="isNews">
  <div class="news">
    <div class="main-section"
         [style.background-image]="'url(../../../assets/images/posts/' + getActiveItem(language$ | async).id + '.jpg)'">
      <div class="container">
        <div class="row align-items-center main-section__wrapper">
          <div class="col-xs-12 col-sm-12">
            <p class="main-section__title">{{getActiveItem(language$ | async).title}}</p>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-6"></div>
        </div>
      </div>
    </div>
  </div>
  <div class="news__wrapper section">
    <div class="container">
      <div class="row">
        <div class="news__text col" [innerHTML]="getActiveItem(language$ | async).text"></div>
        <a href="../../../assets/doc/example.doc"></a>
      </div>
    </div>
    <div class="news__recommended">
      <div class="container">
        <div class="row">
          <div class="col">
            <p class="news__sub-title">{{ 'INFO.RECOMMENDED_TITLE' | translate }}</p>
          </div>
        </div>
        <div class="row">
          <ng-container *ngFor="let item of news[language$ | async]['items']; index as i">
            <div class="news-item col-xs-12 col-sm-6 col-md-3 col-lg-3" *ngIf="item.id !== activeItem && i <= 4">
              <div class="news-item__inner" [routerLink]="['/news', item.id]">
                <div class="news-item__img" [style.background-image]="'url(' + (item.img ? item.img : '../../../assets/images/posts/' + item.id + '.jpg') + ')'"></div>
                <p class="news-item__title" [title]="item.title">{{item.title}}</p>
                <p class="news-item__description" [innerHTML]="item.text.substring(0, 75) + '...'"></p>
              </div>
            </div>
          </ng-container>
        </div>
      </div>
    </div>
  </div>
</div>
