<app-preloader *ngIf="isLoading"></app-preloader>
<div class="page privacy-policy">
  <div class="page__header privacy-policy__header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <p class="page__title privacy-policy__title" [innerHTML]="'PRIVACY_POLICY.TITLE' | translate"></p>
          <p class="page__sub-title privacy-policy__sub-title" [innerHTML]="'PRIVACY_POLICY.SUB_TITLE' | translate "></p>
        </div>
      </div>
    </div>
  </div>
  <div class="privacy-policy__body section">
    <div class="container">
      <div class="row">
        <div class="col" [innerHTML]="'PRIVACY_POLICY.TEXT' | translate"></div>
      </div>
    </div>
  </div>
</div>
