<app-preloader *ngIf="isLoading"></app-preloader>
<div class="page education">
  <div class="page__header education__header">
    <div class="container">
      <div class="row align-items-center">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <p class="page__title education__title" [innerHTML]="'EDUCATION.TITLE' | translate"></p>
          <p class="page__sub-title education__sub-title" [innerHTML]="'EDUCATION.SUB_TITLE' | translate "></p>
        </div>
      </div>
    </div>
  </div>

  <div class="section">
    <div class="container">
      <div class="row">
        <div class="col-8 justify-content-around">
          <p class="section__title education__section-title">
            <ng-container *ngIf="activeSection === 'video'">{{'EDUCATION.VIDEO_TITLE' | translate}}</ng-container>
            <ng-container *ngIf="activeSection === 'audio'">{{'EDUCATION.AUDIO_TITLE' | translate}}</ng-container>
          </p>
        </div>
        <div class="col text-right">
          <div class="btn-group">
            <button class="education__btn"
                    [class.active]="activeSection === 'video'"
                    (click)="onChangeSection('video')"
                    [disabled]="activeSection === 'video'">{{'EDUCATION.VIDEO' | translate}}</button>
            <button class="education__btn"
                    [class.active]="activeSection === 'audio'"
                    (click)="onChangeSection('audio')"
                    [disabled]="activeSection === 'audio'">{{'EDUCATION.AUDIO' | translate}}</button>
          </div>
        </div>
      </div>
      <div class="education__video" [hidden]="activeSection === 'audio'">
        <div class="education__grid" #videoGridRu [hidden]="activeLanguage === 'kz'"></div>
        <div class="education__grid" #videoGridKz [hidden]="activeLanguage === 'ru'"></div>
      </div>
      <div class="row education__audio" [hidden]="activeSection === 'video'">
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" *ngFor="let item of audio['kz']; let i = index" [hidden]="(language$ | async) !== 'kz'">
          <div class="audio-container">
            <p class="audio-container__title">{{item[0].title}}</p>
            <div [ngStyle]="{'cursor': !isUserLoggedIn && i > 0 ? 'pointer' : 'initial'}"
                 (click)="openModal(i,loginFormModal)"
            >
              <plyr style="display: block;"
                    [ngClass]="{'audio-container__disabled': !isUserLoggedIn && i > 0}"
                    [plyrPlaysInline]="true"
                    [plyrType]="'audio'"
                    [plyrSources]="item"
                    (plyrInit)="$event"
                    (plyrPlay)="played($event)">
              </plyr>
            </div>
          </div>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6" *ngFor="let item of audio['ru']; let i = index" [hidden]="(language$ | async) !== 'ru'">
          <div class="audio-container">
            <p class="audio-container__title">{{item[0].title}}</p>
            <div [ngStyle]="{'cursor': !isUserLoggedIn && i > 0 ? 'pointer' : 'initial'}"
                 (click)="openModal(i, loginFormModal)"
            >
              <plyr style="display: block"
                    [ngClass]="{'audio-container__disabled': !isUserLoggedIn && i > 0}"
                    [plyrPlaysInline]="true"
                    [plyrType]="'audio'"
                    [plyrSources]="item"
                    (plyrPlay)="played($event)">
              </plyr>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <app-loggin-modal
    bsModal #loginFormModal="bs-modal"
    class="modal fade" tabindex="-1"
    role="dialog" aria-labelledby="dialog-child-name"
  >
  </app-loggin-modal>
</div>
