import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PaymentService {
  paymentLinks = {
    3000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=3000&pg_currency=KZT&pg_description=Product+pack&pg_salt=vq56nbp3bJ7RtO5W&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=3000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=fc985d6d87a185ebe973878c67ef10d6',
    4000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=4000&pg_currency=KZT&pg_description=Product+pack&pg_salt=jAyEHtgcLys4ogiR&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=4000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=e9b00fe2f728d61a11095ae8af8bb3d0',
    5000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=5000&pg_currency=KZT&pg_description=Product+pack&pg_salt=86G3LUZpkiBpqJeC&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=5000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=e01df76577dd4799aa293848b324c98f',
    6000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=6000&pg_currency=KZT&pg_description=Product+pack&pg_salt=cs8vrQYGMMrX3kUx&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=6000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=4a1dc8900ddbd453582fe98ae839799c',
    7000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=7000&pg_currency=KZT&pg_description=Product+pack&pg_salt=U9124B1Xbkh9zmcE&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=7000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=f0ffdbc5bd8a8bebed9b0d0bea4e3a45',
    8000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=8000&pg_currency=KZT&pg_description=Product+pack&pg_salt=9W3VXgecDMBHnzeO&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=8000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=5921a59409cf55e09b5bc49d6a9307a4',
    9000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=9000&pg_currency=KZT&pg_description=Product+pack&pg_salt=EPMonbIlHsHtU2Gt&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=9000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=7d056d4996d294871a39677e1e530d9e',
    10000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=10000&pg_currency=KZT&pg_description=Product+pack&pg_salt=FkAO1OIg17DEmBVj&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=10000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=c2c359c1e85a8cedde7bf914650059d6',
    12000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=12000&pg_currency=KZT&pg_description=Product+pack&pg_salt=VLGIUb6E1lo0fyG5&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=12000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=a53ee9dc5408625ac587314f99ec3999',
    14000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=14000&pg_currency=KZT&pg_description=Product+pack&pg_salt=oNYDBWXq4AnJx1OL&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=14000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=86517a8c21951451f1d40fb39cd747cf',
    15000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=15000&pg_currency=KZT&pg_description=Product+pack&pg_salt=p811rTcbwGyBaj7p&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=15000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=68999f1267c68cbf075a113b103b59bf',
    16000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=16000&pg_currency=KZT&pg_description=Product+pack&pg_salt=S2ZO3ngJcXIW3i6p&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=16000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=419656ba3f7dd9bc04701e3dded766b9',
    18000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=18000&pg_currency=KZT&pg_description=Product+pack&pg_salt=nKN0vEK8KGqL6x2T&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=18000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=abadf97066e95bc59e76fef208bbbf13',
    20000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=20000&pg_currency=KZT&pg_description=Product+pack&pg_salt=sC0vbFAQIKYNMX1l&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=20000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=0334eae916940b4779a06a9d782751d5',
    25000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=25000&pg_currency=KZT&pg_description=Product+pack&pg_salt=NbWJ2F4g3jdyekcr&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=25000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=5ff6822df68c35374c8d8558f0391d28',
    30000: 'https://api.freedompay.money/payment.php?pg_merchant_id=553803&pg_amount=30000&pg_currency=KZT&pg_description=Product+pack&pg_salt=5S4cyYWL7IzQtLBh&pg_language=ru&pg_receipt_positions%5B0%5D%5Bcount%5D=1&pg_receipt_positions%5B0%5D%5Bname%5D=Product+pack&pg_receipt_positions%5B0%5D%5Bprice%5D=30000&pg_receipt_positions%5B0%5D%5Btax_type%5D=0&pg_sig=4a699d790980022ab372efe718414749'
  };
  constructor() { }

  getPaymentLink(price: number): string {
    return this.paymentLinks[price];
  }
}
