import {EQuestionType, ITest} from '../../../shared/models/test.model';

export const legalAct: ITest = {
  id: 'legalAct',
  title: 'Правовые акты',
  description: '<p>Тест на знание закона «O правовых актах»</p>',
  questions: [
    {
      type: EQuestionType.Radio,
      title: 'Нормативный правовой акт, определяющий статус и полномочия какого-либо государственного органа:',
      choices: [
        'положение',
        'правила',
        'регламент',
        'инструкция',
      ],
      correctAnswer: 'положение'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не относятся к общим требованиям, предъявляемым к правовым актам:',
      choices: [
        'содержание нормы декларативного характера',
        'адресованность к неопределенному кругу лиц или к индивидуально определенным лицам',
        'направленность на регулирование общественных отношений',
        'направленность на возникновение, изменение, дополнение или прекращение прав и обязанностей физических и (или) юридических лиц',
      ],
      correctAnswer: 'содержание нормы декларативного характера'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не относятся к общим требованиям, предъявляемым к правовым актам:',
      choices: [
        'обязательное согласование с Президентом',
        'содержание нормативных или индивидуальных властных правовых предписаний',
        'принятие на республиканском референдуме либо в порядке, установленном законодательными актами',
        'адресованность к неопределенному кругу лиц или к индивидуально определенным лицам',
      ],
      correctAnswer: 'обязательное согласование с Президентом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Виды правовых актов:',
      choices: [
        'нормативные и ненормативные',
        'основные и вспомогательные',
        'нормативные и производные',
        'основныеи ненормативные',
      ],
      correctAnswer: 'нормативные и ненормативные'
    },
    {
      type: EQuestionType.Radio,
      title: 'Разъяснения по вопросам судебной практики содержатся:',
      choices: [
        'в нормативных постановлениях Верховного Суда',
        'в нормативных постановлениях Конституционного Совета',
        'в заключениях Вышего Судебного Совета',
        'в нормативных постановлениях Правительства',
      ],
      correctAnswer: 'в нормативных постановлениях Верховного Суда'
    },
    {
      type: EQuestionType.Radio,
      title: 'Порядок заключения, выполнения, изменения, дополнения и прекращения международных договоров Республики Казахстан определяется:',
      choices: [
        'специальным законом',
        'законом',
        'консолидированным законом',
        'конституционным законом',
      ],
      correctAnswer: 'специальным законом'
    },
    {
      type: EQuestionType.Radio,
      title: 'К основным видам нормативных правовых актов не относятся:',
      choices: [
        'регламент государственной услуги, стандарт государственной услуги, технический регламент',
        'нормативные правовые постановления Центральной избирательной комиссии, Национального Банка',
        'Конституция, конституционные законы, кодексы',
        'указы Президента, нормативные правовые приказы руководителей центральных государственных органов',
      ],
      correctAnswer: 'регламент государственной услуги, стандарт государственной услуги, технический регламент'
    },
    {
      type: EQuestionType.Radio,
      title: 'К производным видам нормативных правовых актов относятся:',
      choices: [
        'положение, правила, инструкция',
        'нормативные правовые приказы министров и иных руководителей центральных государственных органов',
        'нормативные правовые приказы руководителей ведомств центральных государтвенных органов',
        'нормативные правовые решения маслихатов, нормативные правовые постановления акиматов',
      ],
      correctAnswer: 'положение, правила, инструкция'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нормативные правовые акты не вправе издавать:',
      choices: [
        'местные исполнительные органы, уполномоченные акимом',
        'маслихаты',
        'акиматы',
        'центральные государтвенные органы',
      ],
      correctAnswer: 'местные исполнительные органы, уполномоченные акимом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нормативные правовые акты вправе издавать:',
      choices: [
        'местный представительный орган',
        'территориальные подразделения уполномоченных органов',
        'местные исполнительные органы, уполномоченные акимом',
        'местные исполнительные органы, финансируемые из местных бюджетов',
      ],
      correctAnswer: 'местный представительный орган'
    },
    {
      type: EQuestionType.Radio,
      title: 'Принимаются с целью совершенствования структуры законодательства и объединяют законы, регулирующие комплексные по своему характеру общественные отношения в сфере (области):',
      choices: [
        'консолидированные законы',
        'кодексы',
        'кодифицированные законы',
        'инкорпорационые законы',
      ],
      correctAnswer: 'консолидированные законы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Обладает высшей юридической силой:',
      choices: [
        'Конституция',
        'закон, вносящий изменения и дополнения в Конституцию',
        'конституционные законы',
        'указы Президента',
      ],
      correctAnswer: 'Конституция'
    },
    {
      type: EQuestionType.Radio,
      title: 'Вне иерархии нормативных правовых актов находятся: ',
      choices: [
        'Нормативные постановления Конституционного Совета и Верховного Суда',
        'нормативные правовые постановления Правительства и Нормативные постановления Конституционного Совета',
        'нормативные правовые указы Президента и Нормативные постановления Верховного Суда',
        'нормативные постановления Парламента и его Палат, Нормативные постановления Конституционного Совета и Верховного Суда',
      ],
      correctAnswer: 'Нормативные постановления Конституционного Совета и Верховного Суда'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нормы законов в случаях их расхождения с нормами кодексов:',
      choices: [
        'могут применяться только после внесения в кодексы соответствующих изменений и (или) дополнений.',
        'могут применяться только после внесения в Конституцию',
        'могут применяться только после внесения в законы соответствующих изменений и (или) дополнений',
        'могут применяться только после внесения в законы соответствующих дополнений',
      ],
      correctAnswer: 'могут применяться только после внесения в кодексы соответствующих изменений и (или) дополнений.'
    },
    {
      type: EQuestionType.Radio,
      title: 'При отсутствии норм права, регулирующих конкретные общественные отношения, применяется:',
      choices: [
        'аналогия закона или аналогия права',
        'аналогия закона',
        'аналогия права',
        'применение аналогии права в Казахстане запрещено',
      ],
      correctAnswer: 'аналогия закона или аналогия права'
    },
    {
      type: EQuestionType.Radio,
      title: 'В планах подготовки нормативных правых актов не указываются :',
      choices: [
        'предложения государственных и иных органов и организаций',
        'наименование нормативного правового акта, отражающее его уровень, форму и предмет регулирования',
        'сроки подготовки',
        'органы и организации, ответственные за разработку проекта',
      ],
      correctAnswer: 'предложения государственных и иных органов и организаций'
    },
    {
      type: EQuestionType.Radio,
      title: 'В разработке нормативных правовых актов, затрагивающих интересы субъектов частного предпринимательства, обязательно участие:',
      choices: [
        'представителей Национальной палаты предпринимателей и аккредитованных объединений субъектов частного предпринимательства',
        'представителей Национального банка',
        'депутатов Парламента',
        'членов Общественного совета, создаваемого в государственном органе',
      ],
      correctAnswer: 'представителей Национальной палаты предпринимателей и аккредитованных объединений субъектов частного предпринимательства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Требование по размещению проектов нормативных правовых актов для публичного обсуждения на интернет-портале открытых нормативных правовых актов не распространяется на:',
      choices: [
        'нормативные постановления Конституционного Совета',
        'нормативные правовые указы Президента',
        'нормативные постановления Сената Парламента',
        'законы, вносящие изменения и дополнения в Конституцию',
      ],
      correctAnswer: 'нормативные постановления Конституционного Совета'
    },
    {
      type: EQuestionType.Radio,
      title: 'Правомочны принимать участие в работе рабочей группы по подготовке проекта закона на любой стадии:',
      choices: [
        'депутаты Парламента',
        'представители Национальной палаты предпринимателей',
        'работники юридического подразделения органа-разработчика',
        'представители аккредитованных объединений субъектов частного предпринимательства',
      ],
      correctAnswer: 'депутаты Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'В целях вовлечения некоммерческих организаций, граждан в процесс разработки проектов нормативных правовых актов, касающихся прав, свобод и обязанностей граждан, образуются:',
      choices: [
        'общественные советы',
        'советы по этике',
        'национальные научные советы',
        'координационные советы',
      ],
      correctAnswer: 'общественные советы'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае опубликования значительного по объему нормативного правового в нескольких номерах периодического печатного издания, днем официального опубликования считается день опубликования:',
      choices: [
        'заключительной части текста нормативного правового акта на казахском и русском языках',
        'вводной части текста нормативного правового акта на казахском и русском языках',
        'основной части текста нормативного правового акта на казахском и русском языках',
        'постановочной части нормативного правового акта на казахском и русском языках',
      ],
      correctAnswer: 'заключительной части текста нормативного правового акта на казахском и русском языках'
    },
    {
      type: EQuestionType.Radio,
      title: 'Если нормативные правовые акты или их структурные элементы противоречат включенным в новый нормативный правовой акт нормам права или поглощаются ими:',
      choices: [
        'они подлежат признанию утратившими силу',
        'они подлежать переутверждению',
        'они подлежат приведению в соответствие с принятым нормативным правовым актом',
        'они подлежат внесению соответствующих поправок',
      ],
      correctAnswer: 'они подлежат признанию утратившими силу'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не может быть признан утратившим силу нормативный правовой акт:',
      choices: [
        'не вступивший в силу',
        'зарегистрированный в органах юстиции',
        'введенный в действие',
        'официально опубликованный',
      ],
      correctAnswer: 'не вступивший в силу'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не втупившие в силу нормативные правовые акты при необходимости могут быть:',
      choices: [
        'отменены',
        'признаны утратившими силу',
        'отозваны',
        'официально опубликованы',
      ],
      correctAnswer: 'отменены'
    },
    {
      type: EQuestionType.Radio,
      title: 'Обязательное условие применения нормативных правовых актов, касающихся прав, свобод и обязанностей граждан: ',
      choices: [
        'официальное опубликование',
        'государственная регисрация',
        'обсуждение нормативного правового акта на портале «открытых НПА»',
        'опубликование на интернет-ресурсе государственного органа'
      ],
      correctAnswer: 'официальное опубликование'
    },
    {
      type: EQuestionType.Radio,
      title: 'Первое официальное опубликование нормативных правовых актов должно быть осуществлено одновременно на казахском и русском языках в течение:',
      choices: [
        'тридцати календарных дней после дня их вступления в силу',
        'тридцати рабочих дней после дня их вступления в силу',
        'десяти календарных дней после дня их вступления в силу',
        'пятнадцати календарных дней после дня их подписания уполномоченным лицом',
      ],
      correctAnswer: 'тридцати календарных дней после дня их вступления в силу'
    },
    {
      type: EQuestionType.Radio,
      title: 'Контроль за соблюдением установленного порядка последующего официального опубликования текстов нормативных правовых актов осуществляют:',
      choices: [
        'органы юстиции',
        'Правительство',
        'Администрация Президента',
        'органы прокуратуры',
      ],
      correctAnswer: 'органы юстиции'
    },
    {
      type: EQuestionType.Radio,
      title: 'Постановления Конституционного Совета вводятся в действие:',
      choices: [
        'со дня их принятия',
        'по истечении десяти календарных дней после дня их первого официального опубликования, если в самих актах или актах о введении их в действие не указаны иные сроки',
        'со дня их принятия или в сроки, указанные в самом акте',
        'со дня первого официального опубликования, если в самих актах не указаны иные сроки',
      ],
      correctAnswer: 'со дня их принятия'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нормативные правовые акты, содержащие государственные секреты или иную охраняемую законом тайну, вводятся в действие:',
      choices: [
        'со дня их принятия или в сроки, указанные в самом акте',
        'со дня их принятия',
        'по истечении десяти календарных дней после дня их первого официального опубликования, если в самих актах не указаны иные сроки',
        'со дня первого официального опубликования, если в самих актах не указаны иные сроки',
      ],
      correctAnswer: 'со дня их принятия или в сроки, указанные в самом акте'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нормативный правовой акт (его часть или части) прекращает свое действие в случаях:',
      choices: [
        'признания принятого акта неконституционным в порядке, установленном Конституцией',
        'выявления в нормативном правовом акте неэффективно реализуемых норм права',
        'упразднения органа, принявшего нормативный правовой акт',
        'выявления в нормативном правовом акте устаревших норм права',
      ],
      correctAnswer: 'признания принятого акта неконституционным в порядке, установленном Конституцией'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае принятия нового нормативного правового акта, которому противоречат положения ранее изданного нормативного правового акта, действие ранее изданного нормативного правового акта:',
      choices: [
        'прекращается',
        'временно приостанавливается',
        'отменяется',
        'действие ранее изданного нормативного правового акта сохраняется',
      ],
      correctAnswer: 'прекращается'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нормативные правовые акты, принятые местными представительными и исполнительными органами, ревизионными комиссиями, действуют: ',
      choices: [
        'на территории соответствующей административно-территориальной единицы',
        'на территории соответствующей области',
        'на всей территории Республики Казахстан',
        'на всей территории Республики Казахстан, если в самих нормативных правовых актах или актах об их введении в действие не установлено иное',
      ],
      correctAnswer: 'на территории соответствующей административно-территориальной единицы'
    },
    {
      type: EQuestionType.Radio,
      title: 'На дипломатических представителей и некоторых других сотрудников государственных органов иностранных государств и международных организаций нормативные правовые акты Республики Казахстан распространяются:',
      choices: [
        'в пределах, предусмотренных международными договорами и общепризнанными принципами и нормами международного права (дипломатический иммунитет)',
        'в пределах, предусмотренных Конституцией',
        'нормативные правовые акты Республики Казахстан в отношении вышеуказанных лиц не  распространяются в силу наличия у них дипломатического иммунитета',
        'с исключениями, предусмотренными действующим законодательством'
      ],
      correctAnswer: 'в пределах, предусмотренных международными договорами и общепризнанными принципами и нормами международного права (дипломатический иммунитет)'
    },
    {
      type: EQuestionType.Radio,
      title: 'К органам и должностным лицам государства, обеспечивающим законность нормативных правовых актов, не относятся:',
      choices: [
        'Национальная палата предпринимателей',
        'Министерство юстиции и его территориальные органы',
        'Генеральный Прокурор и подчиненные ему прокуроры',
        'суды',
      ],
      correctAnswer: 'Национальная палата предпринимателей'
    },
    {
      type: EQuestionType.Radio,
      title: 'Общественный мониторинг нормативных правовых актов, затрагивающих интересы субъектов частного предпринимательства, проводит:',
      choices: [
        'Национальная палата предпринимателей Республики Казахстан',
        'Министерство юстиции',
        'аккредитованные объединения субъектов частного предпринимательства',
        'Общественный совет',
      ],
      correctAnswer: 'Национальная палата предпринимателей Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственный учет нормативных правовых актов включает в себя:',
      choices: [
        'ведение Государственного реестра  и  Эталонного контрольного банка нормативных правовых актов',
        'ведение номенклатуры нормативных правовых актов',
        'ведение Эталонного контрольного банка нормативных правовых актов',
        'ведение Государственного реестра нормативных правовых актов',
      ],
      correctAnswer: 'ведение Государственного реестра  и  Эталонного контрольного банка нормативных правовых актов'
    },
    {
      type: EQuestionType.Radio,
      title: 'К нормативным правовым актам относятся:',
      choices: [
        'правила',
        'правовые акты индивидуального применения',
        'акты официального разъяснения нормативных правовых актов',
        'правовые акты в области системы государственного планирования',
      ],
      correctAnswer: 'правила'
    },
    /*{
      type: EQuestionType.Radio,
      title: '',
      choices: [],
      correctAnswer: ''
    },*/
    {
      type: EQuestionType.Radio,
      title: 'Правовой акт индивидуального применения является письменным официальным документом и:',
      choices: [
        'реализует установленные законодательством права и обязанности индивидуально определенных лиц',
        'не содержит установленной формы',
        'содержит нормы права, распространяющиеся только на индивидуально определенный круг лиц',
        'входит в законодательство Республики Казахстан',
      ],
      correctAnswer: 'реализует установленные законодательством права и обязанности индивидуально определенных лиц'
    },
    {
      type: EQuestionType.Radio,
      title: 'Правовой акт индивидуального применения вступает в силу: ',
      choices: [
        'с момента  принятия, если в нем не определен более поздний срок',
        'с момента регистрации в органах юстиции',
        'по истечении десяти календарных дней после дня его первого официального опубликования',
        'в срок, установленный в самом правовом акте',
      ],
      correctAnswer: 'с момента  принятия, если в нем не определен более поздний срок'
    },
    {
      type: EQuestionType.Radio,
      title: 'При противоречии правовых актов индивидуального применения, принятых уполномоченными органами различных уровней: ',
      choices: [
        'применяется правовой акт вышестоящего уполномоченного органа, если принятие такого акта входило в его компетенцию',
        'применяется ранее принятый правовой акт',
        'действие нормативных правовых актов приостанавливается',
        'действие нормативных правовых актов прекращается',
      ],
      correctAnswer: 'применяется правовой акт вышестоящего уполномоченного органа, если принятие такого акта входило в его компетенцию'
    },
    {
      type: EQuestionType.Radio,
      title: 'Вправе приостановить, изменить  и (или) дополнить либо отменить правовой акт индивидуального применения до прекращения его действия:',
      choices: [
        'уполномоченный орган, принявший данный правовой акт, вышестоящий к нему государственный орган либо суд',
        'уполномоченный орган, принявший данный правовой акт, вышестоящий к нему государственный орган',
        'только уполномоченный орган, принявший правовой акт',
        'только вышестоящий государственный орган',
      ],
      correctAnswer: 'уполномоченный орган, принявший данный правовой акт, вышестоящий к нему государственный орган либо суд'
    },
    {
      type: EQuestionType.Radio,
      title: 'Официальное разъяснение нормативных правовых актов Правительства осуществляет:',
      choices: [
        'Министерство юстиции совместно с заинтересованными государственными органами  по поручению Премьер-Министра',
        'органом- разработчик нормативного правового акта',
        'Премьер-Министр совместно с заинтересованными государственными органами',
        'Конситуционный Совет',
      ],
      correctAnswer: 'Министерство юстиции совместно с заинтересованными государственными органами  по поручению Премьер-Министра'
    },
    {
      type: EQuestionType.Radio,
      title: 'Официальное разъяснение нормативных правовых постановлений Национального Банка обеспечивает: ',
      choices: [
        'Национальный банк',
        'Министерство финансов',
        'Министерство национальной экономики',
        'Конституционный Совет',
      ],
      correctAnswer: 'Национальный банк'
    },
    {
      type: EQuestionType.Radio,
      title: 'Создает единую систему правовой информации, оказывает помощь центральным исполнительным и иным центральным государственным органам в справочно-информационной работе:',
      choices: [
        'Министерство юстиции',
        'уполномоченный орган в сфере информатизации',
        'Государственная корпорация «Правительство для граждан»',
        'прокуратура'
      ],
      correctAnswer: 'Министерство юстиции'
    },
    {
      type: EQuestionType.Radio,
      title: 'К нормативным правовым актам не относятся:',
      choices: [
        'акты официального разъяснения нормативных правовых актов',
        'инструкция',
        'правила',
        'постановление Правительства',
      ],
      correctAnswer: 'акты официального разъяснения нормативных правовых актов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Разъяснения государственными органами нормативных правовых актов в отношении конкретных субъектов или применительно к конкретной ситуации:',
      choices: [
        'не имеют обязательной юридической силы и носят рекомендательный характер',
        'имеют обязательную юридическую силу',
        'имеют обязательную юридическую силу только в отношении конкретных субъектов',
        'являются актами официциального толкования',
      ],
      correctAnswer: 'не имеют обязательной юридической силы и носят рекомендательный характер'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные органы в случае выявления в ходе правового мониторинга противоречащих законодательству, устаревших, коррупциогенных и неэффективно реализуемых норм права:',
      choices: [
        'принимают меры по внесению в них изменений и (или) дополнений или признанию их утратившими силу',
        'принимают меры по отмене нормативного правового акта',
        'приостанавливают действие нормативного правового акта',
        'излагают нормативный правовой акт в новой редакции',
      ],
      correctAnswer: 'принимают меры по внесению в них изменений и (или) дополнений или признанию их утратившими силу'
    },
    {
      type: EQuestionType.Radio,
      title: 'Подача заинтересованными лицами заявления об отмене, изменении, дополнении или приостановлении действия правового акта индивидуального применения в вышестоящий государственный орган или суд: ',
      choices: [
        'приостанавливает действие правового акта',
        'не приостанавливает действие правового акта',
        'отменяет действие правового акта',
        'прекращает действие правового акта',
      ],
      correctAnswer: 'приостанавливает действие правового акта'
    },
    {
      type: EQuestionType.Radio,
      title: 'Согласно иерархии нормативних правовых актов большей юридической силой по отношению к постановлениям Парламента обладают:',
      choices: [
        'кодексы',
        'нормативные правовые указы Президента',
        'нормативные  правовые постановления Правительства',
        'приказы руководителей центральных государственных органов',
      ],
      correctAnswer: 'кодексы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Согласно иерархии нормативних правовых актов меньшей юридической силой по отношению к постановлениям Правительства обладают:',
      choices: [
        'приказы руководителей центральных государственных органов',
        'нормативные правовые указы Президента',
        'нормативные  правовые постановления Парламента',
        'кодексы',
      ],
      correctAnswer: 'приказы руководителей центральных государственных органов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Планы подготовки проектов нормативных правовых актов подразделяются на:',
      choices: [
        'текущие, перспективные',
        'краткосрочные, долгосрочные',
        'оперативные,  стратегические',
        'оперативные, перспективные',
      ],
      correctAnswer: 'текущие, перспективные'
    },
    {
      type: EQuestionType.Radio,
      title: 'Согласно иерархии нормативних правовых актов большей юридической силой по отношению к консолидированным законам обладают:',
      choices: [
        'кодексы',
        'нормативные правовые указы Президента',
        'нормативные  правовые постановления Парламента',
        'приказы руководителей центральных государственных органов',
      ],
      correctAnswer: 'кодексы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Текущие планы подготовки проектов нормативных правовых актов составляются:',
      choices: [
        'на один год',
        'на полугодие',
        'на два года',
        'на текущий квартал',
      ],
      correctAnswer: 'на один год'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные органы направляют проект нормативного правового акта, касающегося прав, свобод и обязанностей граждан:',
      choices: [
        'в общественный совет для получения рекомендаций',
        'в неправительственные организации для получения заключения',
        'в аккредитованные объединения субъектов частного предпринимательства для получения экспертного заключения',
        'на независимую экспертизу',
      ],
      correctAnswer: 'в общественный совет для получения рекомендаций'
    },
    {
      type: EQuestionType.Radio,
      title: 'Новая редакция нормативного правового акта принимается:',
      choices: [
        'при внесении изменений и (или) дополнений в текст в объеме, превышающем половину текста нормативного правового акта',
        'при внесении изменений и (или) дополнений в текст в объеме, превышающем две трети текста нормативного правового акта',
        'по усмотрению государственного органа-разработчика',
        'в случае реорганизации государственного органа-разработчика',
      ],
      correctAnswer: 'при внесении изменений и (или) дополнений в текст в объеме, превышающем половину текста нормативного правового акта'
    },
    {
      type: EQuestionType.Radio,
      title: 'Экспертные заключения аккредитованных объединений субъектов частного предпринимательства, Национальной палаты предпринимателей:',
      choices: [
        'носят рекомендательный характер',
        'носят обязательный характер',
        'носят разъяснительный характер',
        'являются неотъемлемой частью нормативного правового акта',
      ],
      correctAnswer: 'носят рекомендательный характер'
    }
  ],
  time: 10,
  passingScore: 5,
  questionsTotal: 10
};
