import {EQuestionType, ITest} from '../../../shared/models/test.model';

export const antiCorruption: ITest = {
  id: 'antiCorruption',
  title: 'Госслужба',
  description: '<p>Тест на знание закона «O противодействии коррупции»</p>',
  questions: [
    {
      type: EQuestionType.Radio,
      title: 'Лицо, постоянно, временно или по специальному полномочию осуществляющее функции представителя власти либо выполняющее организационно-распорядительные или административно-хозяйственные функции в государственных органах, субъектах квазигосударственного сектора, органах местного самоуправления, а также в Вооруженных Силах, других войсках и воинских формированиях: ',
      choices: [
        'должностное лицо',
        'лицо, занимающее ответственную государственную должность',
        'лицо, уполномоченное на выполнение государственных функций',
        'лицо, приравненное к лицам, уполномоченным на выполнение государственных функций',
      ],
      correctAnswer: 'должностное лицо'
    },
    {
      type: EQuestionType.Radio,
      title: 'Служащие Национального Банка и его ведомств: ',
      choices: [
        'лица, приравненные к лицам, уполномоченным на выполнение государственных функций',
        'лица, занимающие ответственную государственную должность',
        'должностные лица',
        'лица, уполномоченные на выполнение государственных функций',
      ],
      correctAnswer: 'лица, приравненные к лицам, уполномоченным на выполнение государственных функций'
    },
    {
      type: EQuestionType.Radio,
      title: 'Коррупционное правонарушение- это:',
      choices: [
        'имеющее признаки коррупции противоправное виновное деяние (действие или бездействие), за которое законом установлена административная или уголовная ответственность',
        'имеющее признаки коррупции противоправное виновное действие, за которое законом установлена уголовная ответственность',
        'имеющее признаки коррупции противоправное виновное бездействие, за которое законом установлена административная ответственность',
        'имеющее признаки коррупции противоправное виновное бездействие, за которое законом установлена дисциплинарная ответственность'
      ],
      correctAnswer: 'имеющее признаки коррупции противоправное виновное деяние (действие или бездействие), за которое законом установлена административная или уголовная ответственность'
    },
    {
      type: EQuestionType.Radio,
      title: 'Возможность возникновения причин и условий, способствующих совершению коррупционных правонарушений:',
      choices: [
        'коррупционный риск',
        'коррупционное правонарушение',
        'противодействие коррупции',
        'предупреждение коррупции',
      ],
      correctAnswer: 'коррупционный риск'
    },
    {
      type: EQuestionType.Radio,
      title: 'Основные принципы противодействия коррупции:',
      choices: [
        'законность, гласность, прозрачность, взаимодействие государства и гражданского общества',
        'равенство всех перед законом и судом, обеспечение государством социальной и правовой защиты государственных служащих',
        'принцип меритократии, профессионализм государственных служащих, казахстанский патриотизма',
        'этичность, нетерпимость к правонарушениям, равная оплата труда за выполнение равнозначной работы',
      ],
      correctAnswer: 'законность, гласность, прозрачность, взаимодействие государства и гражданского общества'
    },
    {
      type: EQuestionType.Radio,
      title: 'Противоречие между личными интересами и должностными полномочиями, при котором личные интересы могут привести к ненадлежащему исполнению должностных полномочий:',
      choices: [
        'конфликт интересов',
        'предупреждение коррупции',
        'коррупционное правонарушение',
        'коррупционный риск',
      ],
      correctAnswer: 'конфликт интересов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не входит в систему мер противодействия коррупции:',
      choices: [
        'антикоррупционная политика',
        'анализ коррупционных рисков',
        'финансовый контроль',
        'сообщение о коррупционных правонарушениях',
      ],
      correctAnswer: 'антикоррупционная политика'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не входит в систему мер противодействия коррупции: ',
      choices: [
        'коррупционное правонарушение',
        'антикоррупционный мониторинг',
        'выявление коррупциогенных норм при производстве юридической экспертизы',
        'предотвращение и разрешение конфликта интересов',
      ],
      correctAnswer: 'коррупционное правонарушение'
    },
    {
      type: EQuestionType.Radio,
      title: 'Антикоррупционный мониторинг:',
      choices: [
        'деятельность субъектов противодействия коррупции по сбору, обработке, обобщению, анализу и оценке информации, касающейся эффективности антикоррупционной политики, состояния правоприменительной практики в сфере противодействия коррупции, а также восприятия и оценки уровня коррупции обществом',
        'выявление и изучение причин и условий, способствующих совершению коррупционных правонарушений',
        'установленная для обособленной сферы общественных отношений система рекомендаций, направленная на предупреждение коррупции',
        'документ, содержащий анализ и оценку состояния и тенденции распространения коррупции на международном и национальном уровнях, предложения по формированию, реализации и совершенствованию антикоррупционной политики',
      ],
      correctAnswer: 'деятельность субъектов противодействия коррупции по сбору, обработке, обобщению, анализу и оценке информации, касающейся эффективности антикоррупционной политики, состояния правоприменительной практики в сфере противодействия коррупции, а также восприятия и оценки уровня коррупции обществом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Цель антикоррупционного мониторинга:',
      choices: [
        'оценка правоприменительной практики в сфере противодействия коррупции',
        'антикоррупционное образование',
        'выявление коррупциогенных норм при производстве юридической экспертизы',
        'выявление коррупционных рисков в нормативных правовых актах, затрагивающих деятельность государственных органов и организаций, субъектов квазигосударственного сектора',
      ],
      correctAnswer: 'оценка правоприменительной практики в сфере противодействия коррупции'
    },
    {
      type: EQuestionType.Radio,
      title: 'Анализ коррупционных рисков предусматривает:',
      choices: [
        'выявление и изучение причин и условий, способствующих совершению коррупционных правонарушений',
        'оценку правоприменительной практики в сфере противодействия коррупции',
        'выявление коррупциогенных норм при производстве юридической экспертизы',
        'систему рекомендаций, направленная на предупреждение коррупции',
      ],
      correctAnswer: 'выявление и изучение причин и условий, способствующих совершению коррупционных правонарушений'
    },
    {
      type: EQuestionType.Radio,
      title: 'Внешний анализ коррупционных рисков осуществляется:',
      choices: [
        'уполномоченным органом по противодействию коррупции',
        'государственными органами, организациями и субъектами квазигосударственного сектора',
        'Правительством',
        'правоохранительными органами',
      ],
      correctAnswer: 'уполномоченным органом по противодействию коррупции'
    },
    {
      type: EQuestionType.Radio,
      title: 'Внутренний анализ коррупционных рисков осуществляется:',
      choices: [
        'государственными органами, организациями и субъектами квазигосударственного сектора',
        'только государственными органами и организациями',
        'только субъектами квазигосударственного сектора',
        'Правительством',
      ],
      correctAnswer: 'государственными органами, организациями и субъектами квазигосударственного сектора'
    },
    {
      type: EQuestionType.Radio,
      title: 'Формирование антикоррупционной культуры:',
      choices: [
        'деятельность, осуществляемая субъектами противодействия коррупции в пределах своей компетенции по сохранению и укреплению в обществе системы ценностей, отражающей нетерпимость к коррупции',
        'непрерывный процесс воспитания и обучения, осуществляемый в целях нравственного, интеллектуального, культурного развития и формирования активной гражданской позиции неприятия коррупции личностью',
        'разъяснительная работа в средствах массовой информации, организация социально значимых мероприятий',
        'правовые, административные и организационные меры, направленные на снижение коррупционных рисков, повышение доверия общества к деятельности государственных органов',
      ],
      correctAnswer: 'деятельность, осуществляемая субъектами противодействия коррупции в пределах своей компетенции по сохранению и укреплению в обществе системы ценностей, отражающей нетерпимость к коррупции'
    },
    {
      type: EQuestionType.Radio,
      title: 'Под антикоррупционным образованием понимается:',
      choices: [
        'непрерывный процесс воспитания и обучения, осуществляемый в целях нравственного, интеллектуального, культурного развития и формирования активной гражданской позиции неприятия коррупции личностью',
        'деятельность субъектов противодействия коррупции по изучению, выявлению, ограничению и устранению причин и условий, способствующих совершению коррупционных правонарушений',
        'деятельность субъектов противодействия коррупции по сбору, обработке, обобщению, анализу и оценке информации, касающейся эффективности антикоррупционной политики',
        'процесс формирования и соблюдения антикоррупционных стандартов',
      ],
      correctAnswer: 'непрерывный процесс воспитания и обучения, осуществляемый в целях нравственного, интеллектуального, культурного развития и формирования активной гражданской позиции неприятия коррупции личностью'
    },
    {
      type: EQuestionType.Radio,
      title: 'Формирование антикоррупционной культуры осуществляется посредством:',
      choices: [
        'комплекса мер образовательного, информационного и организационного характера',
        'проведения досудебного расследования, в том числе предварительного следствия, дознания и протокольной формы коррупционных преступлений',
        'реализации государственной политики в сфере безопасности граждан',
        'принятия мер по предотвращению и разрешению конфликта интересов',
      ],
      correctAnswer: 'комплекса мер образовательного, информационного и организационного характера'
    },
    {
      type: EQuestionType.Radio,
      title: 'Антикоррупционные стандарты:',
      choices: [
        'установленная для обособленной сферы общественных отношений система рекомендаций, направленная на предупреждение коррупции',
        'система выявления и изучения причин и условий, способствующих совершению коррупционных правонарушений',
        'система рекомендаций по принятию мер по предотвращению и разрешению конфликта интересов',
        'требования по устранению причин и условий, способствующих совершению коррупционных правонарушений',
      ],
      correctAnswer: 'установленная для обособленной сферы общественных отношений система рекомендаций, направленная на предупреждение коррупции'
    },
    {
      type: EQuestionType.Radio,
      title: 'В целях недопущения совершения действий, которые могут привести к использованию своих полномочий в личных, групповых и иных неслужебных интересах, лица, занимающие ответственную государственную должность:',
      choices: [
        'принимают на себя антикоррупционные ограничения',
        'принимают ограничения по выезду за пределы страны',
        'принимают ограничения по выступлению в средствах массовой информации',
        'соблюдают принципы добросовестной конкуренции',
      ],
      correctAnswer: 'принимают на себя антикоррупционные ограничения'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не относится к антикоррупционным ограничениям:',
      choices: [
        'осуществление деятельности в пределах должностных полномочий',
        'принятие подарков в связи с исполнением служебных полномочий',
        'использование служебной и иной информации, не подлежащей официальному распространению, в целях получения или извлечения имущественных и неимущественных благ и преимуществ',
        'осуществление деятельности, не совместимой с выполнением государственных функций',
      ],
      correctAnswer: 'осуществление деятельности в пределах должностных полномочий'
    },
    {
      type: EQuestionType.Radio,
      title: 'Согласие на принятие антикоррупционных ограничений лиц, уполномоченных на выполнение государственных функций:',
      choices: [
        'фиксируется службами управления персоналом (кадровыми службами) соответствующих организаций в письменной форме',
        'фиксируется службами внутреннего контроля (внутренней безопасности) соответствующих организаций в письменной форме',
        'фиксируется юридическими службами соответствующих организаций в письменной форме',
        'фиксируется службами управления персоналом (кадровыми службами) соответствующих организаций в трудовом договоре'
      ],
      correctAnswer: 'фиксируется службами управления персоналом (кадровыми службами) соответствующих организаций в письменной форме'
    },
    {
      type: EQuestionType.Radio,
      title: 'Отказ в принятии антикоррупционных ограничений влечет:',
      choices: [
        'отказ в приеме на должность либо увольнение с должности (освобождение от должности)',
        'предупреждение о неполном служебном соответствии',
        'объявление выговора',
        'понижение в должности'
      ],
      correctAnswer: 'отказ в приеме на должность либо увольнение с должности (освобождение от должности)'
    },
    {
      type: EQuestionType.Radio,
      title: 'Несоблюдение антикоррупционных ограничений в случаях отсутствия признаков уголовно наказуемого деяния и административного правонарушения является основанием для:',
      choices: [
        'прекращения государственной службы или иной соответствующей деятельности',
        'перевода государственного служащего на иную должность',
        'наложения дисциплинарного взыскания на государственного служащего',
        'понижения государственного служащего в должности',
      ],
      correctAnswer: 'прекращения государственной службы или иной соответствующей деятельности'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лица, занимающие ответственную государственную должность вправе: ',
      choices: [
        'сдавать в имущественный наем (аренду) жилище, принадлежащее им на праве собственности, и получать доход от такой сдачи',
        'предпринимательской деятельностью',
        'иной оплачиваемой должностью',
        'самостоятельно участвовать в управлении хозяйствующим субъектом, если управление или участие в управлении хозяйствующим субъектом не входит в их должностные обязанности',
      ],
      correctAnswer: 'сдавать в имущественный наем (аренду) жилище, принадлежащее им на праве собственности, и получать доход от такой сдачи'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не вправе приобретать паи инвестиционных фондов, облигации, акции коммерческих организаций:',
      choices: [
        'Председатель Национального банка и его заместителям',
        'лица, занимающие ответственную государственную должность',
        'акимы городов районного значения, поселков, сел, сельских округов',
        'лица, исполняющие управленческие функции в субъекте квазигосударственного сектора',
      ],
      correctAnswer: 'Председатель Национального банка и его заместителям'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лица, занимающие ответственную государственную должность, обязаны передать в доверительное управление принадлежащее им имущество: ',
      choices: [
        'в течение 30 календарных дней со дня вступления в должность',
        'в течение десяти рабочих дней со дня вступления в должность',
        'в течение 15 календарных дней со дня вступления в должность',
        'в течение 25 календарных дней со дня вступления в должность',
      ],
      correctAnswer: 'в течение 30 календарных дней со дня вступления в должность'
    },
    {
      type: EQuestionType.Radio,
      title: 'Договор на доверительное управление имуществом:',
      choices: [
        'подлежит нотариальному удостоверению',
        'составляется в простой форме, не подлежит нотариальному удостоверению',
        'подлежит нотариальному удостоверению только при передаче в доверительное управление паев инвестиционных фондов, облигаций и акций коммерческих организаций',
        'подлежит нотариальному удостоверению только при сдаче в имущественный наем (аренду)жилища',
      ],
      correctAnswer: 'подлежит нотариальному удостоверению'
    },
    {
      type: EQuestionType.Radio,
      title: 'Неисполнение обязательств по нотариальному удостоверению договора на доверительное управление имуществом:',
      choices: [
        'является основанием для прекращения ими государственной службы или иной соответствующей деятельности',
        'влечет дисциплинарное взыскание в виде выговора',
        'уголовной ответственностью',
        'основанием для понижения в должности',
      ],
      correctAnswer: 'является основанием для прекращения ими государственной службы или иной соответствующей деятельности'
    },
    {
      type: EQuestionType.Radio,
      title: 'В отношении лиц, нарушающих требования по недопущению совместной службы (работы) близких родственников, супругов или свойственников, если они добровольно не устранят нарушение, принимаются меры по:',
      choices: [
        'переводу на должности, исключающему такую подчиненность, а при невозможности такого перевода один из этих служащих подлежит увольнению с должности или иному освобождению от указанных функций',
        'обязательному освобождению лица от должности',
        'привлечению лица к дисциплинарной ответственности',
        'привлечению лица к административной ответственности',
      ],
      correctAnswer: 'переводу на должности, исключающему такую подчиненность, а при невозможности такого перевода один из этих служащих подлежит увольнению с должности или иному освобождению от указанных функций'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лица, занимающие ответственную государственную должность, лица, уполномоченные на выполнение государственных функций, лица, приравненные к лицам, уполномоченным на выполнение государственных функций, должностные лица при возниконовении конфликта интересов обязаны:',
      choices: [
        'в письменной форме уведомить непосредственного руководителя либо руководство организации',
        'изменить должностные обязанности',
        'принять меры по увольнению',
        'в любой форме уведомить непосредственного руководителя либо руководство организации',
      ],
      correctAnswer: 'в письменной форме уведомить непосредственного руководителя либо руководство организации'
    },
    {
      type: EQuestionType.Radio,
      title: 'Непосредственный руководитель либо руководство организации в целях предотвращения и урегулирования конфликта интересов, принимают меры по:',
      choices: [
        'отстранению лица от исполнения должностных обязанностей и поручению другому лицу исполнение должностных обязанностей по вопросу, в связи с которым возник или может возникнуть конфликт интересов',
        'наложению на государственного служащего дисциплинарного взыскания',
        'предупреждению лица о неполном служебном соответствии или понижению в государственной должности',
        'привлечению лица к административной ответственности',
      ],
      correctAnswer: 'отстранению лица от исполнения должностных обязанностей и поручению другому лицу исполнение должностных обязанностей по вопросу, в связи с которым возник или может возникнуть конфликт интересов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Национальный доклад о противодействии коррупции:',
      choices: [
        'документ, содержащий анализ и оценку состояния и тенденции распространения коррупции на международном и национальном уровнях, предложения по формированию, реализации и совершенствованию антикоррупционной политики',
        'документ, содержащий оценку правоприменительной практики в сфере государственной службы и противодействия коррупции',
        'документ, устанавливающий систему рекомендаций, направленную на предупреждение коррупции',
        'нормативный правовой акт, разрабатываемый уполномоченным органом по противодействию коррупции и утверждаемый Президентом',
      ],
      correctAnswer: 'документ, содержащий анализ и оценку состояния и тенденции распространения коррупции на международном и национальном уровнях, предложения по формированию, реализации и совершенствованию антикоррупционной политики'
    },
    {
      type: EQuestionType.Radio,
      title: 'Национальный доклад о противодействии коррупции формируется: ',
      choices: [
        'ежегодно',
        'дважды в год',
        'один раз в три года',
        'один раз в два года',
      ],
      correctAnswer: 'ежегодно'
    },
    {
      type: EQuestionType.Radio,
      title: 'Информация о лице, оказывающем содействие в противодействии коррупции, является:',
      choices: [
        'государственным секретом',
        'государственной тайной',
        'информацией для служебного пользования',
        'общедоступной информацией',
      ],
      correctAnswer: 'государственным секретом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лицо, сообщившее о факте коррупционного правонарушения или иным образом оказывающее содействие в противодействии коррупции:',
      choices: [
        'находится под защитой государства',
        'проверяется на факт причастности к коррупционному правонарушению',
        'обеспечивается личной охраной до выяснения обстоятельств своершенного коррупционного правонарушения',
        'находится под защитой правоохранительных органов',
      ],
      correctAnswer: 'находится под защитой государства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лицо, располагающее информацией о коррупционном правонарушении, уведомляет об этом: ',
      choices: [
        'руководство государственного органа либо организации, сотрудником которой является, либо уполномоченный орган по противодействию коррупции',
        'сотрудника структурного подразделения, в котором он работает',
        'вышестоящий государственный орган',
        'любой правоохранительный орган',
      ],
      correctAnswer: 'руководство государственного органа либо организации, сотрудником которой является, либо уполномоченный орган по противодействию коррупции'
    },
    {
      type: EQuestionType.Radio,
      title: 'Меры противодействия коррупции, принимаемые физическими лицами, общественными объединениями и иными юридическими лицами:',
      choices: [
        'сообщение об известных им фактах совершения коррупционных правонарушений',
        'изучение и распространение положительного опыта противодействия коррупции',
        'запрос у государственных органов, организаций и должностных лиц информации и материалов, способствующих раскрытию коррупционных правонарушений',
        'проведение анализа практики оперативно-розыскной и следственной деятельности, досудебного расследования по коррупционным преступлениям',
      ],
      correctAnswer: 'сообщение об известных им фактах совершения коррупционных правонарушений'
    },
    {
      type: EQuestionType.Radio,
      title: 'Выявление, пресечение, раскрытие, расследование и предупреждение коррупционных правонарушений и привлечение лиц, виновных в их совершении, к ответственности в пределах компетенции осуществляется:',
      choices: [
        'органами прокуратуры, национальной безопасности, внутренних дел, государственных доходов, военной полиции, Пограничной службой Комитета национальной безопасности',
        'государственными органами, организациями, субъектами квазигосударственного сектора и должностными лицами',
        'органами прокуратуры, национальной безопасности, внутренних дел',
        'органами прокуратуры, Пограничной службой Комитета национальной безопасности'
      ],
      correctAnswer: 'органами прокуратуры, национальной безопасности, внутренних дел, государственных доходов, военной полиции, Пограничной службой Комитета национальной безопасности'
    },
    {
      type: EQuestionType.Radio,
      title: 'К субъектам противодействия коррупции не относятся:',
      choices: [
        'международные неправительственные организации',
        'субъекты квазигосударственного сектора',
        'государственные органы',
        'общественные объединения, физические и юридические лица',
      ],
      correctAnswer: 'международные неправительственные организации'
    },
    {
      type: EQuestionType.Radio,
      title: 'Функции уполномоченного органа по противодействию коррупции осуществляет:',
      choices: [
        'разработка предложений по совершенствованию нормативной правовой базы в сфере противодействия коррупции, а также принятие нормативных правовых актов в пределах своей компетенции',
        'внесение предложений по формированию государственной политики в сферах профилактики правонарушений, охраны общественного порядка, борьбы с преступностью, исполнения уголовных наказаний и административных взысканий',
        'обеспечение оперативного управления силами и средствами при совершении тяжких преступлений, массовых беспорядках, стихийных бедствиях и других чрезвычайных происшествиях',
        'создание и функционирование Республиканской информационной системы в области охраны общественного порядка и борьбы с преступностью',
      ],
      correctAnswer: 'разработка предложений по совершенствованию нормативной правовой базы в сфере противодействия коррупции, а также принятие нормативных правовых актов в пределах своей компетенции'
    },
    {
      type: EQuestionType.Radio,
      title: 'Антикоррупционная служба уполномоченного органа по противодействию коррупции в пределах своих полномочий вправе:',
      choices: [
        'проводить анализ практики оперативно-розыскной и следственной деятельности, досудебного расследования по коррупционным преступлениям',
        'обеспечивать реализацию государственной политики в сфере оборота наркотических средств, психотропных веществ и прекурсоров, противодействие их незаконному обороту и злоупотреблению ими',
        'организовать специальные и военные перевозки',
        'обеспечивать создание и функционирование Республиканской информационной системы в области охраны общественного порядка и борьбы с преступностью',
      ],
      correctAnswer: 'проводить анализ практики оперативно-розыскной и следственной деятельности, досудебного расследования по коррупционным преступлениям'
    },
    {
      type: EQuestionType.Radio,
      title: 'Взыскание незаконно полученного имущества или оплаты государству его стоимости или стоимости незаконно полученных услуг в результате коррупционных правонарушений в случаях отказа их добровольной сдачи производится на основании:',
      choices: [
        'вступившего в законную силу решения суда по иску прокурора, органов государственных доходов либо других государственных органов и должностных лиц, уполномоченных на это законом',
        'иска прокурора, органов государственных доходов либо других государственных органов и должностных лиц, уполномоченных на это законом',
        'решения суда по иску прокурора, или иного субъекта противодействия коррупции',
        'решения суда, вступившего в законную силу, по иску физических лиц',
      ],
      correctAnswer: 'вступившего в законную силу решения суда по иску прокурора, органов государственных доходов либо других государственных органов и должностных лиц, уполномоченных на это законом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Сделки, договоры, совершенные в результате коррупционных правонарушений, признаются недействительными:',
      choices: [
        'судом',
        'актом прокурорского надзора',
        'антикоррупционной службой',
        'уполномоченным органом по противодействию коррупции',
      ],
      correctAnswer: 'судом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лица, исполняющие управленческие функции в субъектах квазигосударственного сектора:',
      choices: [
        'лица, постоянно, временно либо по специальному полномочию исполняющие организационно-распорядительные или административно-хозяйственные функции в указанных организациях',
        'лица, постоянно исполняющие организационно-распорядительные функции',
        'государственные служащие',
        'лица, занимающие политические государственные должности либо административные государственный должности корпуса «А»'
      ],
      correctAnswer: 'лица, постоянно, временно либо по специальному полномочию исполняющие организационно-распорядительные или административно-хозяйственные функции в указанных организациях'
    },
    {
      type: EQuestionType.Radio,
      title: 'Антикоррупционная политика – это:',
      choices: [
        'правовые, административные и организационные меры, направленные на снижение коррупционных рисков, повышение доверия общества к деятельности государственных органов, и иные меры',
        'деятельность субъектов противодействия коррупции по предупреждению коррупции, в том числе по формированию антикоррупционной культуры в обществе, выявлению и устранению причин и условий, способствующих совершению коррупционных правонарушений',
        'деятельность субъектов противодействия коррупции по изучению, выявлению, ограничению и устранению причин и условий, способствующих совершению коррупционных правонарушений, путем разработки и внедрения системы превентивных мер',
        'деятельность субъектов противодействия коррупции по сбору, обработке, обобщению, анализу и оценке информации, касающейся эффективности антикоррупционной политики,  а также восприятия и оценки уровня коррупции обществом'
      ],
      correctAnswer: 'правовые, административные и организационные меры, направленные на снижение коррупционных рисков, повышение доверия общества к деятельности государственных органов, и иные меры'
    },
    {
      type: EQuestionType.Radio,
      title: 'К мерам, принимаемым непосредственным руководителем либо руководством организации по урегулированию конфликта интересов не относится:',
      choices: [
        'незамедлительное увольнение лица с занимаемой должности',
        'отстранение лиц, в отношении которых возник конфликт интересов, от исполнения должностных обязанностей и поручить другому лицу исполнение должностных обязанностей по вопросу, в связи с которым возник или может возникнуть конфликт интересов',
        'изменение должностных обязанностей',
        'принятие иных мер по устранению конфликта интересов',
      ],
      correctAnswer: 'незамедлительное увольнение лица с занимаемой должности'
    },
    {
      type: EQuestionType.Radio,
      title: 'За коррупционное правонарушение лицо привлекается к:',
      choices: [
        'административной и уголовной ответственности',
        'дисциплинарной и административной ответственности',
        'гражданско-правовой и уголовной ответственности',
        'дисциплинарной и гражданско-правовой ответственности',
      ],
      correctAnswer: 'административной и уголовной ответственности'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лица, уволенные с государственной службы по отрицательным мотивам, представляют в орган государственных доходов по месту жительства декларацию о доходах и имуществе в течение:',
      choices: [
        'трех лет после увольнения в порядке, установленном налоговым законодательством',
        'шести месяцев после увольнения в порядке, установленном налоговым законодательством',
        'одного года после увольнения в порядке, установленном налоговым законодательством',
        'двух лет после увольнения в порядке, установленном налоговым законодательством',
      ],
      correctAnswer: 'трех лет после увольнения в порядке, установленном налоговым законодательством'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лицам, уполномоченным на выполнение государственных функций, и лицам, приравненным к ним, запрещается:',
      choices: [
        'заключать гражданско-правовых сделок не под своим именем',
        'сдавать в имущественный наем (аренду) жилище, принадлежащее им на праве собственности, и получать доход от такой сдачи',
        'заниматься оплачиваемой педагогической деятельностью',
        'самостоятельно участвовать в управлении хозяйствующим субъектом, если управление или участие в управлении хозяйствующим субъектом входит в их должностные обязанности',
      ],
      correctAnswer: 'заключать гражданско-правовых сделок не под своим именем'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лица, сообщившие заведомо ложную информацию о факте коррупционного правонарушения:',
      choices: [
        'подлежат ответственности в соответствии с законом',
        'находятся под защитой государства',
        'привлекаются к дисциплинарной ответственности по усмотрению руководителя антикоррупционной службы',
        'подлежат увольнению с занимаемой должности',
      ],
      correctAnswer: 'подлежат ответственности в соответствии с законом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Разглашение поступающих в органы государственных доходов сведений, отражаемых в декларациях государственных служащих, если в содеянном не содержится признаков уголовно наказуемого деяния, влечет:',
      choices: [
        'увольнение виновного лица',
        'выговор виновному лицу',
        'понижение в должности виновного лица',
        'предупреждение о неполном служебном соответствии виновного лица',
      ],
      correctAnswer: 'увольнение виновного лица'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лица, являющиеся кандидатами на государственную должность либо должность, связанную с выполнением государственных или приравненных к ним функций, представляют в орган государственных доходов по месту жительства:',
      choices: [
        'декларацию о доходах и имуществе',
        'декларацию об активах и обязательствах',
        'справку об отсутствии задолженностей по налоговым обязательствам',
        'декларацию об активах и трастах',
      ],
      correctAnswer: 'декларацию о доходах и имуществе'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лица, занимающие государственную должность, в период выполнения своих полномочий представляют в орган государственных доходов декларацию о доходах и имуществе:',
      choices: [
        'ежегодно',
        'по итогам полугодий',
        'ежемесячно',
        'ежеквартально',
      ],
      correctAnswer: 'ежегодно'
    },
    {
      type: EQuestionType.Radio,
      title: 'Обязательство сдачи декларации о доходах и имуществе возложено на:',
      choices: [
        'членов семьи лица, являющегося кандидатом на службу в специальный государственный орган',
        'родителей кандидатов, поступающих на государственную службу',
        'всех членов семьи лица, являющегося кандидатом на службу в государственный орган, непосредственно подчиненный и подотчетный Президенту',
        'всех членов семьи кандидатов, поступающих на государственную службу',
      ],
      correctAnswer: 'членов семьи лица, являющегося кандидатом на службу в специальный государственный орган'
    },
    {
      type: EQuestionType.Radio,
      title: 'Сведения о размерах и об источниках доходов должностных лиц, занимающих ответственные государственные должности:',
      choices: [
        'могут быть опубликованы в порядке, установленном законодательством',
        'составляют служебную тайну',
        'являются государственным секретом',
        'не подлежат разглашению',
      ],
      correctAnswer: 'могут быть опубликованы в порядке, установленном законодательством'
    },
    {
      type: EQuestionType.Radio,
      title: 'Результаты антикоррупционного мониторинга могут являться основанием для: ',
      choices: [
        'проведения анализа коррупционных рисков',
        'расследования по факту совершения коррупционного правонарушения',
        'освобождения лица от должности',
        'признания нормативного правового акта противоречащим Конституции',
      ],
      correctAnswer: 'проведения анализа коррупционных рисков'
    },
    {
      type: EQuestionType.Radio,
      title: 'К антикоррупционным ограничениям, принимаемым лицами, занимающими ответственную государственную должность, лицами, уполномоченными на выполнение государственных функций, не относится:',
      choices: [
        'получение денежной доплаты к должностному окладу',
        'осуществление деятельности, не совместимой с выполнением государственных функций',
        'недопустимости совместной службы (работы) близких родственников, супругов и свойственников',
        'использование служебной и иной информации, не подлежащей официальному распространению, в целях получения или извлечения имущественных и неимущественных благ и преимуществ',
      ],
      correctAnswer: 'получение денежной доплаты к должностному окладу'
    },
    {
      type: EQuestionType.Radio,
      title: 'Согласно Закону «О противодействии коррупции» к близким родственникам не относятся:',
      choices: [
        'супруга (супруги)',
        'родители (родитель), усыновители (удочерители)',
        'дети, усыновленные (удочеренные)',
        'полнородные и неполнородные братья и сестры',
      ],
      correctAnswer: 'супруга (супруги)'
    },
    {
      type: EQuestionType.Radio,
      title: 'Согласно Закону «О противодействии коррупции» к свойственникам не относятся:',
      choices: [
        'бывшие коллеги сотрудника',
        'братья, сестры',
        'родители и дети',
        'супруга (супруги)',
      ],
      correctAnswer: 'бывшие коллеги сотрудника'
    },
    {
      type: EQuestionType.Radio,
      title: 'Принятие актов и совершение действий в результате коррупционных правонарушений являются основаниями:',
      choices: [
        'для их отмены (признания недействительными)',
        'их переутверждения',
        'привлечения лица к уголовной ответственности',
        'начала расследования в отношении лиц, принявших акт'
      ],
      correctAnswer: 'для их отмены (признания недействительными)'
    },
    {
      type: EQuestionType.Radio,
      title: 'Лицо, исполняющее управленческие функции в государственной организации или субъекте квазигосударственного сектора, относится к категории лиц:',
      choices: [
        'приравненных к лицам, уполномоченным на выполнение государственных функций',
        'должностных лиц',
        'уполномоченных на выполнение государственных функций',
        'занимающих ответственную государственную должность',
      ],
      correctAnswer: 'приравненных к лицам, уполномоченным на выполнение государственных функций'
    },
    {
      type: EQuestionType.Radio,
      title: 'Меры финансового контроля, предусмотренные Законом «О противодействии коррупции» не распространяются на правоотношения, связанные:',
      choices: [
        'с приобретением в собственность жилищ и строительных материалов для строительства жилищ в Республике Казахстан',
        'с участием лиц в качестве акционера или учредителя (участника) юридических лиц',
        'с участием в трастах',
        'с обязательствами (в том числе и устными) по содержанию или временному хранению материальных и финансовых средств, принадлежащих лицу или супругу (супруге)'
      ],
      correctAnswer: 'с приобретением в собственность жилищ и строительных материалов для строительства жилищ в Республике Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Уголовная ответственность и наказание за коррупционные преступления предусматривается:',
      choices: [
        'Уголовным кодексом',
        'Законом «О противодействии коррупции»',
        'Уголовно-процессуальным кодексом',
        'Кодексом об административных правонарушениях'
      ],
      correctAnswer: 'Уголовным кодексом'
    },
    {
      type: EQuestionType.Radio,
      title: 'К источникам антикоррупционного мониторинга не относятся:',
      choices: [
        'сведения, составляющие государственную тайну',
        'правовая статистика и обращения физических и юридических лиц',
        'сведения неправительственных и международных организаций',
        'данные социологических опросов и публикаций в средствах массовой информации, а также иные не запрещенные законом источники информации'
      ],
      correctAnswer: 'сведения, составляющие государственную тайну'
    },
    {
      type: EQuestionType.Radio,
      title: 'Подлежит внешнему анализу коррупционных рисков сфера:',
      choices: [
        'образования',
        'правосудия',
        'оперативно-розыскной деятельности',
        'уголовно-исполнительной деятельности',
      ],
      correctAnswer: 'образования'
    },
    {
      type: EQuestionType.Radio,
      title: 'Сведения о доходах кандидатов на выборные государственные должности при их выдвижении:',
      choices: [
        'могут быть опубликованы в порядке, установленном законодательством',
        'составляют служебную тайну',
        'являются государственным секретом',
        'не подлежат разглашению',
      ],
      correctAnswer: 'могут быть опубликованы в порядке, установленном законодательством'
    },
    {
      type: EQuestionType.Radio,
      title: 'К лицам, уполномоченным на выполнение государственных функций, не относятся:',
      choices: [
        'лица, осуществляющие техническое обслуживание и обеспечивающие функционирование государственных органов',
        'государственные служащие',
        'депутаты маслихата',
        'лица, временно исполняющие обязанности, предусмотренные государственной должностью, до назначения его на государственную службу',
      ],
      correctAnswer: 'лица, осуществляющие техническое обслуживание и обеспечивающие функционирование государственных органов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не относится к системе мер противодействия коррупции:',
      choices: [
        'развитие международного сотрудничества по противодействию коррупции',
        'антикоррупционный мониторинг',
        'анализ коррупционных рисков',
        'формирование антикоррупционной культуры',
      ],
      correctAnswer: 'развитие международного сотрудничества по противодействию коррупции'
    },
    {
      type: EQuestionType.Radio,
      title: 'Противодействие коррупции в пределах своей компетенции обязаны вести:',
      choices: [
        'все государственные органы, организации, субъекты квазигосударственного сектора и должностные лица',
        'только центральные исполнительные органы',
        'физические и юридические лица',
        'только правоохранительные органы',
      ],
      correctAnswer: 'все государственные органы, организации, субъекты квазигосударственного сектора и должностные лица'
    },
    {
      type: EQuestionType.Radio,
      title: 'За пределами Республики Казахстан Закон «О противодействии коррупции»:',
      choices: [
        'действует в отношении граждан Республики Казахстан и юридических лиц, зарегистрированных в Республике Казахстан, если иное не предусмотрено международным договором, ратифицированным Республикой Казахстан',
        'не действует',
        'действует в отношении иностранных граждан',
        'действует в отношении иностранных граждан и юридических лиц',
      ],
      correctAnswer: 'действует в отношении граждан Республики Казахстан и юридических лиц, зарегистрированных в Республике Казахстан, если иное не предусмотрено международным договором, ратифицированным Республикой Казахстан'
    }
  ],
  time: 10,
  passingScore: 5,
  questionsTotal: 10
};
