export * from './admin-procedures';
export * from './anti-corruption';
export * from './citizens-appeal';
export * from './civil-service';
export * from './constitution';
export * from './ethical-code';
export * from './government';
export * from './legalAct';
export * from './mgius';
export * from './president';
export * from './public-services';
export * from './self-service';
