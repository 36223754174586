import {IExam} from '../../../shared/models/test.model';

export const corpAFirst: IExam = {
  id: 'corpAFirst',
  title: 'Корпус А',
  topics: [
    {
      id: 'constitution',
      title: 'Конституция РК',
      questions: [],
      questionsCount: 1,
      passingScore: 1
    },
    {
      id: 'president',
      title: 'Закон «O Президенте РК»',
      questions: [],
      questionsCount: 1,
      passingScore: 1
    },
    {
      id: 'government',
      title: 'Закон «O Правительстве РК»',
      questions: [],
      questionsCount: 1,
      passingScore: 1
    },
    {
      id: 'civilService',
      title: 'Закон «O государственной службе РК»',
      questions: [],
      questionsCount: 1,
      passingScore: 1
    },
    {
      id: 'antiCorruption',
      title: 'Закон «O противодействии коррупции»',
      questions: [],
      questionsCount: 1,
      passingScore: 1
    },
    {
      id: 'legalAct',
      title: 'Закон «O правовых актах»',
      questions: [],
      questionsCount: 1,
      passingScore: 1
    },
    {
      id: 'selfService',
      title: 'Закон «O местном государственном управлении и самоуправлении в РК»',
      questions: [],
      questionsCount: 1,
      passingScore: 1,
    },
    {
      id: '',
      title: 'Закон «O Парламенте РК и статусе его депутатов»',
      questions: [],
      questionsCount: 1,
      passingScore: 1
    },
    {
      id: 'adminProcedures',
      title: 'Административный процедурно-процессуальный кодекс РК',
      questions: [],
      questionsCount: 1,
      passingScore: 1
    },
    {
      id: 'ethicalCode',
      title: 'Этический кодекс государственных служащих РК (правила служебной этики госслужащих)',
      questions: [],
      questionsCount: 1,
      passingScore: 1
    }
  ],
  time: 90,
  questionsTotal: 100,
  passingScore: 70
};

