import {EQuestionType, ITest} from '../../../shared/models/test.model';

export const president: ITest = {
  id: 'president',
  title: 'Президент',
  description: '<p>Тест на знание закона «O Президенте РК»</p>',
  questions: [
    {
      type: EQuestionType.Radio,
      title: 'Право выступать от имени народа и государства принадлежит:',
      choices: [
        'Президенту',
        'Премьер-Министру',
        'Председателю Мажилиса Парламента',
        'Председателю Сената Парламента'
      ],
      correctAnswer: 'Президенту'
    },
    {
      type: EQuestionType.Radio,
      title: 'Материалы личного архива и личной библиотеки Президента по завершении его служебной деятельности находятся в собственности:',
      choices: [
        'Экс-Президента',
        'Архива Президента',
        'действующего Президента',
        'государственного музея Республики Казахстан'
      ],
      correctAnswer: 'Экс-Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Срок полномочий Президента ',
      choices: [
        '5 лет',
        '4 года',
        '9 лет',
        '7 лет'
      ],
      correctAnswer: '5 лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент Республики Казахстан вправе: ',
      choices: [
        'заниматься творческой деятельностью',
        'быть депутатом представительного органа',
        'занимать иные оплачиваемые должности',
        'осуществлять предпринимательскую деятельность'
      ],
      correctAnswer: 'заниматься творческой деятельностью'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент назначает на должность Председателя Комитета национальной безопасности с согласия: ',
      choices: [
        'Сената Парламента',
        'Министра Обороны',
        'Премьер-Министра',
        'Мажилиса Парламента'
      ],
      correctAnswer: 'Сената Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Число депутатов Сената Парламента, назначаемых Президентом:',
      choices: [
        '15',
        '17',
        '16',
        '14'
      ],
      correctAnswer: '15'
    },
    {
      type: EQuestionType.Radio,
      title: 'Выдвигает кандидатуру на должность Председателя Сената Парламента:',
      choices: [
        'Президент',
        'Премьер-Министр',
        'Председатель Мажилиса',
        'Председатель Конституционного Совета'
      ],
      correctAnswer: 'Президент'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент Республики назначает на должность Премьер-Министра',
      choices: [
       'С согласия Мажилиса Парламента',
       'С согласия обеих палат Парламента',
       'С согласия Сената Парламента',
       'Самостоятельно'
      ],
      correctAnswer: 'С согласия Мажилиса Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент Республики председательствует на заседаниях Правительства:',
      choices: [
        'по особо важным вопросам',
        'в случае отсутствия Премьер-Министра',
        'по вопросам международного сотрудничества',
        'при рассмотрении постановлений Правительства'
      ],
      correctAnswer: 'по особо важным вопросам'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент Республики назначает на должность Генерального Прокурора сроком на: ',
      choices: [
        'пять лет',
        'шесть лет',
        'четыре года',
        'семь лет'
      ],
      correctAnswer: 'пять лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент назначает на должность Генерального Прокурора: ',
      choices: [
        'с согласия Сената Парламента',
        'с согласия обеих палат Парламента',
        'с согласия Мажилиса Парламента',
        'самостоятельно'
      ],
      correctAnswer: 'с согласия Сената Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент назначает на должность Председателя и двух членов Счетного комитета по контролю за исполнением республиканского бюджета сроком на:',
      choices: [
        'пять лет',
        'четыре года',
        'шесть лет',
        'три года'
      ],
      correctAnswer: 'пять лет'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент назначает на должности акимов областей, городов республиканского значения и столицы: ',
      choices: [
        'с согласия маслихата соответствующей административно-территориальной единицы',
        'самостоятельно',
        'с согласия Премьер-Министра',
        'с согласия членов Правительства'
      ],
      correctAnswer: 'с согласия маслихата соответствующей административно-территориальной единицы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент может быть досрочно освобожден от должности в случае:',
      choices: [
        'устойчивой неспособности осуществлять свои обязанности по болезни',
        'совершения деяний с целью подрыва или ослабления внешней безопасности и суверенитета Казахстана',
        'оказания иностранному государству помощи в осуществлении враждебной деятельности против Казахстана',
        'перехода на сторону врага во время войны или вооруженного конфликта'
      ],
      correctAnswer: 'устойчивой неспособности осуществлять свои обязанности по болезни'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае досрочного освобождения или отрешения от должности Президента, а также его смерти, полномочия Президента на оставшийся срок переходят к: ',
      choices: [
        'Председателю Сената Парламента',
        'Депутатом Сената Парламента',
        'Премьер-Министру Республики',
        'Председателю Мажилиса Парламента'
      ],
      correctAnswer: 'Председателю Сената Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент назначает руководящих должностных лиц консультативно-совещательных органов, Ассамблеи народа Казахстана, Высшего Судебного Совета:',
      choices: [
        'самостоятельно',
        'с согласия Мажилиса Парламента',
        'с согласия Сената Парламента',
        'с согласия Премьер-Министра'
      ],
      correctAnswer: 'самостоятельно'
    },
    {
      type: EQuestionType.Radio,
      title: 'Экс-Президенты по праву пожизненно являются:',
      choices: [
        'членами Конституционного Совета',
        'депутатами Сената Парламента',
        'депутатами Мажилиса Парламента',
        'сотрудниками Администрации Президента'
      ],
      correctAnswer: 'членами Конституционного Совета'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае объявления частичной или общей мобилизации Президент незамедлительно информирует об этом: ',
      choices: [
        'Парламент',
        'Премьер-Министра республики',
        'Правительство республики',
        'Конституционный Совет республики'
      ],
      correctAnswer: 'Парламент'
    },
    {
      type: EQuestionType.Radio,
      title: 'Одно и то же лицо не может быть избрано Президентом более:',
      choices: [
        'двух раз подряд',
        'одного раза',
        'трех раз подряд',
        'четырех раз подряд'
      ],
      correctAnswer: 'двух раз подряд'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае агрессии против Казахстана либо непосредственной внешней угрозы его безопасности: Президент ',
      choices: [
        'вводит на всей территории республики военное положение',
        'вводит на всей территории республики чрезвычайное положение',
        'вносит Парламенту предложение о введении чрезвычайного положения',
        'вносит Парламенту предложение о введении военного положения'
      ],
      correctAnswer: 'вводит на всей территории республики военное положение'
    },
    {
      type: EQuestionType.Radio,
      title: 'Вопрос об отрешении Президента от должности не может быть возбужден в период:',
      choices: [
        'рассмотрения вопроса о досрочном прекращении полномочий Парламента или Мажилиса Парламента',
        'рассмотрения вопроса о досрочном прекращении полномочий Правительства',
        'рассмотрения вопроса о досрочном прекращении полномочий Премьер-Министра',
        'вопрос об отрешении Президента от должности может быть возбужден в любое время'
      ],
      correctAnswer: 'рассмотрения вопроса о досрочном прекращении полномочий Парламента или Мажилиса Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Звание Экс-Президента Республики имеют: ',
      choices: [
        'все бывшие Президенты Республики, кроме отрешенных от должности',
        'все бывшие Президенты Республики',
        'все бывшие Президенты Республики, кроме досрочно освобожденных от должности',
        'лица, исполнявшие полномочия Президента на оставшийся срок после его отрешения'
      ],
      correctAnswer: 'все бывшие Президенты Республики, кроме отрешенных от должности'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент вступает в должность: ',
      choices: [
        'с момента принесения народу присяги',
        'с момента объявления результатов выборов',
        'с момента издания первого акта Президента',
        'по истечении десяти дней со дня принесения народу присяги'
      ],
      correctAnswer: 'с момента принесения народу присяги'
    },
    {
      type: EQuestionType.Radio,
      title: 'Подготовка проектов актов Президента Республики не может осуществляться:',
      choices: [
        'неправительственными организациями',
        'Администрацией Президента и иными государственными органами, непосредственно подчиненными и подотчетными Президенту',
        'Правительством',
        'Верховным Судом'
      ],
      correctAnswer: 'неправительственными организациями'
    },
    {
      type: EQuestionType.Radio,
      title: 'Принимает решение о приостановлении действия не подлежащих ратификации международных договоров, заключенных Казахстаном: ',
      choices: [
        'Президент',
        'Председатель Мажилиса Парламента',
        'Председатель Сената Парламента',
        'Министр иностранных дел'
      ],
      correctAnswer: 'Президент'
    },
    {
      type: EQuestionType.Radio,
      title: 'Помилование граждан отнесено к компетенции: ',
      choices: [
        'Президента',
        'Председателя Сената Парламента',
        'Председателя Мажилиса Парламента',
        'Премьер-Министра'
      ],
      correctAnswer: 'Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Экс-Президенту не предоставляется:',
      choices: [
        'бесплатные поездки за границу',
        'квартира и государственная дача с необходимым обслуживанием',
        'личная охрана',
        'медицинское обслуживание и санаторно-курортное лечение'
      ],
      correctAnswer: 'бесплатные поездки за границу'
    },
    {
      type: EQuestionType.Radio,
      title: 'На период пребывания на посту Президент обеспечивается:',
      choices: [
        'резиденциями на территории Республики, служебной квартирой в столице и государственной дачей',
        'резиденциями на территории Республики и зарубежных стран, служебной квартирой',
        'служебными квартирами в городах республиканского значения, государственной дачей',
        'служебной квартирой в столице, а также частной дачей'
      ],
      correctAnswer: 'резиденциями на территории Республики, служебной квартирой в столице и государственной дачей'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент Республики вправе:',
      choices: [
        'заниматься научной деятельностью',
        'занимать иные оплачиваемые должности',
        'быть депутатом маслихата',
        'осуществлять предпринимательскую деятельность'
      ],
      correctAnswer: 'заниматься научной деятельностью'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае неисполнения членом Правительства законов Президент: ',
      choices: [
        'рассматривает обращение Сената или Мажилиса Парламента об освобождении от должности члена Правительства',
        'рассматривает обращение Премьер-Министра об освобождении от должности члена Правительства',
        'принимает решение о привлечении члена Правительства к административной ответственности',
        'принимает решение о привлечении члена Правительства к уголовной ответственности'
      ],
      correctAnswer: 'рассматривает обращение Сената или Мажилиса Парламента об освобождении от должности члена Правительства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Структура и общая штатная численность государственных органов, непосредственно подчиненных и подотчетных Президенту утверждается:',
      choices: [
        'Президентом',
        'Руководителем государственного органа, непосредственно подчиненного и подотчетного Президенту',
        'Правительством',
        'Парламентом'
      ],
      correctAnswer: 'Президентом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Полномочия Президента в отношении судов: ',
      choices: [
        'представление Сенату Парламента для избрания на должности и освобождения от должностей Председателя и судей Верховного Суда Республики',
        // tslint:disable-next-line:max-line-length
        'самостоятельное решение вопроса избрания на должности и освобождения от должностей Председателя и судей Верховного Суда Республики',
        'избирание Председателя Верховного Суда',
        'приостановление актов Верховного Суда'
      ],
      correctAnswer: 'представление Сенату Парламента для избрания на должности и освобождения от должностей Председателя и судей Верховного Суда Республики'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае досрочного освобождения или отрешения от должности Президента, а также его смерти полномочия Президента на оставшийся срок переходят к Председателю Мажилиса Парламента в случае:',
      choices: [
        'невозможности Председателя Сената принять на себя полномочия Президента',
        'невозможности Премьер-Министра принять на себя полномочия Президента',
        'невозможности Руководителя Администрации Президента принять на себя полномочия Президента',
        'невозможности Государственного секретаря принять на себя полномочия Президента'
      ],
      correctAnswer: 'невозможности Председателя Сената принять на себя полномочия Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Решением Президента пенсия Экс-Президента устанавливается в размере: ',
      choices: [
        'восьмидесяти процентов от должностного оклада Президента',
        'семидесяти процентов от должностного оклада Президента',
        'шестидесяти процентов от должностного оклада Президента',
        'пятидесяти процентов от должностного оклада Президента'
      ],
      correctAnswer: 'восьмидесяти процентов от должностного оклада Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Экс-Президенту предоставляются: ',
      choices: [
        'квартира,  государственная дача с необходимым обслуживанием, личная охрана, персональный автомобиль с водителями, медицинское обслуживание и санаторно-курортное лечение',
        'бесплатные проезд по стране, в зарубежные страны',
        'квартира в столице, личная охрана, автомобиль, частная дача, медицинское обслуживание и санаторно-курортное лечение',
        'квартира за границей (по выбору),  государственная дача с необходимым обслуживанием, личная охрана, персональный автомобиль с водителями'
      ],
      correctAnswer: 'квартира, государственная дача с необходимым обслуживанием, личная охрана, персональный автомобиль с водителями, медицинское обслуживание и санаторно-курортное лечение'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нормы законодательства о государственной службе, трудового и пенсионного законодательства на Президента: ',
      choices: [
        'не распространяются',
        'распространяются в полном объеме',
        'распространяются с определенными законодательством изъятиями',
        'распространяются в части определения порядка оплаты труда'
      ],
      correctAnswer: 'не распространяются'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент Республики Казахстан утверждает: ',
      choices: [
        'Военную доктрину',
        'Военную стратегию',
        'Военную дорожную карту',
        'Военную тактику'
      ],
      correctAnswer: 'Военную доктрину'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент принимает или отклоняет отставку, заявленную Правительством или любым его членом: ',
      choices: [
        'в десятидневный срок',
        'в течение двух недель',
        'в месячный срок',
        'в пятнадцатидневный дней'
      ],
      correctAnswer: 'в десятидневный срок'
    },
    {
      type: EQuestionType.Radio,
      title: 'Досрочно прекращает полномочия маслихата:',
      choices: [
        'Президент',
        'Президент с согласия акима соответствующей административно-территориальной единицы',
        'Президент с согласия Председателя Сената Парламента',
        'Премьер-Министра'
      ],
      correctAnswer: 'Президент'
    },
    {
      type: EQuestionType.Radio,
      title: 'Послание Президента, оглашенное на сессии Парламента: ',
      choices: [
        'не обсуждается и вопросы по нему не задаются',
        'подлежит совместному обсуждению',
        'транслируется в прямом эфире',
        'в подлежит дальнейшему обсуждению на заседании Правительства'
      ],
      correctAnswer: 'не обсуждается и вопросы по нему не задаются'
    },
    {
      type: EQuestionType.Radio,
      title: 'Предложение об использовании Вооруженных Сил Республики для выполнения международных обязательств по поддержанию мира и безопасности Президент вносит:',
      choices: [
        'на рассмотрение совместного заседания Палат Парламента',
        'Председателю Сената Парламента',
        'Председателю Комитета национальной безопасности',
        'на рассмотрение заседания Правительства'
      ],
      correctAnswer: 'на рассмотрение совместного заседания Палат Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'За оказание иностранному государству помощи в осуществлении враждебной деятельности против Республики Президент может быть:',
      choices: [
        'отрешен от занимаемой должности',
        'уволен с занимаемой должности',
        'досрочно освобожден от должности',
        'предан суду'
      ],
      correctAnswer: 'отрешен от занимаемой должности'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае досрочного освобождения или отрешения от должности Президента, а также его смерти полномочия Президента на оставшийся срок переходят к Премьер-Министру в случае:',
      choices: [
        'невозможности Председателя Мажилиса принять на себя полномочия Президента',
        'невозможности Председателя Сената принять на себя полномочия Президента',
        'невозможности Руководителя Администрации Президента принять на себя полномочия Президента',
        'невозможности Государственного секретаря принять на себя полномочия Президента'
      ],
      correctAnswer: 'невозможности Председателя Мажилиса принять на себя полномочия Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'На Президента не распространяется:',
      choices: [
        'пенсионное законодательство',
        'запрет на занятие предпринимательской деятельностью',
        'запрет на занятие иной оплачиваемой деятельности',
        'требование о наличии гражданства Казахстана'
      ],
      correctAnswer: 'пенсионное законодательство'
    },
    {
      type: EQuestionType.Radio,
      title: 'Президент вправе присутствовать на следующих заседаниях Палат Парламента:',
      choices: [
        'на любых совместных заседаниях Палат Парламента',
        'только на заседаниях палат Парламента по особо важным вопросам',
        'Президент не вправе присутствовать на заседаниях Палат Парламента',
        'только на заседаниях палат Парламента по вопросам обеспечения обороны страны'
      ],
      correctAnswer: 'на любых совместных заседаниях Палат Парламента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Назначает на должности и освобождает от должностей председателей и судей местных и других судов: ',
      choices: [
        'Президент по рекомендации Высшего Судебного Совета',
        'Президент по рекомендации Конституционного Совета',
        'Президент по рекомендации Верховного Суда',
        'Президент единолично'
      ],
      correctAnswer: 'Президент по рекомендации Высшего Судебного Совета'
    }
  ],
  time: 10,
  passingScore: 5,
  questionsTotal: 10
};



