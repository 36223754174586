import {EQuestionType, ITest} from '../../../shared/models/test.model';

export const ethicalCode: ITest = {
  id: 'ethicalCode',
  title: 'Этический кодекс',
  description: '<p>Тест на знание этического кодекса государственных служащих РК (правила служебной этики госслужащих)</p>',
  questions: [
    {
      type: EQuestionType.Radio,
      title: 'Должны ли государственные служащие соблюдать установленные законами ограничения и запреты?',
      choices: [
        'да, должны',
        'нет, не должны',
        'не предусмотрено',
        'по согласованию с руководством'
      ],
      correctAnswer: 'да, должны'
    },
    {
      type: EQuestionType.Radio,
      title: 'Какое должностное лицо в центральных исполнительных органах обеспечивает исполнение требований этического кодекса, размещение текста этического кодекса в зданиях государственных органов в местах доступных для всеобщего обозрения?',
      choices: [
        'должностные лица',
        'уполномоченный по этике',
        'руководитель кадровой службы',
        'руководитель юридической службы'
      ],
      correctAnswer: 'должностные лица'
    },
    {
      type: EQuestionType.Radio,
      title: 'В каких целях создан этический кодекс государственного служащего?',
      choices: [
        'в целях повышения требований к морально-нравственному облику и деловым качествам государственных служащих',
        'в целях повышения требований к моральному облику государственных служащих',
        'в целях улучшения доверия общества к государственным органам',
        'в целях совершенствования работы государственных служащих'
      ],
      correctAnswer: 'в целях повышения требований к морально-нравственному облику и деловым качествам государственных служащих'
    },
    {
      type: EQuestionType.Radio,
      title: 'Кто рассчитывает, что государственные служащие будут вкладывать все свои силы, знание и опыт в осуществляемую ими профессиональную деятельность, беспристрастно и честно служить своей Родине – Республике Казахстан?',
      choices: [
        'Президент',
        'Парламент',
        'общество',
        'непосредственный руководитель'
      ],
      correctAnswer: 'общество'
    },
    {
      type: EQuestionType.Radio,
      title: 'Соблюдение государственными служащими Этического кодекса является:',
      choices: [
        'моральным долгом',
        'гражданским долгом',
        'служебным долгом',
        'государственным долгом'
      ],
      correctAnswer: 'служебным долгом'
    },
    {
      type: EQuestionType.Radio,
      title: 'Публикации каких материалов может осуществляться государственными служащими только от собственного имени как частного лица?',
      choices: [
        'по педагогической',
        'по творческой',
        'по научной',
        'все ответы верны'
      ],
      correctAnswer: 'все ответы верны'
    },
    {
      type: EQuestionType.Radio,
      title: 'Противостоять действиям, наносящим ущерб интересам государства, препятствующим или снижающим эффективность функционирования государственных органов, является:',
      choices: [
        'долгом государственного служащего',
        'правом государственного служащего',
        'обязанностью государственного служащего',
        'кредом государственного служащего'
      ],
      correctAnswer: 'долгом государственного служащего'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственный служащий должен:',
      choices: [
        'неукоснительно соблюдать служебную дисциплину',
        'обеспечивать сохранность государственной собственности',
        'не распространять сведения, не соответствующие действительности',
        'все ответы верны'
      ],
      correctAnswer: 'все ответы верны'
    },
    {
      type: EQuestionType.Radio,
      title: 'Какое из утверждений верно?',
      choices: [
        'государственные служащие должны вести дискуссии в корректной форме, не подрывая авторитета государственной службы',
        'государственные служащие не должны допускать случаев антиобщественного поведения',
        'государственные служащие должны проявлять скромность, не подчеркивать и не использовать свое должностное положение при получении соответствующих услуг',
        'все ответы верны'
      ],
      correctAnswer: 'все ответы верны'
    },
    {
      type: EQuestionType.Radio,
      title: 'Текст Этического кодекса должен размещаться в зданиях государственных органов',
      choices: [
        'в местах, доступных для работников государственного органа',
        'в местах, доступных для всеобщего обозрения',
        'в кадровой службе',
        'на первом этаже здания'
      ],
      correctAnswer: 'в местах, доступных для всеобщего обозрения'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нарушение служебной этики государственным служащим влечет:',
      choices: [
        'дисциплинарную ответственность',
        'административную ответственность',
        'уголовную ответственность',
        'материальную ответственность'
      ],
      correctAnswer: 'дисциплинарную ответственность'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные служащие должны:',
      choices: [
        'противостоять действиям, наносящим ущерб интересам государства',
        'использовать служебное положение для оказания влияния на деятельность государственных органов, организаций, государственных служащих и иных лиц при решении вопросов личного характера',
        'распространять сведения, не соответствующие действительности',
        'раскрывать служебную информацию'
      ],
      correctAnswer: 'противостоять действиям, наносящим ущерб интересам государства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Внешний вид государственного служащего при исполнении им служебных обязанностей должен соответствовать:',
      choices: [
        'общепринятому деловому стилю',
        'общепринятому модному стилю',
        'удобному стилю одежды',
        'официальному стилю'
      ],
      correctAnswer: 'общепринятому деловому стилю'
    },
    {
      type: EQuestionType.Radio,
      title: 'В соответствии с каким нормативным правововым актом устанавливаются основные требования к морально-этическому облику государственного служащего?',
      choices: [
        'Конституция',
        'закон «О государственной службе Республики Казахстан»',
        'закон «О противодействии коррупции»',
        'все отеты правильные'
      ],
      correctAnswer: 'все отеты правильные'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные служащие должны обеспечивать прозрачность принятия решений, затрагивающих права и законные интересы:',
      choices: [
        'физических и юридических лиц',
        'государственных служащих',
        'только физических лиц',
        'физических лиц и государственных органов'
      ],
      correctAnswer: 'физических и юридических лиц'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные служащие, занимающие нижестоящие должности, должны при выполнении поручения руководителя?',
      choices: [
        'выполнять все поручения безоговорочно',
        'представлять только объективные и достоверные сведения',
        'представлять руководителю подробную и устную информацию',
        'сообщать о каждом факте просрочки документов посредством служебных записок'
      ],
      correctAnswer: 'представлять только объективные и достоверные сведения'
    },
    {
      type: EQuestionType.Radio,
      title: 'В своей служебной деятельности государственные служащие должны быть привержены политике?',
      choices: [
        'государства и народа',
        'правового государства',
        'государственной политике',
        'Премьер-Министра'
      ],
      correctAnswer: 'государственной политике'
    },
    {
      type: EQuestionType.Radio,
      title: 'Руководители в отношениях с подчинёнными служащими должны?',
      choices: [
        'обеспечивать их необходимыми материалами',
        'обеспечивать их защиту от неправомерных действий граждан',
        'обеспечивать соблюдение принципов меритократии',
        'показывать свою значимость и важность'
      ],
      correctAnswer: 'обеспечивать соблюдение принципов меритократии'
    },
    {
      type: EQuestionType.Radio,
      title: 'Публикация материалов по педагогической, научной, творческой деятельности может осуществляться государственными служащими?',
      choices: [
        'от собственного имени как частного лица',
        'как от собственного имени, так и от государственного органа',
        'запрещено публиковать такие материалы государственным служащим',
        'от имени государства'
      ],
      correctAnswer: 'от собственного имени как частного лица'
    },
    {
      type: EQuestionType.Radio,
      title: 'Этический кодекс государственных служащих направлен на укрепление доверия общества?',
      choices: [
        'к Правительству',
        'Президенту',
        'Премьер-Министру',
        'к государственным органам'
      ],
      correctAnswer: 'к государственным органам'
    },
    {
      type: EQuestionType.Radio,
      title: 'При решении каких вопросов руководитель в отношениях с подчинёнными служащими должен соблюдать принципы меритократии?',
      choices: [
        'при решении кадровых вопросов',
        'при предоставлении премии',
        'при предоставлении отпуска',
        'при решении вопросов наказания'
      ],
      correctAnswer: 'при решении кадровых вопросов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Какие качества должен проявить руководитель при оценке результатов деятельности подчинённых?',
      choices: [
        'честность и открытость',
        'объективность и справедливость',
        'законность и официозность',
        'этичность и справедливость'
      ],
      correctAnswer: 'объективность и справедливость'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные служащие во внеслужебное время должны?',
      choices: [
        'не подчеркивать и не использовать свое должностное положение при получении соответствующих услуг',
        'подчеркивать и использовать свое должностное положение при получении соответствующих услуг',
        'вести себя в отношениях с использованием своего должностного положения при получении услуг',
        'нет правильного ответа'
      ],
      correctAnswer: 'не подчеркивать и не использовать свое должностное положение при получении соответствующих услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Кто должен принимать меры, исключающие любые формы дискриминации, посягательства на честь и достоинство служащего?',
      choices: [
        'подчиненный',
        'начальник службы персонала',
        'руководитель',
        'Уполномоченный по правам человека'
      ],
      correctAnswer: 'руководитель'
    },
    {
      type: EQuestionType.Radio,
      title: 'Какие виды стандартов поведения государственных служащих содержатся в этическом кодексе?',
      choices: [
        'общие стандарты поведения, стандарты поведения во внеслужебное время, стандарты поведения в служебных отношениях, стандарты поведения в командировке',
        'стандарты поведения во внеслужебное время, стандарты поведения в служебных отношениях',
        'общие стандарты поведения, стандарты поведения во внеслужебное время, стандарты поведения в служебных отношениях, стандарты поведения, связанные с публичными выступлениями, в том числе в СМИ',
        'общие стандарты поведения, стандарты поведения во внеслужебное время, стандарты поведения, связанные с публичными выступлениями, в том числе в СМИ'
      ],
      correctAnswer: 'общие стандарты поведения, стандарты поведения во внеслужебное время, стандарты поведения в служебных отношениях, стандарты поведения, связанные с публичными выступлениями, в том числе в СМИ'
    },
    {
      type: EQuestionType.Radio,
      title: 'Госслужащие не должны допускать совершение проступков и иных правонарушений за которые законом предусмотрена?',
      choices: [
        'административная, дисциплинарная, уголовная ответственность',
        'только дисциплинарная ответственность',
        'административная и уголовная ответственность',
        'административная и дисциплинарная ответственность'
      ],
      correctAnswer: 'административная, дисциплинарная, уголовная ответственность'
    },
    {
      type: EQuestionType.Radio,
      title: 'Каким нормативно-правовым актом утвержден Этический кодекс госслужащих РК?',
      choices: [
        'Законом',
        'Конституционным законом',
        'Указом Президента',
        'Постановлением Правительства'
      ],
      correctAnswer: 'Указом Президента'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные служащие, занимающие нижестоящие должности, должны при выполнении поручения руководителя?',
      choices: [
        'выполнять все поручения безоговорочно',
        'представлять только объективные и достоверные сведения',
        'представлять руководителю подробную и устную информацию',
        'сообщать о каждом факте просрочки документов посредством служебных записок'
      ],
      correctAnswer: 'представлять только объективные и достоверные сведения'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные служащие, занимающие нижестоящие должности, должны?',
      choices: [
        'незамедлительно доводить до сведения руководства и уполномоченного по этике ставшие им известными нарушения норм служебной этики, дисциплинарных проступков, дискредитирующих госслужбу',
        'незамедлительно доводить до сведения руководства и уполномоченного по этике ставшие им известными нарушения норм уголовного кодекса',
        'в течение суток доводить до сведения руководства и уполномоченного по этике ставшие им известными факты коррупции',
        'незамедлительно доводить до сведения уполномоченного по этике ставшие им известными нарушения норм служебной этики и просрочке по кредитам'
      ],
      correctAnswer: 'незамедлительно доводить до сведения руководства и уполномоченного по этике ставшие им известными нарушения норм служебной этики, дисциплинарных проступков, дискредитирующих госслужбу'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные служащие, занимающие нижестоящие должности, должны?',
      choices: [
        'не допускать действий, препятствующих выполнению НЕправомерных поручений руководителя',
        'не допускать действий, препятствующих выполнению правомерных поручений руководителя',
        'препятствовать выполнению правомерных поручений руководителя',
        'предупреждать всех о том, что выполняют поручения руководителя и не могут выполнять другую работу'
      ],
      correctAnswer: 'не допускать действий, препятствующих выполнению правомерных поручений руководителя'
    },
    {
      type: EQuestionType.Radio,
      title: 'В каких случаях государственные служащие должны придерживаться делового стиля в одежде?',
      choices: [
        'при исполнении ими служебных обязанностей',
        'всегда должны придерживаться',
        'не обязаны придерживаться',
        'деловой стиль одежды только на совещаниях и встречах'
      ],
      correctAnswer: 'при исполнении ими служебных обязанностей'
    },
    {
      type: EQuestionType.Radio,
      title: 'Так, руководители в отношениях с подчиненными служащими должны?',
      choices: [
        'своим поведением служить примером крутости и подражания',
        'своим поведением воодушевлять их быть исполнительными и безынициативными',
        'вызывать чувство гордости от работы на государственной службе',
        'своим поведением служить примером беспристрастности, справедливости, бескорыстия, уважительного отношения к чести и достоинству личности'
      ],
      correctAnswer: 'своим поведением служить примером беспристрастности, справедливости, бескорыстия, уважительного отношения к чести и достоинству личности'
    },
    {
      type: EQuestionType.Radio,
      title: 'К какому стандарту поведения относится обеспечение соблюдения принципов меритократии при решении кадровых вопросов?',
      choices: [
        'к стандарту поведения во внеслужебное время',
        'к общему стандарту поведения',
        'к стандарту поведения в служебных отношениях',
        'к стандарту поведения, связанному с публичными выступлениями, в том числе в СМИ'
      ],
      correctAnswer: 'к стандарту поведения в служебных отношениях'
    },
    {
      type: EQuestionType.Radio,
      title: 'Несение государственной службы является:',
      choices: [
        'выражением особого доверия со стороны Правительства',
        'выражением личного доверия со стороны первого руководителя государственного органа, в котором работает государственный служащий',
        'выражением особого доверия со стороны руководства и коллектива государственного органа',
        'выражением особого доверия со стороны общества и государства'
      ],
      correctAnswer: 'выражением особого доверия со стороны общества и государства'
    },
    {
      type: EQuestionType.Radio,
      title: 'Этический кодекс государственных служащих Республики Казахстан устанавливает:',
      choices: [
        'основные требования к морально-этическому облику государственных служащих, а также базовые стандарты их поведения',
        'быть приверженными политике Президента Республики Казахстан',
        'порядок соблюдения государственной и трудовой дисциплины государственных служащих Республики Казахстан',
        'порядок наложения дисциплинарных взысканий к государственным служащим за нарушение норм служебной этики'
      ],
      correctAnswer: 'основные требования к морально-этическому облику государственных служащих, а также базовые стандарты их поведения'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные служащие в деятельность лиц, причастных к принятию решений по вопросам, затрагивающим личные интересы государственного служащего:',
      choices: [
        'могут вмешиваться',
        'не должны вмешиваться',
        'должны вмешиваться'
      ],
      correctAnswer: 'не должны вмешиваться'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не допускать совершения действий, способных дискредитировать Казахстан является долгом:',
      choices: [
        'всех граждан Республики Казахстан',
        'всех государственных служащих',
        'только политических государственных служащих',
        'только административных государственных служащих'
      ],
      correctAnswer: 'всех государственных служащих'
    },
    {
      type: EQuestionType.Radio,
      title: 'Все государственные служащие должны',
      choices: [
        'уважительно относиться к государственному и другим языкам народа Казахстана',
        'уважительно относиться только к государственному и русскому языку',
        'уважительно относиться только к языкам национальных меньшинств Казахстана',
        'уважительно относиться только к государственному языку'
      ],
      correctAnswer: 'уважительно относиться к государственному и другим языкам народа Казахстана'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные служащие, имеющие в своем подчинении работников:',
      choices: [
        'обязаны ставить в известность своих подчиненных о причине своего отсутствия на рабочем месте',
        'не должны допускать по отношению к своим подчиненным необоснованных обвинений',
        'могут возлагать свои непосредственные должностные полномочия на своих подчиненных'
      ],
      correctAnswer: 'не должны допускать по отношению к своим подчиненным необоснованных обвинений'
    },
    {
      type: EQuestionType.Radio,
      title: 'Публичные выступления по вопросам деятельности государственного органа возможно осуществлять:',
      choices: [
        'руководителями структурных подразделений государственного органа без соответствующих полномочий',
        'пресс-службой государственного органа',
        'руководителем кадровой службы',
        'руководителем государственного органа или уполномоченными на это должностными лицами'
      ],
      correctAnswer: 'руководителем государственного органа или уполномоченными на это должностными лицами'
    }
  ],
  time: 10,
  passingScore: 5,
  questionsTotal: 10
};
