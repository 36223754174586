import {IExam} from '../../../shared/models/test.model';

export const lawEnforcementServiceSecond: IExam = {
  id: 'lawEnforcementServiceSecond',
  title: 'Правоохранительная госслужба (программа 2)',
  topics: [
    {
      id: 'constitution',
      title: 'Конституция РК',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: '',
      title: 'Уголовный кодекс РК',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: '',
      title: 'Уголовно-процессуальный кодекс РК',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: '',
      title: 'Кодекс РК об административных правонарушениях',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: 'antiCorruption',
      title: 'Закон «O противодействии коррупции»',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: '',
      title: 'Закон «O правохранительной службе»',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    },
    {
      id: 'ethicalCode',
      title: 'Этический кодекс государственных служащих РК (правила служебной этики госслужащих)',
      questions: [],
      questionsCount: 15,
      passingScore: 5
    }
  ],
  time: 90,
  questionsTotal: 105,
  passingScore: 63
};

