import {EQuestionType, ITest} from '../../../shared/models/test.model';

export const publicServices: ITest = {
  id: 'publicServices',
  title: 'Госуслуги',
  description: '<p>Тест на знание закона «O государственных услугах»</p>',
  questions: [
    {
      type: EQuestionType.Radio,
      title: 'Регламент государственной услуги, разрабатываемый местным исполнительным органом области, города республиканского значения, столицы утверждается:',
      choices: [
        'нормативным правовым постановлением акимата области, города республиканского значения, столицы',
        'актом Президента',
        'нормативным постановлением Правительства',
        'актом Центрального государственного органа',
      ],
      correctAnswer: 'нормативным правовым постановлением акимата области, города республиканского значения, столицы'
    },
    {
      type: EQuestionType.Radio,
      title: 'Услугодатели имеют право:',
      choices: [
        'обращаться с запросом в государственные органы за информацией, необходимой для оказания государственных услуг',
        'оказывать государственные услуги в соответствии со стандартами и регламентами государственных услуг',
        'создавать необходимые условия для лиц с ограниченными возможностями при получении ими государственных услуг',
        'предоставлять полную и достоверную информацию о порядке оказания государственных услуг услугополучателям в доступной форме',
      ],
      correctAnswer: 'обращаться с запросом в государственные органы за информацией, необходимой для оказания государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Правительство Республики Казахстан:',
      choices: [
        'утверждает реестр государственных услуг',
        'утверждает стандарты государственных услуг',
        'утверждает правила ведения реестра государственных услуг',
        'утверждает регламенты государственных услуг',
      ],
      correctAnswer: 'утверждает реестр государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Юридическое лицо, созданное по решению Правительства, для оказания государственных услуг, организации работы по приему заявлений на оказание государственных услуг и выдаче их результатов услугополучателю по принципу «одного окна», а также обеспечения оказания государственных услуг в электронной форме:',
      choices: [
        'Государственная корпорация «Правительство для граждан»',
        'государственный орган',
        'услугодатель',
        'центральный государственный орган'
      ],
      correctAnswer: 'Государственная корпорация «Правительство для граждан»'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нормативный правовой акт, разрабатываемый и утверждаемый после утверждения и введения в действие стандарта государственной услуги:',
      choices: [
        'регламент государственной услуги',
        'паспорт государственной услуги',
        'порядок обжалования государственной услуги',
        'инструкция по предоставлению государственной услуги',
      ],
      correctAnswer: 'регламент государственной услуги'
    },
    {
      type: EQuestionType.Radio,
      title: 'Публичное обсуждение проектов стандартов государственных услуг проводится с целью:',
      choices: [
        'учета замечаний и предложений физических и  юридических лиц, права, свободы и законные интересы которых затрагиваются стандартами государственных услуг',
        'оптимизации государственных услуг',
        'разработки проектов подзаконных актов, направленных на регулирование сферу оказания государственных услуг',
        'автоматизации государственных услуг',
      ],
      correctAnswer: 'учета замечаний и предложений физических и  юридических лиц, права, свободы и законные интересы которых затрагиваются стандартами государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Информационная система, представляющая собой единое окно доступа ко всей консолидированной правительственной информации, включая доступ к государственным услугам, оказываемым в электронной форме:',
      choices: [
        'веб-портал «электронного правительства»',
        'веб-портал Государственной корпорации «Правительство для граждан»',
        'веб-портал «открытое окно»',
        'интернет-ресурс уполномоченного органа по оказанию государственных услуг'
      ],
      correctAnswer: 'веб-портал «электронного правительства»'
    },
    {
      type: EQuestionType.Radio,
      title: 'К компетенции центральных государственных органов в сфере государственных услуг отнесено:',
      choices: [
        'рассмотрение обращений услугополучателей по вопросам оказания государственных услуг',
        'обеспечение повышения качества оказания государственных услуг на территории соответствующей административно-территориальной единицы',
        'организация и координация работы Единого контакт-центра',
        'все ответы верны',
      ],
      correctAnswer: 'рассмотрение обращений услугополучателей по вопросам оказания государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Оказание услугополучателям нескольких государственных услуг в электронной форме на основании одного заявления:',
      choices: [
        'возможно',
        'невозможно',
        'только пенсионерам',
        'только инвалидам',
      ],
      correctAnswer: 'возможно'
    },
    {
      type: EQuestionType.Radio,
      title: 'Классифицированный перечень государственных услуг:',
      choices: [
        'реестр государственных услуг',
        'список государственных услуг',
        'свод государственных услуг',
        'перечень государственных услуг',
      ],
      correctAnswer: 'реестр государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Периодичность проведения публичных обсуждений отчетов о деятельности в сфере оказания государственных услуг государственными органами:',
      choices: [
        'не реже одного раза в год',
        'не реже одного раза в квартал',
        'не реже двух раз в год',
        'не реже одного раза в полгода',
      ],
      correctAnswer: 'не реже одного раза в год'
    },
    {
      type: EQuestionType.Radio,
      title: 'Публичное обсуждение проекта стандарта государственной услуги осуществляется в течение:',
      choices: [
        'тридцати календарных дней со дня его размещения для публичного обсуждения',
        'десяти календарных дней со дня его размещения для публичного обсуждения',
        'десяти рабочих дней со дня его размещения для публичного обсуждения',
        'пятнадцати календарных дней со дня его размещения для публичного обсуждения',
      ],
      correctAnswer: 'тридцати календарных дней со дня его размещения для публичного обсуждения'
    },
    {
      type: EQuestionType.Radio,
      title: 'Иностранцы, лица без гражданства и иностранные юридические лица:',
      choices: [
        'получают государственные услуги наравне с гражданами и юридическими лицами Республики Казахстан',
        'не получают государственные услуги',
        'получают государственные услуги через соответствующее посольство',
        'получают только юридические лица',
      ],
      correctAnswer: 'получают государственные услуги наравне с гражданами и юридическими лицами Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Уполномоченный орган по оценке и контролю за качеством оказания государственных услуг – это :',
      choices: [
        'центральный государственный орган, осуществляющий в пределах своей компетенции деятельность по оценке и контролю за качеством оказания государственных услуг',
        'местный исполнительный орган, осуществляющий в пределах своей компетенции деятельность по оценке и контролю за качеством оказания государственных услуг',
        'местный представительный орган, осуществляющий контроль за качеством оказания государственных услуг',
        'центральный государственный орган, осуществляющий общую политику в сфере оказания государственных услуг',
      ],
      correctAnswer: 'центральный государственный орган, осуществляющий в пределах своей компетенции деятельность по оценке и контролю за качеством оказания государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Одним из прав услугополучателя является:',
      choices: [
        'получение государственной услуги в соответствии со стандартом государственной услуги',
        'обращение с запросом в государственные органы за информацией, необходимой для оказания государственных услуг',
        'отказ в оказании государственных услуг в случаях и по основаниям, установленным законами Республики  Казахстан',
        'объем прав услугополучателя определят услугодатель по своему усмотрению',
      ],
      correctAnswer: 'получение государственной услуги в соответствии со стандартом государственной услуги'
    },
    {
      type: EQuestionType.Radio,
      title: 'Услугодатель – это:',
      choices: [
        'центральные государственные органы, загранучреждения, местные исполнительные органы, акимы соответствующих адниминистративно-территориальных единиц, а также физические и юридические лица, оказывающие государственные услуги в соответствии с законодательством Республики Казахстан',
        'центральные государственные органы, загранучреждения Республики Казахстан, местные исполнительные органы соответствующих адниминистративно-территориальных единиц',
        'нет правильного ответа',
        'все государственные органы, оказывающие государственные услуги в соответствии с законодательством Республики Казахстан',
      ],
      correctAnswer: 'центральные государственные органы, загранучреждения, местные исполнительные органы, акимы соответствующих адниминистративно-территориальных единиц, а также физические и юридические лица, оказывающие государственные услуги в соответствии с законодательством Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'В целях обеспечения единых требований к качеству оказания государственных услуг центральными государственными органами:',
      choices: [
        'разрабатываются и утверждаются стандарты государственных услуг',
        'разрабатывается единый план государственных услуг',
        'разрабатываются новые технологии государственных услуг',
        'разрабатывается порядок обжалования',
      ],
      correctAnswer: 'разрабатываются и утверждаются стандарты государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'При оказании государственных услуг через Государственную корпорацию, оказание которых предусматривает отправку заявления и документов услугополучателя услугодателям на бумажном носителе, день приема заявлений и документов:',
      choices: [
        'не входит в срок оказания государственной услуги, установленный стандартом государственной услуги',
        'входит в срок оказания государственной услуги, установленный стандартом государственной услуги',
        'определяется по усмотрению услугодателя',
        'с письменного заявления услугополучателя не засчитывается в срок оказания государственной услуги, установленный стандартом государственной услуги',
      ],
      correctAnswer: 'не входит в срок оказания государственной услуги, установленный стандартом государственной услуги'
    },
    {
      type: EQuestionType.Radio,
      title: 'Форма централизованного оказания государственной услуги, предусматривающая минимальное участие услугополучателя в сборе и подготовке документов при оказании государственной услуги и ограничение его непосредственного контакта с услугодателями является:',
      choices: [
        'принципом «одного окна»',
        'принципом справедливости',
        'принципом нетерпимости к коррупции',
        'принципом равного доступа услугополучателей к государственной услуге'
      ],
      correctAnswer: 'принципом «одного окна»'
    },
    {
      type: EQuestionType.Radio,
      title: 'Общественный мониторинг качества государственных услуг:',
      choices: [
        'деятельность физических лиц, некоммерческих организаций по сбору, анализу информации об уровне качества оказания государственных услуг и выработке рекомендаций',
        'деятельность юридических лиц по сбору информации об уровне инфляции',
        'деятельность коммерческих организаций по сбору, анализу информации об уровне качества оказания коммерческих услуг и выработке рекомендаций',
        'формирование статистических данных об оказании государственных услуг посредством Государственной корпорации «Правительство для граждан»'
      ],
      correctAnswer: 'деятельность физических лиц, некоммерческих организаций по сбору, анализу информации об уровне качества оказания государственных услуг и выработке рекомендаций'
    },
    {
      type: EQuestionType.Radio,
      title: 'Жалоба услугополучателя, поступившая в адрес государственного органа, услугодателя, Государственной корпорации «Правительство для граждан», подлежит рассмотрению со дня ее регистрации в течение:',
      choices: [
        'пяти рабочих дней',
        'трех рабочих дней',
        'десяти рабочих дней',
        'пяти календарных дней',
      ],
      correctAnswer: 'пяти рабочих дней'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные услуги в Республике Казахстан оказываются:',
      choices: [
        'на платной или бесплатной основе',
        'на платной основе',
        'на бесплатной основе',
        'по усмотрению услугодателя',
      ],
      correctAnswer: 'на платной или бесплатной основе'
    },
    {
      type: EQuestionType.Radio,
      title: 'Объектом государственного контроля за качеством оказания государственных услуг является деятельность в сфере оказания государственных услуг:',
      choices: [
        'центральных государственных органов, местных исполнительных органов, а также физических и юридических лиц, оказывающих государственные услуги в соответствии с законодательством Республики Казахстан',
        'Правительства',
        'Премьер-Министра',
        'Генеральной Прокуратуры',
      ],
      correctAnswer: 'центральных государственных органов, местных исполнительных органов, а также физических и юридических лиц, оказывающих государственные услуги в соответствии с законодательством Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Информирование услугополучателей о размещенном для публичного обсуждения проекте стандарта государственной услуги обеспечивается центральным государственным органом, разрабатывающим стандарт государственной услуги, в течение:',
      choices: [
        '5 рабочих дней со дня включения государственной услуги в реестр государственных услуг',
        '3 рабочих дней со дня включения государственной услуги в реестр государственных услуг',
        '10 рабочих дней со дня включения государственной услуги в реестр государственных услуг',
        'одного месяца со дня включения государственной услуги в реестр государственных услуг',
      ],
      correctAnswer: '5 рабочих дней со дня включения государственной услуги в реестр государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Услугодатели имеют право:',
      choices: [
        'отказывать в оказании государственных услуг, в случаях и по основаниям, установленным законами Республики Казахстан',
        'предоставлять центральным государственным органам, местным исполнительным органам, иным услугодателям, Государственной корпорации документы и информацию, необходимые для оказания государственных услуг, в том числе посредством интеграции информационных систем',
        'повышать квалификацию работников в сфере оказания государственных услуг',
        'информировать по запросу услугополучателей о стадии исполнения государственной услуги',
      ],
      correctAnswer: 'отказывать в оказании государственных услуг, в случаях и по основаниям, установленным законами Республики Казахстан'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не относится к принципам оказания государственных услуг:',
      choices: [
        'необоснованный отказ в предоставлении государственных услуг',
        'недопустимость проявлений бюрократизма и волокиты при оказании государственных услуг',
        'подотчетность и прозрачность в сфере оказания государственных услуг',
        'качество и доступность государственных услуг',
      ],
      correctAnswer: 'необоснованный отказ в предоставлении государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Осуществление деятельности единого провайдера иными лицами:',
      choices: [
        'запрещается',
        'разрешается в соответствии с законодательством Республики Казахстан',
        'прямо не запрещается',
        'вопрос осуществление деятельности единого провайдера иными лицами не урегулирован',
      ],
      correctAnswer: 'запрещается'
    },
    {
      type: EQuestionType.Radio,
      title: 'Регламент государственной услуги, разрабатываемый центральным государственным органом, утверждается:',
      choices: [
        'Нормативным правовым актом центрального государственного органа или его руководителя',
        'Президентом',
        'Правительством',
        'Парламентом',
      ],
      correctAnswer: 'Нормативным правовым актом центрального государственного органа или его руководителя'
    },
    {
      type: EQuestionType.Radio,
      title: 'Мероприятие, направленное на упрощение процесса оказания государственной услуги, сокращение срока оказания государственной услуги, перечня документов, представляемых услугополучателями, а также звеньев процесса ее оказания, в том числе путем автоматизации – это:',
      choices: [
        'оптимизация процесса оказания государственных услуг',
        'активизация процесса оказания государственных услуг',
        'автоматизация процесса оказания государственных услуг',
        'обеспечение «прозрачности» оказания государственных услуг'
      ],
      correctAnswer: 'оптимизация процесса оказания государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Жалоба услугополучателя в адрес уполномоченного органа по оценке и контролю за качеством оказания государственных услуг, подлежит рассмотрению в течение:',
      choices: [
        'пятнадцати рабочих дней',
        'трех рабочих дней',
        'десяти рабочих дней',
        'пяти рабочих дней',
      ],
      correctAnswer: 'пятнадцати рабочих дней'
    },
    {
      type: EQuestionType.Radio,
      title: 'Если международным договором, ратифицированным Республикой Казахстан, установлены иные правила, чем те, которые содержатся в настоящем Законе, то: ',
      choices: [
        'применяются правила международного договора',
        'применяются нормы Закона',
        'применяются нормы, действие которых максимально обеспечивает защиту прав и интересов услугополучателя',
        'вопрос выбора нормы направляется в Конституционный Совет',
      ],
      correctAnswer: 'применяются правила международного договора'
    },
    {
      type: EQuestionType.Radio,
      title: 'Деятельность по определению эффективности мер по обеспечению услугополучателей доступными и качественными государственными услугами:',
      choices: [
        'оценка качества оказания государственных услуг',
        'оценка механизма оказания государственных услуг',
        'оценка деятельности оказания государственных услуг',
        'оценка работы оказания государственных услуг',
      ],
      correctAnswer: 'оценка качества оказания государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Нормативный правовой акт, устанавливающий требования к оказанию государственной услуги, а также включающий характеристики процесса, формы, содержание и результат оказания государственной услуги:',
      choices: [
        'стандарт государственной услуги',
        'регламент государственной услуги',
        'инструкция',
        'положение о государственных услугах',
      ],
      correctAnswer: 'стандарт государственной услуги'
    },
    {
      type: EQuestionType.Radio,
      title: 'Требования и порядок оказания государственных услуг услугодателями определяются:',
      choices: [
        'стандартом и регламентом государственных услуг',
        'стандартом государственных услуг',
        'регламентом государственных услуг',
        'паспортом государственной услуги',
      ],
      correctAnswer: 'стандартом и регламентом государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Реестр государственных услуг не предусматривает:',
      choices: [
        'сроки оказания государственных услуг',
        'сведения об услугополучателе',
        'наименование организаций, осуществляющих прием заявлений и выдачу результатов оказания государственной услуги, и (или) указания на веб-портал «электронного правительства» в случае оказания государственной услуги в электронной форме',
        'форму оказания государственной услуги'
      ],
      correctAnswer: 'сроки оказания государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Единственным акционером Государственной корпорации «Правительство для граждан» является:',
      choices: [
        'Правительство',
        'Президент',
        'Парламент',
        'Администрация Президента',
      ],
      correctAnswer: 'Правительство'
    },
    {
      type: EQuestionType.Radio,
      title: 'Процедура преобразования административных процессов услугодателя для обеспечения оказания государственной услуги в электронной форме:',
      choices: [
        'автоматизация процесса оказания государственных услуг',
        'активизация процесса оказания государственных услуг',
        'оптимизация процесса оказания государственных услуг',
        'электронизация процесса оказания государственных услуг',
      ],
      correctAnswer: 'автоматизация процесса оказания государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Услугодатели обязаны:',
      choices: [
        'принимать меры, направленные на восстановление нарушенных прав, свобод и законных интересов услугополучателей',
        'обращаться с запросом в центральные государственные органы за информацией, необходимой для оказания государственных услуг',
        'отказывать в оказании государственных услуг в случаях и по основаниям, установленным законами Республики Казахстан',
        'обращаться с запросом в местные исполнительные органы за информацией, необходимой для оказания государственных услуг',
      ],
      correctAnswer: 'принимать меры, направленные на восстановление нарушенных прав, свобод и законных интересов услугополучателей'
    },
    {
      type: EQuestionType.Radio,
      title: 'Юридическое лицо, определенное Правительством, выполняющее функции информационно-справочной службы по предоставлению услугополучателям информации по вопросам оказания государственных и иных услуг, а также государственным органам – информации по вопросам оказания информационно-коммуникационных услуг:',
      choices: [
        'Единый контакт-центр',
        'Телефон доверия по вопросам оказания государственных услуг',
        'Территориальная единая служба по вопросам оказания государственных услуг',
        'Контакт-центр Государственной корпорации «Правительство для граждан»'
      ],
      correctAnswer: 'Единый контакт-центр'
    },
    {
      type: EQuestionType.Radio,
      title: 'В случае продления срока рассмотрения жалобы, услугополучателю, подавшему жалобу, в письменной форме или электронной форме сообщается о продлении срока рассмотрения жалобы с указанием причин продления в течение:',
      choices: [
        'трех рабочих дней',
        'трех календарных дней',
        'пяти календарных дней',
        'пяти рабочих дней',
      ],
      correctAnswer: 'трех рабочих дней'
    },
    {
      type: EQuestionType.Radio,
      title: 'К приницпам оказания государственных услуг не относится принцип:',
      choices: [
        'предоставления государственных услуг только на платной основе',
        'равного доступа услугополучателям без какой-либо дискриминации по мотивам происхождения, социального, должностного и имущественного положения, пола, расы, национальности, языка, отношения к религии, убеждений, места жительства или по любым иным обстоятельствам',
        'недопустимости проявлений бюрократизма и волокиты при оказании государственных услуг',
        'качества и доступности государственных услуг',
      ],
      correctAnswer: 'предоставления государственных услуг только на платной основе'
    },
    {
      type: EQuestionType.Radio,
      title: 'Проводится физическими лицами, некоммерческими организациями по собственной инициативе и за свой счет:',
      choices: [
        'общественный мониторинг',
        'контроль за качеством оказания государственных услуг',
        'оптимизация процесса оказания государственных услуг',
        'автоматизация процесса оказания государственных услуг',
      ],
      correctAnswer: 'общественный мониторинг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не является правом услугополучателей:',
      choices: [
        'создавать необходимые условия для лиц с ограниченными возможностями при получении ими государственных услуг',
        'получать государственную услугу в соответствии со стандартом государственной услуги',
        'получать государственную услугу в бумажной и (или) электронной форме в соответствии с законодательством Республики Казахстан',
        'обращаться в суд с иском о защите нарушенных прав, свобод и законных интересов в сфере оказания государственных услуг',
      ],
      correctAnswer: 'создавать необходимые условия для лиц с ограниченными возможностями при получении ими государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не является принципом оказания государственных услуг принцип:',
      choices: [
        'осуществления общественного мониторинга только неправительственными организациями',
        'подотчетности и прозрачности в сфере оказания государственных услуг',
        'постоянного совершенствования процесса оказания государственных услуг',
        'экономичности и эффективности при оказании государственных услуг',
      ],
      correctAnswer: 'осуществления общественного мониторинга только неправительственными организациями'
    },
    {
      type: EQuestionType.Radio,
      title: 'Уполномоченный орган Государственной корпорации «Правительство для граждан» определяется решением Правительства из числа:',
      choices: [
        'центральных государственных органов',
        'местных исполнительных органов',
        'местных представительных органов',
        'правоохранительных органов',
      ],
      correctAnswer: 'центральных государственных органов'
    },
    {
      type: EQuestionType.Radio,
      title: 'Срок рассмотрения жалобы уполномоченным органом по оценке и контролю за качеством оказания государственных услуг, центральным государственным органом, местным исполнительным органом продлевается не более чем:',
      choices: [
        'на десять рабочих дней',
        'на три рабочих дня',
        'на пятнадцать рабочих дней',
        'на пять календарных дней',
      ],
      correctAnswer: 'на десять рабочих дней'
    },
    {
      type: EQuestionType.Radio,
      title: 'Деятельность по проверке и мониторингу соблюдения законодательства в сфере оказания государственных услуг центральными государственными органами, местными исполнительными органами, а также физическими и юридическим лицами, оказывающими государственные услуги:',
      choices: [
        'государственный контроль за качеством оказания государственных услуг',
        'мониторинг за качеством оказания государственных услуг',
        'проверка за качеством оказания государственных услуг',
        'ревизия за качеством оказания государственных услуг',
      ],
      correctAnswer: 'государственный контроль за качеством оказания государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Оптимизация процессов оказания государственных услуг осуществляется:',
      choices: [
        'центральными государственными органами, местными исполнительными органами',
        'Правительством',
        'центральными государственными органами',
        'местными исполнительными органами',
      ],
      correctAnswer: 'центральными государственными органами, местными исполнительными органами'
    },
    {
      type: EQuestionType.Radio,
      title: 'При оказании государственных услуг не допускается истребование от услугополучателей:',
      choices: [
        'документов, которые могут быть получены из информационных систем',
        'документов, удостоверяющих личность услугополучателя',
        'документов, предусмотренных для предоставления государственной услуги',
        'документов, подтверждающих оплату государственной услуги, в случае, если услуга представляется на платной основе',
      ],
      correctAnswer: 'документов, которые могут быть получены из информационных систем'
    },
    {
      type: EQuestionType.Radio,
      title: 'К полномочиям Государственной корпорации «Правительство для граждан» не относится:',
      choices: [
        'разработка и утверждение стандартов государственных услуг',
        'обеспечение информированности услугополучателей о порядке оказания государственных услуг',
        'рассматрение обращений услугополучателей по вопросам оказания государственных услуг',
        'обеспечение повышения квалификации работников в сфере оказания государственных услуг',
      ],
      correctAnswer: 'разработка и утверждение стандартов государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не является основанием для отказа в оказании государственных услуг:',
      choices: [
        'установление достоверности документов, представленных услугополучателем для получения государственной услуги, и (или) данных (сведений), содержащихся в них',
        'несоответствие услугополучателя и (или) представленных материалов, объектов, данных и сведений, необходимых для оказания государственной услуги, требованиям, установленным нормативными правовыми актами',
        'отрицательный ответ уполномоченного государственного органа на запрос о согласовании, который требуется для оказания государственной услуги, а также отрицательное заключение экспертизы, исследования либо проверки',
        'в отношении услугополучателя имеется вступившее в законную силу решение (приговор) суда о запрещении деятельности или отдельных видов деятельности, требующих получения определенной государственной услуги',
      ],
      correctAnswer: 'установление достоверности документов, представленных услугополучателем для получения государственной услуги, и (или) данных (сведений), содержащихся в них'
    },
    {
      type: EQuestionType.Radio,
      title: 'Допускается ли установление для услугополучателя платы за оказание государственных услуг, бесплатное предоставление которых гарантировано законами:',
      choices: [
        'не допускается',
        'допускается',
        'допускается в случаях, установленных законами Республики Казахстан',
        'допускается по усмотрению услугодателя',
      ],
      correctAnswer: 'не допускается'
    },
    {
      type: EQuestionType.Radio,
      title: 'Оценка качества государственных услуг, оказываемых в электронной форме, осуществляется:',
      choices: [
        'Уполномоченным органом в сфере информатизации',
        'Уполномоченным органом по оценке и контролю за качеством оказания государственных услуг',
        'Уполномоченным органом в сфере оказания государственных услуг',
        'Правительством',
      ],
      correctAnswer: 'Уполномоченным органом в сфере информатизации'
    },
    {
      type: EQuestionType.Radio,
      title: 'Оценка качества оказания государственных услуг, за исключением государственных услуг, оказываемых в электронной форме, осуществляется:',
      choices: [
        'Уполномоченным органом по оценке и контролю за качеством оказания государственных услуг',
        'Уполномоченным органом в сфере оказания государственных услуг',
        'Уполномоченным органом в сфере информатизации',
        'Правительством',
      ],
      correctAnswer: 'Уполномоченным органом по оценке и контролю за качеством оказания государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Услугополучатели имеют право:',
      choices: [
        'Обращаться в суд с иском о защите нарушенных прав, свобод и законных интересов в сфере оказания государственных услуг;',
        'Предоставлять полную и достоверную информацию о порядке оказания государственных услуг услугополучателям в доступной форме;',
        'Отказывать в оказании государственных услуг в случаях и по основаниям, установленным законами Республики Казахстан;',
        'Обеспечивать внесение данных в информационную систему мониторинга оказания государственных услуг о стадии оказания государственной услуги.'
      ],
      correctAnswer: ''
    },
    {
      type: EQuestionType.Radio,
      title: 'Правом обращения с запросом в центральные государственные органы, местные исполнительные органы за информацией, необходимой для оказания государственных услуг, обладают:',
      choices: [
        'услугодатели',
        'услугополучатели',
        'пенсионеры',
        'субъекты предпринимательства',
      ],
      correctAnswer: 'услугодатели'
    },
    {
      type: EQuestionType.Radio,
      title: 'Физические и юридические лица, за исключением центральных государственных органов, загранучреждений Республики Казахстан, местных исполнительных:',
      choices: [
        'услугополучатель',
        'услугодатель',
        'Государственная корпорация «Правительство для граждан»',
        'субъекты государственного контроля за качеством оказания государственных услуг'
      ],
      correctAnswer: 'услугополучатель'
    },
    {
      type: EQuestionType.Radio,
      title: 'Стандарт государственной услуги не включает:',
      choices: [
        'результаты оценки качества оказания данной государственной услуги',
        'требования к оказанию государственной услуги',
        'характеристики процесса, формы оказания государственной услуги',
        'содержание и результат оказания государственной услуги',
      ],
      correctAnswer: 'результаты оценки качества оказания данной государственной услуги'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не входит в компетенцию центральных государственных органов:',
      choices: [
        'разрабатывают и утверждают методику определения стоимости государственной услуги',
        'разрабатывают и утверждают стандарты государственных услуг',
        'разрабатывают и утверждают регламенты государственных услуг',
        'обеспечивают повышение качества, доступность оказания государственных услуг',
      ],
      correctAnswer: 'разрабатывают и утверждают методику определения стоимости государственной услуги'
    },
    {
      type: EQuestionType.Radio,
      title: 'Разрабатывает и утверждает методику определения стоимости государственной услуги:',
      choices: [
        'Уполномоченный орган в сфере оказания государственных услуг',
        'Уполномоченный орган в сфере информатизации',
        'Правительство',
        'Уполномоченный органом по оценке и контролю за качеством оказания государственных услуг',
      ],
      correctAnswer: 'Уполномоченный орган в сфере оказания государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Является основанием для отказа в оказании государственной услуги:',
      choices: [
        'отрицательный ответ уполномоченного государственного органа на запрос о согласовании, который требуется для оказания государственной услуги, а также отрицательное заключение экспертизы, исследования либо проверки',
        'установление достоверности документов, представленных услугополучателем для получения государственной услуги, и (или) данных (сведений), содержащихся в них',
        'соответствие услугополучателя и (или) представленных материалов, объектов, данных и сведений, необходимых для оказания государственной услуги, требованиям, установленным нормативными правовыми актами Республики Казахстан',
        'технический сбой при предоставлении государственной услуги в электронной форме',
      ],
      correctAnswer: 'отрицательный ответ уполномоченного государственного органа на запрос о согласовании, который требуется для оказания государственной услуги, а также отрицательное заключение экспертизы, исследования либо проверки'
    },
    {
      type: EQuestionType.Radio,
      title: 'Не относится к основным принципам оказания государственных услуг:',
      choices: [
        'Высокое качество и недоступность государственных услуг',
        'Недопустимость проявлений бюрократизма и волокиты при оказании государственных услуг',
        'Подотчетность и прозрачность в сфере оказания государственных услуг',
        'Экономичность и эффективность при оказании государственных услуг',
      ],
      correctAnswer: 'Высокое качество и недоступность государственных услуг'
    },
    {
      type: EQuestionType.Radio,
      title: 'Государственные услуги не оказываются:',
      choices: [
        'индивидуальными предпринимателями',
        'услугодателями',
        'через Государственную корпорацию',
        'посредством веб-портала «электронного правительства» и абонентского устройства подвижной сети'
      ],
      correctAnswer: 'индивидуальными предпринимателями'
    }
  ],
  time: 10,
  passingScore: 5,
  questionsTotal: 10
};



