import {IExam} from '../../../shared/models/test.model';

export const judicialCorp: IExam = {
    id: 'judicialCorp',
    title: 'Судебный корпус',
    topics: [
        {
            id: 'constitutionForJudicialCorp',
            title: 'Конституция РК',
            questions: [],
            questionsCount: 10,
            passingScore: 0
        },
        {
            id: 'adminOffensesForJudicialCorp',
            title: 'Кодекс об административных правонарушениях',
            questions: [],
            questionsCount: 10,
            passingScore: 0
        },
        {
            id: 'adminProceduresForJudicialCorp',
            title: 'Административный процедурно-процессуальный кодекс',
            questions: [],
            questionsCount: 10,
            passingScore: 0
        },
        {
            id: 'civilCodeForJudicialCorp',
            title: 'Гражданский кодекс РК',
            questions: [],
            questionsCount: 10,
            passingScore: 0
        },
        {
            id: 'civilProcedureCodeForJudicialCorp',
            title: 'Гражданский процессуальный кодекс Республики Казахстан',
            questions: [],
            questionsCount: 10,
            passingScore: 0
        },
        {
            id: 'criminalCodeForJudicialCorp',
            title: 'Уголовный кодекс РК',
            questions: [],
            questionsCount: 10,
            passingScore: 0
        },
        {
            id: 'criminalProcedureForJudicialCorp',
            title: 'Уголовный кодекс РК',
            questions: [],
            questionsCount: 10,
            passingScore: 0
        },
      {
            id: 'criminalProcedureForJudicialCorp',
            title: 'Уголовный кодекс РК',
            questions: [],
            questionsCount: 10,
            passingScore: 0
        },
    ],
    time: 90,
    questionsTotal: 100,
    passingScore: 75
};
