<div class="modal-header">
  <h5 class="modal-title">{{"SELECT_LAW_MODAL.TITLE" | translate}}</h5>
</div>
<div class="modal-body">
  <p class="fw-bold">{{"SELECT_LAW_MODAL.SUB_TITLE" | translate}}</p>
  <form (ngSubmit)="submitSelection()">
    <div class="form-check" *ngFor="let law of (activeLanguage === 'ru' ? lawsRu : lawsKz)">
      <input
        type="checkbox"
        class="form-check-input"
        [value]="law"
        (change)="onCheckboxChange($event)"
      />
      <label class="form-check-label">{{ law }}</label>
    </div>
    <button
      type="submit"
      class="btn btn-primary mt-3"
      [disabled]="!isValidSelection()"
    >
      {{"SELECT_LAW_MODAL.SAVE" | translate}}
    </button>
  </form>
</div>
