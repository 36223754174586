<div class="section book">
  <div class="container">
    <div class="row align-items-center">
      <div class="col-10 col-sm-5">
        <img class="book__img"
             *ngIf="selectedLanguage === 'ru'"
             src="../../../assets/images/olk-book-ru.png"
             alt="">
        <img class="book__img"
             *ngIf="selectedLanguage === 'kz'"
             src="../../../assets/images/olk-book-kz.png"
             alt="">
      </div>
      <div class="col-11 col-sm-7">
        <p class="section__title book__title">{{ 'BOOK.TITLE' | translate }}</p>
        <p class="section__sub-title book__sub-title">{{ 'BOOK.SUB_TITLE' | translate }}</p>
        <p class="section__text book__text">{{ 'BOOK.TEXT_1' | translate }}</p>
        <p class="section__text book__text">{{ 'BOOK.TEXT_2' | translate }}</p>

        <div class="book__order book-order">
          <button class="book-order__buy" (click)="onBuy('product46')">{{ 'BOOK.BTN' | translate }}</button>
          <!--<div class="btn-group book-order__language">
            <button class="btn"
                    [class.active]="selectedLanguage === 'ru'"
                    (click)="onchangeLanguage('ru')"
                    [disabled]="selectedLanguage === 'ru'">RU</button>
            <button class="btn"
                    [class.active]="selectedLanguage === 'kz'"
                    (click)="onchangeLanguage('kz')"
                    [disabled]="selectedLanguage === 'kz'">KZ</button>
          </div>
          <div class="btn-group book-order__amount">
            <button class="btn" (click)="removeBook()" [disabled]="bookAmount === 1">-</button>
            <div class="book-order__value">{{bookAmount}}</div>
            <button class="btn" (click)="addBook()">+</button>
          </div>-->
        </div>
      </div>
    </div>
  </div>
</div>

<div>
  <app-book-modal
    bsModal #bookFormModal="bs-modal"
    class="modal fade" tabindex="-1"
    role="dialog" aria-labelledby="dialog-child-name"
  >
  </app-book-modal>
</div>
